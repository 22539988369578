const translations = {
    en: {
        home: "Home",
        pricing: "Pricing",
        loginpage: "Log In",
        signup: "Sign Up",
        dashboard: "Dashboard",
        managesubscribtion: "Manage Subscription",
        upgradetopremium: "Upgrade to premium",
        logout: "Logout",
        chooseacategory: "Choose a category",
        logingpage: "Log In page",
        youarenotloggedin: "You are currently not logged in, please log in here",
        resetpassword: "Reset Password",
        enteremailtoresetpassowrd: "Enter your email to reset your password",
        email: "Email",
        submit: "Submit",
        enteryournewpassword: "Enter your new password",
        newpassword: "New Password",
        confirmnewpassword: "Confirm New Password",
        gotologin: "Go to Login",
        generallibrary: "General Library",
        yourlibrary: "Your Library",
        createnewbook: "Create New Book",
        youarecurrentlynotloggedin: "You are currently not logged in, please log in here",
        nowpleaseconfirmwhichpackage: "Now please confirm which package you would like to buy. Currently, you are using this email address:",
        onceyouclickonthebuybutton: "Once you click on the 'Buy Now' Button below, you will be transferred to our trusted vendor FastSpring where you will be able to finalize payment details. Please make sure to use the same email address",
        chooseapackage: "Choose a package:",
        selectyourpackage: "Select your package",
        monthly: "Monthly",
        yearly: "Yearly",
        buynow: "Buy Now",
        thankyouforchoosingpackage: "Thank you for selecting your package. After finalizing your payment details please make sure to relogin to update your balance. You will be able to create your own books and access your private library",
        youarecurrentlysubscribed: " You are currently subscribed to the",
        pleaseuseyourregisteredemail: "Package. Please use your registered email address:",
        tomanageyouraccount: "to manage your account. You can manage your subscription",
        here: "here",
        noteifyoudecidetoupgradeyourpackage: "Note: If you decide to upgrade your Package, the upgraded Package will show next time you log in.",
        yourorderiscompleted: "your order is completed",
        turnyourchildreality: "Turn your child's imagination into reality with ",
        howitworks: "How it works",
      
        whychoose: "Why choose",
        ourplatformisdedicatedto: "Our platform is dedicated to strengthening family bonds through personalized storytelling experiences. We facilitate deeper connections between parents and children, allowing families to create lasting memories together. Our immersive stories, tailored to children's interests and developmental stages, promote educational engagement while reinforcing important concepts and values",
        ignitecreativity: "Ignite Creativity",
        empowerchildrenimagination: "Empower children's imagination and creativity through engaging narratives and vibrant illustrations. Our service encourages creative thinking and problem-solving skills.",
        connectondeeperlevel: "Connect on a Deeper Level",
        fosteradeeperbond: "Foster a deeper bond between parents and children through personalized storytelling experiences. Our platform provides interactive elements for families to create lasting memories together.",
        funlearningadventures: "Fun Learning Adventures",
        leverageourservicestoprovide: "Leverage our service to provide educational content in a fun and engaging way. Children learn valuable lessons and skills through immersive storytelling tailored to their interests and developmental stage.",
        discoverwhyfamilies: "Discover why families trust us",
        convenienceandsimplified: "Convenience and Simplified Access",
        saygoodbuytothehassle: "Say goodbye to the hassle of searching for age-appropriate books or struggling to come up with creative ideas. Our service provides personalized stories and illustrations at your fingertips.",
        customizedlearningexperience: "Customized Learning Experiences",
        tailorstorytelling: "Tailor storytelling experiences to your child's individual needs and interests. Whether reinforcing specific learning concepts or addressing social-emotional themes, our service adapts to your child's developmental stage and learning style.",
        parentalempowement: "Parental Empowerment",
        takeanactiverole: "Take an active role in your child's education and growth with our tools and resources. Empower your child's learning journey and strengthen your relationship through collaborative storytelling experiences.",
        timesavingsolution: "Time-Saving Solution",
        savetimebyeleminating: "Save time by eliminating the need to research and curate educational materials. Rely on our platform to provide high-quality, personalized content tailored to your child's needs and interests.",
        experiencethemagic: "Experience the magic of storytelling with us today. Check out our",
        pricingpage: "Pricing Page",
        formoreinformationandstart: "for more information and start creating meaningful memories with your family.",
        welcomebackpleaselogin: "Welcome back! Please login to continue.",
        password: "Password",
        donthaveanouaccount: "Dont have an account, you can sign up ",
        forgotyourpassword: "Forgot your password, you can reset it ",
        welcome: "Welcome",
        letscreateyouraccount: "Let's create your account!",
        firstname: "First name",
        lastname: "Last name",
        termsandconditions: "Terms and Conditions",
        free: "Free",
        freeuser: "Free User",
        accesstogenerallibrary: "Access to General Library & Monthly featured books",
        earlyaccesstonewfeature: "Early Access to Promotions — Be the first to discover new features and deals",
        nocreditcardrequired: "No Credit Card Required — Start your journey with zero commitment",
        signupnow: "Sign UP Now",
        generalandprivatelibraries: "Access General & Private Libraries — Explore new featured stories every month",
        accesstonewaddedmonthlly: "Monthly Access to New Featured Books — Discover fresh tales each month",
        questbook: "5 Custom Storybooks — Crafted up to 8 pages, fully personalized for your child",
        odysseybook: "12 Custom Storybooks — Crafted up to 8 pages, fully personalized for your child",
        enchantedbook: "29 Custom Storybooks — Crafted up to 8 pages, fully personalized for your child",
        questbookannually: "60 Custom Storybooks — Crafted up to 8 pages, fully personalized for your child",
        odysseybookannually: "145 Custom Storybooks — Crafted up to 8 pages, fully personalized for your child",
        enchantedbookannually: "350 Custom Storybooks — Crafted up to 8 pages, fully personalized for your child",
        forallpaidpackages: "For all paid packages, at checkout you can choose to be billed annually and get ",
        onemonthfree: "one month free!",
        title: "Title",
        creationdate: "Creation Date",
        action: "Action",
        view: "View",
        pleasebarewithus: "Please bare with us, good things take time!",
        loading: "Loading",
        booksremaining: "Books remaining:",
        gender: "Gender:",
        booklength: "Book Length:",
        agegroup: "Age Group:",
        lessonlearned: "Lessons Learned",
        morallesson: "Moral Lesson",
        themes: "Themes",
        visualstyle: "Visual Style:",
        Whatillustrationstyle: "What illustration style do you find appealing?",
        characterarechetype: "Character Archetypes: ",
        genre: "Genre:",
        plotelements: " Plot Elements:",
        magicalelements: "Magical Elements:",
        cluturalcontext: "Cultural Context:",
        emotionaltone: "Emotional Tone:",
        environment: "Environment:",
        specificpreferences: "Specific preferences? customize your story even more:",
        storycreatedsuccesfully: "Story created and saved successfully!",
        failedtocreatestory: "Failed to create and save story!",
        toviewyourlibrary: "To view your library, click",
        unfortunatellyyouhaveconsumed: "Unfortunately, you have consumed all of your books for the month. If you would like to upgrade your package, please visit the ",
        language: "Language:",
        english: "English",
        arabic: "Arabic",
        bulgarian: "Bulgarian",
        chinese:"Chinese",
        french: "French",
        german: "German",
        hindi: "Hindi",
        korean: "Korean",
        portuguese: "portuguese",
        russian: "Russian",
        spanish:"spanish",
        dutch: "Dutch",
        safeContent: 'Is the content safe and age-appropriate for children?',
        safeContentAnswer: 'Yes, the content on GWOW AI is safe and age-appropriate for children. We take great care to ensure that all stories and illustrations generated by our AI are suitable for young audiences. Our platform allows parents to customize stories, ensuring that the content meets their specific requirements and preferences.',
        supportedDevices: 'What devices are supported by the AI Interactive Storyteller?',
        supportedDevicesAnswer: 'The AI Interactive Storyteller supports all modern devices, including smartphones, tablets, and computers. You need an internet connection to access the website, but you can also download stories locally for offline access.',
        globalSupport: 'Is it supported globally?',
        globalSupportAnswer: 'Yes, GWOW AI is supported globally. Users can choose from 12 different languages to create and enjoy their stories, making it accessible to a wide audience around the world.',
        freeTrial: 'Is there a free trial available?',
        freeTrialAnswer: 'Yes, there is a free trial available. You can create a free user account to access the general library. To create customized stories, you will need to subscribe to one of our packages starting at $7.99.',
        dataCollection: 'What data is collected and how is it used?',
        dataCollectionAnswer: 'We collect basic login information to provide and improve our services. This information is kept secure, not shared with anyone, and will not be sold.',
        aiBooksCreativity: "How can AI-generated books improve my child's creativity and learning?",
        aiBooksCreativityAnswer: "AI-generated books can significantly enhance your child's creativity and learning by providing personalized and engaging stories. These stories can be tailored to your child's interests and learning needs, making reading a more interactive and enjoyable experience. Additionally, parents can download stories as PDFs or coloring books to further engage their children.",
        learnmore: "Learn more:",
        years: "years",
        selectgender: "Select Gender ",
        male: "Male",
        female: "Female",
        unspecified: "Unspecified",
        selectbooklength: "Select Book Length",
        pages: "pages",
        selectmorallesson: "Select Moral Lesson",
        friendship: "Friendship",
        kindness: "Kindness",
        courage: "Courage",
        honesty: "Honesty",
        empathy: "Empathy",
        teamwork:"Teamwork",
        acceptance: "Acceptance",
        responsibility: "Responsibility",
        creativity: "Creativity",
        selectvisualstyle: "Select Visual Style",
        realistic: "Realistic",
        cartoon: "Cartoon",
        painterly: "Painterly",
        anime: "Anime",
        fantasy: "Fantasy",
        watercolor: "Watercolor",
        popart: "Pop Art",
        selectgenre: "Select Genre",
        adventure: "Adventure",
        mystery: "Mystery",
      
        sciencefiction: "Science Fiction",
        historicalfiction:"Historical Fiction",
        romance:"Romance",
        comedy:"Comedy",
        addatwist: "Add a Twist",
        selectmagicalelement: "Select Magical Element",
        wizards: "Wizards",
        mermaids: "Mermaids",
        dinosaurs: "Dinosaurs",
        robots:"Robots",
        cars: "Cars",
        pets: "Pets",
        dragons: "Dragons",
        aliens: "Aliens",
        fairies: "Fairies",
        superheroes: "Superheroes",
        pirates: "Pirates",
        cowboys: "Cowboys",
        secretworlds: "Secret Worlds",
        magicalcreatures: "Magical Creatures",
        knights: "Knights",
        princesses: "Princesses",
        otherspleasespecifybelow: "Others, please specify in the text area below",
        selectculturalcontext: "Select cultural Context",
        ancientegypt: "Ancient Egypt",
        medievaleurope: "Medieval Europe",
        feudaljapan: "Feudal Japan",
        victorianengland: "Victorian England",
        renaissanceitaly: "Renaissance Italy",
        nativeamericanfolklore: "Native American Folklore",
        africanmythology: "African Mythology",
        asianlegends: "Asian Legends",
        wildwest: "Wild West",
        selectenvironment: "Select Environment",
        forest: "Forest",
        cityscape: "Cityscape",
        countryside: "Countryside",
        mountains: "Mountains",
        beach: "Beach",
        underwaterkingdom: "Underwater Kingdom",
        outerspace: "Outer Space",
        enchantedforest: "Enchanted Forest",
        magicalcastle: "Magical Castle",
        desert: "Desert",
        arctictundra: "Arctic Tundra",
        volcanicisland: "Volcanic Island",
        alienplanet: "Alien Planet",
        maincharacter: "Main Character",
        selectmainchacter: "Select Main Character",
        child: "Child",
        superhero: "Superhero",
        animal: "Animal",
        wizard: "Wizard",
        fairy: "Fairy",
        robot: "Robot",
        fantasycharacters: "Fantasy Characters",
        VariousTales: "Various Tales",

        animals: "Animals",
        toys: "Toys",
        mythicalcreatures: "Mythical Creatures",
        momentstogether: "Moments Together",
        selectone: "Choose One",
        completeregistration: "Complete Registration",

        subscriptionsuccessful: "Yay! Your subscription was successful, and you have got a fresh tank of story credits! 🎉 To see your updated balance, please log out and log back in.Get ready for more fun adventures!",
        paymentissue: "Oops! It looks like your payment didn’t go through. Please check your card or PayPal details and give it another try. If it keeps happening, try a different payment method so you don’t miss out on any fun! ",
        underreview: "Hold tight! Your order is being reviewed to make sure everything’s safe and sound. We’ll let you know as soon as it’s ready. Thanks for your patience—more stories are coming your way soon!",
        pendingpayment: "our payment is on its way to us! As soon as we get it, your story credits will be ready to use. If you're using PayPal or a wire transfer, just make sure everything's approved so you can jump back into the adventure.",



        selectagegroup: "Select Age Group",
        createstory: "Create Story",
        flipdeviceforbetterview: "Flip device for better view",
        gobacktolibrary: "Go Back to Library",
        downloadbook:"Download Book",
        downloadcoloringbook:"Download Coloring Book",
        forsupportemailusat: "For support email us at ",
        chooselanguage: "Choose Language",
        currentsubscriptiondetails: "Current Subscription Details",
        packagename: "Package name",
        enddate: "End Date",
        remainingbalance: "Remaining balance",
        emailaddress: "Email address",
        renewsubscription: "Renew Subscription",
        confirrenewsubscriptiondate: "Are you sure you want to renew your subscription now?",
        ifyoudecidetoupgrade: "If you decide to upgrade or downgrade, your new subscription will start today",
        yes: "Yes",
        no: "No",
        cancelsubscription: "Cancel Subscription",
        confirmsubcancelation: "Are you sure you want to cancel your subscription? Once canceled you will only have access to free features and general libraries",
        toupgradeyourpackage: "To upgrade or downgrade your package, select one of the following",
        somethingwrongcontactsupport: "There might be something wrong. Please contact support team for assistance.",
        emailverification:"Email Verification",
        pleaselogin: "Please sign in",
        downloadbookfeature: "Download Storybooks — Available in original or coloring book version, ready to print",

        aboutus: "About US",
        frequentlyaskedquestion:"Frequently Asked Questions",
        

        languagedisclaimer: "Please note that the Terms and Conditions are displayed in English for reference purposes. While we strive to make our content available in multiple languages, the English version of these Terms and Conditions will always prevail in case of any discrepancies, misunderstandings, or legal disputes arising from translations. By agreeing to these Terms and Conditions, you acknowledge that it is your responsibility to ensure accurate interpretation of the content in your preferred language. In the event of any legal disputes or ambiguity, the English version of the Terms and Conditions will take precedence.If you have any questions or need clarification, we encourage you to seek professional advice or contact us directly.",
        availablecurrences: "Supporting Over 100 Local Currencies, Including USD, EUR, GBP, JPY, AUD, and More!",
        ifyouhaveacouponyoucanredeemit: "If you have a coupon, you can redeem it ",
        ifthestoryreloads: "Note: If the form reloads, it means the images didn't pass our safety checks. Please adjust your input and try again",
        youcanalsosendagift :"You can also buy a one time gift and send it to someone",


    },
    es: {
        home: "Inicio",
        pricing: "Precios",
        loginpage: "Iniciar Sesión",
        signup: "Registrarse",
        dashboard: "Tablero",
        managesubscribtion: "Gestionar Suscripción",
        upgradetopremium: "Actualizar a premium",
        logout: "Cerrar Sesión",
        chooseacategory: "Elegir una categoría",
        logingpage: "Página de Iniciar Sesión",
        youarenotloggedin: "Actualmente no has iniciado sesión, por favor inicia sesión aquí",
        resetpassword: "Restablecer Contraseña",
        enteremailtoresetpassowrd: "Ingresa tu correo electrónico para restablecer tu contraseña",
        email: "Correo Electrónico",
        submit: "Enviar",
        enteryournewpassword: "Ingresa tu nueva contraseña",
        newpassword: "Nueva Contraseña",
        confirmnewpassword: "Confirmar Nueva Contraseña",
        gotologin: "Ir a Iniciar Sesión",
        yourlibrary: "Tu Biblioteca",
        createnewbook: "Crear Nuevo Libro",
        generallibrary: "Biblioteca General",
        youarecurrentlynotloggedin: "Actualmente no has iniciado sesión, por favor inicia sesión aquí",
        nowpleaseconfirmwhichpackage: "Ahora por favor confirma qué paquete te gustaría comprar. Actualmente, estás usando esta dirección de correo electrónico:",
        onceyouclickonthebuybutton: "Una vez que hagas clic en el botón 'Comprar Ahora' a continuación, serás transferido a nuestro proveedor de confianza FastSpring donde podrás finalizar los detalles del pago. Por favor asegúrate de usar la misma dirección de correo electrónico",
        chooseapackage: "Elige un paquete:",
        selectyourpackage: "Selecciona tu paquete",
        monthly: "Mensual",
        yearly: "Anual",
        buynow: "Comprar Ahora",
        thankyouforchoosingpackage: "Gracias por seleccionar tu paquete. Después de finalizar los detalles de tu pago, por favor asegúrate de volver a iniciar sesión para actualizar tu saldo. Podrás crear tus propios libros y acceder a tu biblioteca privada.",
        youarecurrentlysubscribed: "Actualmente estás suscrito al",
        pleaseuseyourregisteredemail: "Paquete. Por favor usa tu dirección de correo electrónico registrada:",
        tomanageyouraccount: "para gestionar tu cuenta. Puedes gestionar tu suscripción",
        here: "aquí",
        noteifyoudecidetoupgradeyourpackage: "Nota: Si decides actualizar tu paquete, el paquete actualizado se mostrará la próxima vez que inicies sesión.",
        yourorderiscompleted: "tu pedido está completado",
        turnyourchildreality: "Convierte la imaginación de tu hijo en realidad con ",
     
       
        whychoose: "Por qué elegir",
        ourplatformisdedicatedto: "Nuestra plataforma está dedicada a fortalecer los lazos familiares a través de experiencias de narración personalizadas. Al proporcionar una plataforma para la narración compartida, facilitamos conexiones más profundas entre padres e hijos, permitiendo a las familias crear recuerdos duraderos juntos. Nuestras historias inmersivas, adaptadas a los intereses y etapas de desarrollo de los niños, promueven el compromiso educativo mientras refuerzan conceptos y valores importantes",
        ignitecreativity: "Enciende la Creatividad",
        empowerchildrenimagination: "Potencia la imaginación y creatividad de los niños a través de narraciones envolventes e ilustraciones vibrantes. Nuestro servicio fomenta el pensamiento creativo y las habilidades para resolver problemas.",
        connectondeeperlevel: "Conéctate a un Nivel Más Profundo",
        fosteradeeperbond: "Fomenta un vínculo más profundo entre padres e hijos a través de experiencias de narración personalizadas. Nuestra plataforma ofrece elementos interactivos para que las familias creen recuerdos duraderos juntos.",
        funlearningadventures: "Aventuras de Aprendizaje Divertidas",
        leverageourservicestoprovide: "Aprovecha nuestro servicio para proporcionar contenido educativo de una manera divertida y atractiva. Los niños aprenden lecciones y habilidades valiosas a través de narraciones inmersivas adaptadas a sus intereses y etapa de desarrollo.",
        discoverwhyfamilies: "Descubre por qué las familias confían en nosotros",
        convenienceandsimplified: "Conveniencia y Acceso Simplificado",
        saygoodbuytothehassle: "Di adiós a la molestia de buscar libros apropiados para la edad o de tratar de idear ideas creativas. Nuestro servicio proporciona historias e ilustraciones personalizadas al alcance de tu mano.",
        customizedlearningexperience: "Experiencias de Aprendizaje Personalizadas",
        tailorstorytelling: "Personaliza las experiencias de narración según las necesidades e intereses individuales de tu hijo. Ya sea reforzando conceptos de aprendizaje específicos o abordando temas socioemocionales, nuestro servicio se adapta a la etapa de desarrollo y estilo de aprendizaje de tu hijo.",
        parentalempowement: "Empoderamiento Parental",
        takeanactiverole: "Toma un papel activo en la educación y el crecimiento de tu hijo con nuestras herramientas y recursos. Potencia el viaje de aprendizaje de tu hijo y fortalece tu relación a través de experiencias de narración colaborativas.",
        timesavingsolution: "Solución Ahorra Tiempo ",
        savetimebyeleminating: "Ahorra tiempo al eliminar la necesidad de investigar y seleccionar materiales educativos. Confía en nuestra plataforma para proporcionar contenido de alta calidad y personalizado adaptado a las necesidades e intereses de tu hijo.",
        experiencethemagic: "Experimenta la magia de la narración con nosotros hoy. Visita nuestra",
        pricingpage: "Página de Precios",
        formoreinformationandstart: "para más información y comienza a crear recuerdos significativos con tu familia.",
        welcomebackpleaselogin: "¡Bienvenido de nuevo! Por favor inicia sesión para continuar.",
        password: "Contraseña",
        donthaveanouaccount: "No tienes una cuenta, puedes registrarte ",
        forgotyourpassword: "¿Olvidaste tu contraseña? Puedes restablecerla ",
        welcome: "Bienvenido",
        letscreateyouraccount: "¡Vamos a crear tu cuenta!",
        firstname: "Nombre",
        lastname: "Apellido",
        termsandconditions: "Términos y Condiciones",
        free: "Gratis",
        freeuser: "Usuario Gratis",
        accesstogenerallibrary: "Acceso a la Biblioteca General y libros destacados mensuales",
        earlyaccesstonewfeature: "Acceso anticipado a promociones — Sé el primero en descubrir nuevas funciones y ofertas",
        nocreditcardrequired: "Sin necesidad de tarjeta de crédito — Empieza sin compromiso",
        signupnow: "Regístrate Ahora",
        generalandprivatelibraries: "Acceso a Bibliotecas Generales y Privadas — Explora nuevas historias destacadas cada mes",
        accesstonewaddedmonthlly: "Acceso mensual a nuevos libros destacados — Descubre cuentos frescos cada mes",
        questbook: "5 Cuentos Personalizados — Diseñados de hasta 8 páginas, completamente personalizados para tu hijo",
        odysseybook: "12 Cuentos Personalizados — Diseñados de hasta 8 páginas, completamente personalizados para tu hijo",
        enchantedbook: "29 Cuentos Personalizados — Diseñados de hasta 8 páginas, completamente personalizados para tu hijo",
        questbookannually: "60 Cuentos Personalizados — Diseñados de hasta 8 páginas, completamente personalizados para tu hijo",
        odysseybookannually: "145 Libros Personalizados — Hasta 8 páginas, totalmente a medida",
        enchantedbookannually: "350 Cuentos Personalizados — Diseñados de hasta 8 páginas, completamente personalizados para tu hijo",
        forallpaidpackages: "Para todos los paquetes de pago, al finalizar la compra puedes elegir ser facturado anualmente y obtener ",
        onemonthfree: "¡un mes gratis!",
        title: "Título",
        creationdate: "Fecha de Creación",
        action: "Acción",
        view: "Ver",
        pleasebarewithus: "Por favor ten paciencia con nosotros, ¡las cosas buenas toman tiempo!",
        loading: "Cargando",
        booksremaining: "Libros restantes:",
        gender: "Género:",
        booklength: "Longitud del Libro:",
        agegroup: "Grupo de Edad:",
        morallesson: "Lección Moral",
        themes: "Temas",
        visualstyle: "Estilo Visual:",
        Whatillustrationstyle: "¿Qué estilo de ilustración te resulta atractivo?",
        characterarechetype: "Arquetipos de Personajes: ",
        genre: "Género:",
        plotelements: "Elementos de la Trama:",
        magicalelements: "Elementos Mágicos:",
        cluturalcontext: "Contexto Cultural:",
        emotionaltone: "Tono Emocional:",
        environment: "Ambiente:",
        specificpreferences: "¿Preferencias específicas? Personaliza tu historia aún más:",
        storycreatedsuccesfully: "¡Historia creada y guardada con éxito!",
        failedtocreatestory: "¡Error al crear y guardar la historia!",
        toviewyourlibrary: "Para ver tu biblioteca, haz clic",
        unfortunatellyyouhaveconsumed: "Desafortunadamente, has consumido todos tus libros del mes. Si deseas actualizar tu paquete, por favor visita el ",
        language: "Idioma:",
        english: "Inglés",
        arabic: "Árabe",
        bulgarian: "Búlgaro",
        chinese: "Chino",
        french: "Francés",
        german: "Alemán",
        hindi: "Hindi",
        korean: "Coreano",
        portuguese: "Portugués",
        russian: "Ruso",
        spanish: "Español",
        dutch: "Neerlandés",
        safeContent: '¿Es el contenido seguro y adecuado para la edad de los niños?',
        safeContentAnswer: 'Sí, el contenido en GWOW AI es seguro y adecuado para la edad de los niños. Nos preocupamos mucho para garantizar que todas las historias e ilustraciones generadas por nuestra IA sean adecuadas para audiencias jóvenes. Nuestra plataforma permite a los padres personalizar las historias, asegurando que el contenido cumpla con sus requisitos y preferencias específicos.',
        supportedDevices: '¿Qué dispositivos son compatibles con el Narrador Interactivo AI?',
        supportedDevicesAnswer: 'El Narrador Interactivo AI es compatible con todos los dispositivos modernos, incluidos smartphones, tabletas y computadoras. Necesita una conexión a Internet para acceder al sitio web, pero también puede descargar historias localmente para acceder sin conexión.',
        globalSupport: '¿Está soportado globalmente?',
        globalSupportAnswer: 'Sí, GWOW AI es compatible a nivel mundial. Los usuarios pueden elegir entre 12 idiomas diferentes para crear y disfrutar de sus historias, lo que lo hace accesible a una amplia audiencia en todo el mundo.',
        freeTrial: '¿Hay una prueba gratuita disponible?',
        freeTrialAnswer: 'Sí, hay una prueba gratuita disponible. Puede crear una cuenta de usuario gratuita para acceder a la biblioteca general. Para crear historias personalizadas, deberá suscribirse a uno de nuestros paquetes a partir de $7.99.',
        dataCollection: '¿Qué datos se recopilan y cómo se utilizan?',
        dataCollectionAnswer: 'Recopilamos información básica de inicio de sesión para proporcionar y mejorar nuestros servicios. Esta información se mantiene segura, no se comparte con nadie y no se venderá.',
        aiBooksCreativity: '¿Cómo pueden los libros generados por IA mejorar la creatividad y el aprendizaje de mi hijo?',
        aiBooksCreativityAnswer: 'Los libros generados por IA pueden mejorar significativamente la creatividad y el aprendizaje de su hijo al proporcionar historias personalizadas y atractivas. Estas historias se pueden adaptar a los intereses y necesidades de aprendizaje de su hijo, haciendo que la lectura sea una experiencia más interactiva y agradable. Además, los padres pueden descargar historias como PDFs o libros para colorear para involucrar aún más a sus hijos.',
        learnmore: "Aprende más:",
        years: "años",
        selectgender: "Seleccionar género",
        male: "Hombre",
        female: "Mujer",
        unspecified: "No especificado",
        selectbooklength: "Seleccionar longitud del libro",
        pages: "páginas",
        selectmorallesson: "Seleccionar lección moral",
        friendship: "Amistad",
        kindness: "Amabilidad",
        courage: "Coraje",
        honesty: "Honestidad",
        empathy: "Empatía",
        teamwork: "Trabajo en equipo",
        acceptance: "Aceptación",
        responsibility: "Responsabilidad", creativity: "Creatividad", selectvisualstyle: "Seleccionar estilo visual", realistic: "Realista", cartoon: "Dibujos animados", painterly: "Pictórico", anime: "Anime",
        watercolor: "Acuarela",
        popart: "Arte Pop", selectgenre: "Seleccionar género", adventure: "Aventura", mystery: "Misterio", fantasy: "Fantasía", sciencefiction: "Ciencia ficción", historicalfiction: "Ficción histórica", romance: "Romance", comedy: "Comedia", addatwist: "Agregar un giro", selectmagicalelement: "Seleccionar elemento mágico", wizards: "Magos", mermaids: "Sirenas", dinosaurs: "Dinosaurios", robots: "Robots", cars: "Coches", pets: "Mascotas",
        dragons: "Dragones", aliens: "Extraterrestres", fairies: "Hadas", superheroes: "Superhéroes", pirates: "Piratas", cowboys: "Vaqueros", secretworlds: "Mundos Secretos", magicalcreatures: "Criaturas Mágicas", knights: "Caballeros", princesses: "Princesas", otherspleasespecifybelow: "Otros, especifique en el área de texto a continuación", selectculturalcontext: "Seleccione el Contexto Cultural", ancientegypt: "Antiguo Egipto", medievaleurope: "Europa Medieval", feudaljapan: "Japón Feudal", victorianengland: "Inglaterra Victoriana", renaissanceitaly: "Italia Renacentista", nativeamericanfolklore: "Folclore Nativo Americano", africanmythology: "Mitología Africana", asianlegends: "Leyendas Asiáticas", wildwest: "Lejano Oeste", selectenvironment: "Seleccione el Entorno", forest: "Bosque", cityscape: "Paisaje Urbano", countryside: "Campo", mountains: "Montañas", beach: "Playa", underwaterkingdom: "Reino Submarino", outerspace: "Espacio Exterior", enchantedforest: "Bosque Encantado", magicalcastle: "Castillo Mágico", desert: "Desierto", arctictundra: "Tundra Ártica", volcanicisland: "Isla Volcánica", alienplanet: "Planeta Alienígena",
        Superheroes: "Superhéroes",
        VariousTales: "Cuentos Varios",
        maincharacter: "Personaje Principal",

        animals: "Animales",
        toys: "Juguetes",
        mythicalcreatures: "Criaturas Míticas",
        momentstogether: "Momentos Juntos",
        selectone: "Elige Uno",
        completeregistration: "Completa el registro",

        subscriptionsuccessful: "¡Yay! Tu suscripción fue exitosa, y tienes un tanque nuevo de créditos para historias! 🎉 Para ver tu saldo actualizado, por favor cierra sesión y vuelve a ingresar. ¡Prepárate para más aventuras divertidas!",
        paymentissue: "¡Ups! Parece que tu pago no se realizó. Revisa los detalles de tu tarjeta o PayPal y vuelve a intentarlo. Si sigue ocurriendo, prueba con otro método de pago para que no te pierdas de la diversión.",
        underreview: "¡Espera un momento! Tu pedido está siendo revisado para asegurarnos de que todo esté bien. Te avisaremos tan pronto esté listo. ¡Gracias por tu paciencia—más historias están en camino!",
        pendingpayment: "¡Tu pago está en camino hacia nosotros! Tan pronto como lo recibamos, tus créditos para historias estarán listos para usar. Si estás usando PayPal o una transferencia bancaria, solo asegúrate de que todo esté aprobado para que puedas volver a la aventura.",


        selectagegroup: "Seleccionar grupo de edad",
        createstory: "Crear historia",
        flipdeviceforbetterview: "Gira el dispositivo para una mejor vista",
        gobacktolibrary: "Volver a la biblioteca",
        downloadbook: "Descargar libro",
        downloadcoloringbook: "Descargar libro para colorear",
        forsupportemailusat: "Para soporte, envíenos un correo electrónico a ",
        chooselanguage: "Elegir idioma",
        currentsubscriptiondetails: "Detalles de suscripción actuales",
        packagename: "Nombre del paquete",
        enddate: "Fecha de finalización",
        remainingbalance: "Saldo restante",
        emailaddress: "Dirección de correo electrónico",
        renewsubscription: "Renovar suscripción",
        confirrenewsubscriptiondate: "Are you sure you want to renew your subscription now?",
        ifyoudecidetoupgrade: "Si decides cambiar tu suscripción, tu nueva suscripción comenzará hoy",
        yes: "Sí",
        no: "No",
        cancelsubscription: "Cancelar suscripción",
        confirmsubcancelation: "¿Está seguro de que desea cancelar su suscripción? Una vez cancelado, solo tendrá acceso a funciones gratuitas y bibliotecas generales",
        toupgradeyourpackage: "Para actualizar o degradar su paquete, seleccione uno de los siguientes",
        somethingwrongcontactsupport: "Algo puede estar mal. Por favor, póngase en contacto con el equipo de soporte para obtener asistencia.",
        emailverification: "Verificación de correo electrónico",
        pleaselogin: "Por favor, inicie sesión",
        downloadbookfeature: "Descargar Cuentos — Disponible en versión original o de colorear, listo para imprimir",

        child: "Niño",
        superhero: "Superhéroe",
        animal: "Animal",
        wizard: "Mago",
        fairy: "Hada",
        robot: "Robot",
        fantasycharacters: "Personajes Fantásticos",
      
        aboutus: "Sobre Nosotros",
        frequentlyaskedquestion: "Preguntas Frecuentes",
        howitworks: "Cómo funciona",

   languagedisclaimer: "Tenga en cuenta que los Términos y Condiciones se muestran en inglés como referencia. Aunque nos esforzamos por hacer nuestro contenido disponible en varios idiomas, la versión en inglés de estos Términos y Condiciones siempre prevalecerá en caso de discrepancias, malentendidos o disputas legales que surjan de las traducciones. Al aceptar estos Términos y Condiciones, usted reconoce que es su responsabilidad asegurarse de la interpretación precisa del contenido en su idioma preferido. En caso de cualquier disputa legal o ambigüedad, la versión en inglés de los Términos y Condiciones tendrá prioridad. Si tiene alguna pregunta o necesita aclaración, le recomendamos buscar asesoramiento profesional o ponerse en contacto con nosotros directamente.",
        availablecurrences: "Soportando más de 100 monedas locales, incluyendo USD, EUR, GBP, JPY, AUD y más!",
        ifyouhaveacouponyoucanredeemit: "Si tienes un cupón, puedes canjearlo",
        ifthestoryreloads: "Nota: Si el formulario se recarga, significa que las imágenes no pasaron nuestros controles de seguridad. Por favor, ajuste su entrada y vuelva a intentarlo",
        youcanalsosendagift: "También puedes comprar un regalo único y enviarlo a alguien",
    }
,

    zhcn:{
        home: "首页",
        pricing: "价格",
        loginpage: "登录",
        signup: "注册",
        dashboard: "仪表板",
        managesubscribtion: "管理订阅",
        upgradetopremium: "升级到高级版",
        logout: "退出登录",
        chooseacategory: "选择一个类别",
        logingpage: "登录页面",
        youarenotloggedin: "您当前未登录，请在此登录",
        resetpassword: "重置密码",
        enteremailtoresetpassowrd: "输入您的电子邮件以重置密码",
        email: "电子邮件",
        submit: "提交",
        enteryournewpassword: "输入您的新密码",
        newpassword: "新密码",
        confirmnewpassword: "确认新密码",
        gotologin: "去登录",
        generallibrary: "综合图书馆",
        yourlibrary: "您的图书馆",
        createnewbook: "创建新书",
        youarecurrentlynotloggedin: "您当前未登录，请在此登录",
        nowpleaseconfirmwhichpackage: "现在请确认您想购买的套餐。当前，您正在使用此电子邮件地址：",
        onceyouclickonthebuybutton: "点击下方“立即购买”按钮后，您将被转移到我们信任的供应商FastSpring，在那里您可以完成付款细节。请确保使用相同的电子邮件地址",
        chooseapackage: "选择一个套餐：",
        selectyourpackage: "选择您的套餐",
        monthly: "每月",
        yearly: "每年",
        buynow: "立即购买",
        thankyouforchoosingpackage: "感谢您选择您的套餐。请在完成付款详情后重新登录以更新您的余额。您将能够创建自己的书籍并访问您的私人图书馆。",
        youarecurrentlysubscribed: "您当前订阅的是",
        pleaseuseyourregisteredemail: "套餐。请使用您的注册电子邮件地址：",
        tomanageyouraccount: "管理您的帐户。您可以管理您的订阅",
        here: "这里",
        noteifyoudecidetoupgradeyourpackage: "注意：如果您决定升级您的套餐，升级后的套餐将在您下次登录时显示。",
        yourorderiscompleted: "您的订单已完成",
        turnyourchildreality: "将您的孩子的想象变为现实 ",
    
      
        whychoose: "为什么选择",
        ourplatformisdedicatedto: "我们的平台致力于通过个性化的故事讲述体验来加强家庭纽带。通过提供一个共享故事的平台，我们促进父母和孩子之间更深的联系，让家庭一起创造持久的回忆。我们的沉浸式故事，针对儿童的兴趣和发展阶段，促进教育参与，同时强化重要的概念和价值观",
        ignitecreativity: "激发创造力",
        empowerchildrenimagination: "通过引人入胜的叙述和生动的插图，激发儿童的想象力和创造力。我们的服务鼓励创造性思维和解决问题的能力。",
        connectondeeperlevel: "在更深层次上连接",
        fosteradeeperbond: "通过个性化的故事讲述体验，在父母和孩子之间建立更深的联系。我们的平台提供互动元素，让家庭一起创造持久的回忆。",
        funlearningadventures: "有趣的学习冒险",
        leverageourservicestoprovide: "利用我们的服务，以有趣和引人入胜的方式提供教育内容。通过适合他们兴趣和发展阶段的沉浸式故事，孩子们学习到宝贵的教训和技能。",
        discoverwhyfamilies: "发现为什么家庭信任我们",
        convenienceandsimplified: "便利和简化访问",
        saygoodbuytothehassle: "告别寻找适合年龄的书籍或努力想出创意的麻烦。我们的服务提供个性化的故事和插图，触手可及。",
        customizedlearningexperience: "定制的学习体验",
        tailorstorytelling: "根据您孩子的个人需求和兴趣定制故事讲述体验。无论是强化特定的学习概念还是解决社交情感主题，我们的服务都会根据您孩子的发展阶段和学习风格进行调整。",
        parentalempowement: "父母赋权",
        takeanactiverole: "通过我们的工具和资源，在孩子的教育和成长中扮演积极角色。通过协作的故事讲述体验，增强孩子的学习旅程并加强您们的关系。",
        timesavingsolution: "省时解决方案",
        savetimebyeleminating: "通过消除研究和整理教育材料的需求节省时间。依靠我们的平台提供高质量、个性化的内容，满足您孩子的需求和兴趣。",
        experiencethemagic: "今天就体验我们故事讲述的魔力。查看我们的",
        pricingpage: "价格页面",
        formoreinformationandstart: "了解更多信息并开始与您的家人创造有意义的回忆。",
        welcomebackpleaselogin: "欢迎回来！请登录继续。",
        password: "密码",
        donthaveanouaccount: "没有账户，您可以在这里注册 ",
        forgotyourpassword: "忘记密码了吗？您可以重置它",
        welcome: "欢迎",
        letscreateyouraccount: "让我们创建您的账户！",
        firstname: "名字",
        lastname: "姓氏",
        termsandconditions: "条款和条件",
        free: "免费",
        freeuser: "免费用户",
        accesstogenerallibrary: "访问普通图书馆和每月推荐书籍",
        earlyaccesstonewfeature: "提前访问促销活动 — 成为第一个发现新功能和优惠的人",
        nocreditcardrequired: "无需信用卡 — 零承诺开始你的旅程",
        signupnow: "立即注册",
        generalandprivatelibraries: "访问公共和私人图书馆 — 每月探索新的精选故事。",
        accesstonewaddedmonthlly: "每月访问新增推荐书籍 — 每月发现新故事",
        questbook: "5 定制故事书 — 制作最多 8 页，完全个性化，适合您的孩子",
        odysseybook: "12 定制故事书 — 制作最多 8 页，完全个性化，适合您的孩子" ,
        enchantedbook: "29 定制故事书 — 制作最多 8 页，完全个性化，适合您的孩子",
        questbookannually: "60 定制故事书 — 制作最多 8 页，完全个性化，适合您的孩子",
        odysseybookannually: "145 定制故事书 — 制作最多 8 页，完全个性化，适合您的孩子",
        enchantedbookannually: "350 定制故事书 — 制作最多 8 页，完全个性化，适合您的孩子",
        forallpaidpackages: "对于所有付费套餐，在结账时您可以选择按年计费并获得 ",
        onemonthfree: "一个月免费！",
        title: "标题",
        creationdate: "创建日期",
        action: "操作",
        view: "查看",
        pleasebarewithus: "请耐心等待，好事需要时间！",
        loading: "加载中",
        booksremaining: "剩余书籍：",
        gender: "性别：",
        booklength: "书籍长度：",
        agegroup: "年龄组：",
        lessonlearned: "学习的教训",
        morallesson: "道德课程",
        themes: "主题",
        visualstyle: "视觉风格：",
        Whatillustrationstyle: "你觉得哪种插图风格更吸引人？",
        characterarechetype: "角色原型：",
        genre: "类型：",
        plotelements: "情节元素：",
        magicalelements: "魔法元素：",
        cluturalcontext: "文化背景：",
        emotionaltone: "情感基调：",
        environment: "环境：",
        specificpreferences: "有特殊偏好吗？进一步定制您的故事：",
        storycreatedsuccesfully: "故事创建并成功保存！",
        failedtocreatestory: "创建并保存故事失败！",
        toviewyourlibrary: "查看您的图书馆，请点击",
        unfortunatellyyouhaveconsumed: "不幸的是，您本月的所有书籍都已使用完。如果您想升级您的套餐，请访问 ",
        language: "语言：",
        english: "英语",
        arabic: "阿拉伯语",
        bulgarian: "保加利亚语",
        chinese: "中文",
        french: "法语",
        german: "德语",
        hindi: "印地语",
        korean: "韩语",
        portuguese: "葡萄牙语",
        russian: "俄语",
        spanish: "西班牙语",
        dutch: "荷兰语",
        safeContent: '内容是否安全并适合儿童?',
        safeContentAnswer: '是的，GWOW AI上的内容是安全且适合儿童的。我们非常重视确保我们的AI生成的所有故事和插图都适合年轻观众。我们的平台允许父母自定义故事，确保内容符合他们的具体要求和偏好。',
        supportedDevices: 'AI互动故事书支持哪些设备?',
        supportedDevicesAnswer: 'AI互动故事书支持所有现代设备，包括智能手机、平板电脑和电脑。您需要互联网连接才能访问网站，但您也可以将故事下载到本地进行离线访问。',
        globalSupport: '它在全球范围内支持吗?',
        globalSupportAnswer: '是的，GWOW AI在全球范围内支持。用户可以选择12种不同的语言来创建和享受他们的故事，使其能够被全世界的广泛观众使用。',
        freeTrial: '是否提供免费试用?',
        freeTrialAnswer: '是的，提供免费试用。您可以创建一个免费用户帐户来访问普通图书馆。要创建自定义故事，您需要订阅我们起价为7.99美元的套餐。',
        dataCollection: '收集了哪些数据，如何使用?',
        dataCollectionAnswer: '我们收集基本的登录信息来提供和改进我们的服务。这些信息是安全保密的，不会与任何人分享，也不会出售。',
        aiBooksCreativity: "AI生成的书籍如何提高我孩子的创造力和学习能力?",
        aiBooksCreativityAnswer: "AI生成的书籍通过提供个性化和引人入胜的故事，可以显著增强您孩子的创造力和学习能力。这些故事可以根据您孩子的兴趣和学习需求进行定制，使阅读成为更互动和愉快的体验。此外，父母可以将故事下载为PDF或涂色书，进一步吸引孩子的兴趣。",

        faqAppropriateContent: '内容是否安全并适合儿童观看？',
        faqSupportedDevices: 'AI互动故事讲述器支持哪些设备？',
        faqGlobalSupport: '是否支持全球使用？',
        faqFreeTrial: '是否提供免费试用？',
        faqDataCollection: '收集哪些数据，如何使用？',
        faqAIBooks: 'AI生成的书籍如何提高孩子的创造力和学习能力？',
        answerAppropriateContent: '是的，GWOW AI上的内容是安全且适合儿童观看的。我们非常注重确保由我们的AI生成的所有故事和插图适合年轻观众。我们的平台允许家长定制故事，以确保内容满足他们的特定要求和偏好。',
        answerSupportedDevices: 'AI互动故事讲述器支持所有现代设备，包括智能手机、平板电脑和计算机。您需要互联网连接才能访问网站，但您也可以下载故事以便离线访问。',
        answerGlobalSupport: '是的，GWOW AI支持全球使用。用户可以从12种不同的语言中选择来创建和享受他们的故事，使其能够接触到全球广泛的受众。',
        answerFreeTrial: '是的，提供免费试用。您可以创建一个免费用户帐户以访问通用图书馆。要创建定制故事，您需要订阅我们的一个套餐，起价为4.99美元。',
        answerDataCollection: '我们收集基本的登录信息以提供和改进我们的服务。这些信息是安全的，不会与任何人共享，也不会被出售。',
        answerAIBooks: 'AI生成的书籍可以通过提供个性化和吸引人的故事显著提高您孩子的创造力和学习能力。这些故事可以根据您孩子的兴趣和学习需求进行调整，使阅读成为一种更互动和愉快的体验。此外，家长还可以将故事下载为PDF或涂色书，以进一步吸引孩子。',
        learnmore: "了解更多：",
        years: "年", selectgender: "选择性别", male: "男", female: "女", unspecified: "未指定", selectbooklength: "选择书长", pages: "页", selectmorallesson: "选择道德教训", friendship: "友谊", kindness: "善良", courage: "勇气", honesty: "诚实", empathy: "同情", teamwork: "团队合作", acceptance: "接受", responsibility: "责任", creativity: "创造力", selectvisualstyle: "选择视觉风格", realistic: "写实", cartoon: "卡通", painterly: "画家风格", anime: "动漫", fantasy: "奇幻",
        watercolor: "水彩",
        popart: "波普艺术", selectgenre: "选择类型", adventure: "冒险", mystery: "悬疑", sciencefiction: "科幻", historicalfiction: "历史小说", romance: "浪漫", comedy: "喜剧", addatwist: "加一个转折", selectmagicalelement: "选择魔法元素", wizards: "巫师", mermaids: "美人鱼", dinosaurs: "恐龙", robots: "机器人", cars: "汽车", pets: "宠物",
        dragons: "龙", aliens: "外星人", fairies: "仙女", superheroes: "超级英雄", pirates: "海盗", cowboys: "牛仔", secretworlds: "秘密世界", magicalcreatures: "神奇生物", knights: "骑士", princesses: "公主", otherspleasespecifybelow: "其他，请在下面的文本区域中指定", selectculturalcontext: "选择文化背景", ancientegypt: "古埃及", medievaleurope: "中世纪欧洲", feudaljapan: "封建时代的日本", victorianengland: "维多利亚时代的英格兰", renaissanceitaly: "文艺复兴时期的意大利", nativeamericanfolklore: "美洲原住民传说", africanmythology: "非洲神话", asianlegends: "亚洲传说", wildwest: "狂野西部", selectenvironment: "选择环境", forest: "森林", cityscape: "城市景观", countryside: "乡村", mountains: "山脉", beach: "海滩", underwaterkingdom: "水下王国", outerspace: "外太空", enchantedforest: "魔法森林", magicalcastle: "魔法城堡", desert: "沙漠", arctictundra: "北极苔原", volcanicisland: "火山岛", alienplanet: "外星行星",
        Superheroes: "超级英雄",
        VariousTales: "各种故事", maincharacter: "主要角色",

        animals: "动物",
        toys: "玩具",
        mythicalcreatures: "神话生物",
        momentstogether: "一起的时刻",
        selectone: "选择一个",
        completeregistration: "完成注册",
        subscriptionsuccessful: "耶！您的订阅成功了，您获得了新的故事积分！🎉 查看您的最新余额，请注销并重新登录。准备好迎接更多有趣的冒险吧！",
        paymentissue: "哎呀！看起来您的付款没有成功。请检查您的卡片或PayPal信息，并再试一次。如果问题持续发生，请尝试另一种付款方式，以免错过任何有趣的内容！",
        underreview: "稍等一下！您的订单正在审核中，确保一切安全无误。订单准备好后，我们会立即通知您。感谢您的耐心等待—更多故事即将到来！",
        pendingpayment: "您的付款正在传送给我们！一旦我们收到，您的故事积分将可用。 如果您使用的是PayPal或银行转账，请确保一切都已批准， 这样您就可以重新进入冒险之旅了。",



        selectagegroup: "选择年龄组",
        createstory: "创建故事",
        flipdeviceforbetterview: "翻转设备以获得更好的视图",
        gobacktolibrary: "返回图书馆",
        downloadbook: "下载书籍",
        downloadcoloringbook: "下载涂色书",
        forsupportemailusat: "如需支持，请通过电子邮件联系我们 ",
        chooselanguage: "选择语言",
        currentsubscriptiondetails: "当前订阅详情",
        packagename: "套餐名称",
        enddate: "结束日期",
        remainingbalance: "剩余余额",
        emailaddress: "电子邮件地址",
        renewsubscription: "续订",
        confirrenewsubscriptiondate: "您确定要现在续订订阅吗？",
        ifyoudecidetoupgrade: "如果您决定更改订阅，您的新订阅将从今天开始",
        yes: "是",
        no: "否",
        cancelsubscription: "取消订阅",
        confirmsubcancelation: "您确定要取消订阅吗？取消后，您将只能访问免费功能和一般图书馆",
        toupgradeyourpackage: "要升级或降级您的套餐，请选择以下之一",
        somethingwrongcontactsupport: "可能出了点问题。请联系支持团队寻求帮助。",
        emailverification: "电子邮件验证",
        pleaselogin: "请登录",
        downloadbookfeature: "下载故事书 — 提供原版或涂色书版本，随时可打印",

        child: "孩子",
        superhero: "超级英雄",
        animal: "动物",
        wizard: "巫师",
        fairy: "仙女",
        robot: "机器人",
        fantasycharacters: "幻想角色",
      
        aboutus: "关于我们",
        frequentlyaskedquestion: "常见问题",
        howitworks: "它是如何运作的",

  languagedisclaimer: "请注意，条款和条件以英文显示仅供参考。虽然我们努力使我们的内容以多种语言提供，但这些条款和条件的英文版本在翻译中出现任何差异、误解或法律纠纷的情况下将始终占优。通过同意这些条款和条件，您承认有责任确保在您首选语言中准确解释内容。在任何法律纠纷或歧义情况下，英文版本的条款和条件将优先适用。如果您有任何疑问或需要澄清，我们建议您寻求专业意见或直接与我们联系。",
        availablecurrences: "支持超过100种本地货币，包括美元、欧元、英镑、日元、澳元等！",
        ifyouhaveacouponyoucanredeemit: "如果您有优惠券，可以兑换",
        ifthestoryreloads: "注意：如果表单重新加载，则意味着图像未通过我们的安全检查。请调整您的输入并重试",
        youcanalsosendagift: "您还可以购买一次性礼物并将其发送给某人",
    }
,

    hi: {
        home: "होम",
        pricing: "मूल्य निर्धारण",
        loginpage: "लॉग इन",
        signup: "साइन अप",
        dashboard: "डैशबोर्ड",
        managesubscribtion: "सदस्यता प्रबंधन",
        upgradetopremium: "प्रीमियम में अपग्रेड करें",
        logout: "लॉग आउट",
        chooseacategory: "एक श्रेणी चुनें",
        logingpage: "लॉग इन पेज",
        youarenotloggedin: "आप वर्तमान में लॉग इन नहीं हैं, कृपया यहां लॉग इन करें",
        resetpassword: "पासवर्ड रीसेट",
        enteremailtoresetpassowrd: "अपना ईमेल दर्ज करें पासवर्ड रीसेट करने के लिए",
        email: "ईमेल",
        submit: "सबमिट",
        enteryournewpassword: "अपना नया पासवर्ड दर्ज करें",
        newpassword: "नया पासवर्ड",
        confirmnewpassword: "नए पासवर्ड की पुष्टि करें",
        gotologin: "लॉगिन पेज पर जाएं",
        generallibrary: "सामान्य पुस्तकालय",
        yourlibrary: "आपकी पुस्तकालय",
        createnewbook: "नई पुस्तक बनाएं",
        youarecurrentlynotloggedin: "आप वर्तमान में लॉग इन नहीं हैं, कृपया यहां लॉग इन करें",
        nowpleaseconfirmwhichpackage: "अब कृपया पुष्टि करें कि आप कौन सी पैकेज खरीदना चाहेंगे। वर्तमान में, आप इस ईमेल पते का उपयोग कर रहे हैं:",
        onceyouclickonthebuybutton: "जैसे ही आप 'अब खरीदें' बटन पर क्लिक करेंगे, तो आपको हमारे विश्वसनीय विक्रेता FastSpring पर ट्रांसफर किया जाएगा जहां आप भुगतान विवरणों को अंतिम रूप दे सकेंगे। कृपया सुनिश्चित करें कि आप उसी ईमेल पते का उपयोग करें",
        chooseapackage: "एक पैकेज चुनें:",
        selectyourpackage: "अपना पैकेज चुनें",
        monthly: "मासिक",
        yearly: "वार्षिक",
        buynow: "अब खरीदें",
        thankyouforchoosingpackage: "आपके पैकेज को चुनने के लिए धन्यवाद। भुगतान विवरण को अंतिम रूप देने के बाद कृपया अपना संतुलन अपडेट करने के लिए पुन: लॉगिन करना सुनिश्चित करें। आप अपनी पुस्तकें बना सकेंगे और अपनी निजी लाइब्रेरी तक पहुंच सकेंगे।",
        youarecurrentlysubscribed: "आप वर्तमान में इसके सदस्य हैं",
        pleaseuseyourregisteredemail: "पैकेज। कृपया अपना पंजीकृत ईमेल पता उपयोग करें:",
        tomanageyouraccount: "अपने खाते को प्रबंधित करने के लिए। आप अपनी सदस्यता को प्रबंधित कर सकते हैं",
        here: "यहां",
        noteifyoudecidetoupgradeyourpackage: "नोट: अगर आप अपने पैकेज को अपग्रेड करने का फैसला करते हैं, तो अपग्रेडेड पैकेज अगली बार जब आप लॉग इन करेंगे, दिखाई देगा।",
        yourorderiscompleted: "आपका ऑर्डर पूरा हो गया है",
        turnyourchildreality: "अपने बच्चे की कल्पना को वास्तविकता में बदलें ",
     
      
        whychoose: "क्यों चुनें",
        ourplatformisdedicatedto: "हमारा प्लेटफ़ॉर्म परिवारी बंधनों को मजबूत करने के लिए समर्पित है, व्यक्तिगत कहानी साझा करने के अनुभवों के माध्यम से। हम साझा कहानी के लिए एक मंच प्रदान करके, माता-पिता और बच्चों के बीच गहरे संवाद को सुविधाजनक बनाते हैं, जिससे परिवार साथ में स्थायी स्मृतियां बना सकते हैं। हमारी व्यापक कहानियाँ, बच्चों के रुचियों और विकासात्मक चरणों के अनुसार अनुकूलित, शैक्षिक भागीदारी को बढ़ाती है, साथ ही महत्वपूर्ण विचार और मूल्यों को पुनर्निर्माण करती है।",
        ignitecreativity: "रचनात्मकता को प्रेरित करें",
        empowerchildrenimagination: "बच्चों की कल्पना और रचनात्मकता को बढ़ावा दें, जुटावारेदार कथाओं और जीवंत चित्रणों के माध्यम से। हमारी सेवा रचनात्मक सोच और समस्या समाधान के कौशल को बढ़ावा देती है।",
        connectondeeperlevel: "गहरे स्तर पर जुड़ें",
        fosteradeeperbond: "माता-पिता और बच्चों के बीच एक गहरे बंधन को बढ़ावा दें व्यक्तिगत कहानी साझा करने के अनुभवों के माध्यम से। हमारा प्लेटफ़ॉर्म परिवारों के लिए स्थायी स्मृतियां बनाने के लिए इंटरैक्टिव तत्व प्रदान करता है।",
        funlearningadventures: "मजेदार शिक्षा साहसिक",
        leverageourservicestoprovide: "हमारी सेवाओं का उपयोग करके शिक्षात्मक सामग्री को एक मजेदार और आकर्षक तरीके से प्रदान करने में सहायता प्राप्त करें। बच्चे अपने रुचियों और विकासात्मक स्तर के अनुसार अत्याधिक कहानियों के माध्यम से मूल्यवान सीखेंगे।",
        discoverwhyfamilies: "जानें क्यों परिवार हमें विश्वस्त करते हैं",
        convenienceandsimplified: "सुविधा और सरल पहुँच",
        saygoodbuytothehassle: "उम्र-अनुकूल पुस्तकों की खोज करने या रचनात्मक विचारों की खोज में समस्याओं का अलविदा कहें। हमारी सेवा आपके हाथों में व्यक्तिगत कहानियों और चित्रण प्रदान करती है।",
        customizedlearningexperience: "अनुकूलित शिक्षा अनुभव",
        tailorstorytelling: "अपने बच्चे की व्यक्तिगत आवश्यकताओं और रुचियों के अनुसार कहानी की अनुभव को अनुकूलित करें। क्या विशिष्ट शिक्षा सिद्धांतों को मजबूती देना या सामाजिक-भावनात्मक विषयों का समाधान करना है, हमारी सेवा आपके बच्चे के विकासात्मक स्तर और शैक्षिक शैली के अनुसार बदल जाती है।",
        parentalempowement: "माता-पिता को सशक्त करना",
        takeanactiverole: "हमारे उपकरणों और संसाधनों के साथ अपने बच्चे की शिक्षा और विकास में सक्रिय भूमिका निभाएं। सहयोगी कहानी साझा करने के अनुभव के माध्यम से अपने बच्चे की शिक्षा यात्रा को सशक्त करें और अपने संबंधों को मजबूत करें।",
        timesavingsolution: "समय बचाने का समाधान",
        savetimebyeleminating: "शिक्षात्मक सामग्रियों के अनुसंधान और अनुकूलन की आवश्यकता को खत्म करके समय बचाएं। हमारे प्लेटफ़ॉर्म पर अपने बच्चे की आवश्यकताओं और रुचियों के अनुसार व्यक्तिगत और उच्च-गुणवत्ता की, व्यक्तिगत सामग्री प्रदान करें।",
        experiencethemagic: "हमारे साथ कहानी का जादू अनुभव करें। हमारे",
        pricingpage: "मूल्य निर्धारण पृष्ठ",
        formoreinformationandstart: "अधिक जानकारी के लिए और अपने परिवार के साथ महत्वपूर्ण स्मृतियां बनाना शुरू करें।",
        welcomebackpleaselogin: "वापस स्वागत है! कृपया लॉगिन करके जारी रखें।",
        password: "पासवर्ड",
        donthaveanouaccount: "खाता नहीं है, आप साइन अप कर सकते हैं ",
        forgotyourpassword: "क्या आप अपना पासवर्ड भूल गए हैं? आप इसे रीसेट कर सकते हैं",
        welcome: "स्वागत है",
        letscreateyouraccount: "अपने खाता बनाएं!",
        firstname: "पहला नाम",
        lastname: "अंतिम नाम",
        termsandconditions: "नियम और शर्तें",
        free: "मुफ्त",
        freeuser: "मुफ्त उपयोगकर्ता",
        accesstogenerallibrary: "सामान्य पुस्तकालय और मासिक विशेष किताबों तक पहुंच",
        earlyaccesstonewfeature: "प्रोमोशंस के लिए जल्दी पहुंच — नए फीचर्स और ऑफर्स सबसे पहले पाएं",
        nocreditcardrequired: "कोई क्रेडिट कार्ड आवश्यक नहीं — बिना किसी प्रतिबद्धता के शुरू करें",
        signupnow: "अब साइन अप करें",
        generalandprivatelibraries: "सामान्य और निजी पुस्तकालयों तक पहुँचें — हर महीने नई कहानियाँ खोजें।",
        accesstonewaddedmonthlly: "मासिक रूप से नए विशेष किताबों तक पहुंच — हर महीने नई कहानियों का आनंद लें",
        questbook: "5 कस्टम कहानी किताबें — 8 पृष्ठों तक तैयार, पूरी तरह से आपके बच्चे के लिए व्यक्तिगत",
        odysseybook: "12 कस्टम कहानी किताबें — 8 पृष्ठों तक तैयार, पूरी तरह से आपके बच्चे के लिए व्यक्तिगत",
        enchantedbook: "29 कस्टम कहानी किताबें — 8 पृष्ठों तक तैयार, पूरी तरह से आपके बच्चे के लिए व्यक्तिगत",
        questbookannually: "60 कस्टम कहानी किताबें — 8 पृष्ठों तक तैयार, पूरी तरह से आपके बच्चे के लिए व्यक्तिगत",
        odysseybookannually: "145 कस्टम कहानी किताबें — 8 पृष्ठों तक तैयार, पूरी तरह से आपके बच्चे के लिए व्यक्तिगत",
        enchantedbookannually: "350 कस्टम कहानी किताबें — 8 पृष्ठों तक तैयार, पूरी तरह से आपके बच्चे के लिए व्यक्तिगतई",
        forallpaidpackages: "सभी भुगतान किए गए पैकेज के लिए, चेकआउट पर आप वार्षिक बिलिंग का चयन कर सकते हैं और पाएं ",
        onemonthfree: "एक महीना मुफ्त!",
        title: "शीर्षक",
        creationdate: "निर्माण तिथि",
        action: "कार्रवाई",
        view: "देखें",
        pleasebarewithus: "हमारे साथ रहने के लिए धैर्य रखें, अच्छी चीजें समय लेती हैं!",
        loading: "लोड हो रहा है",
        booksremaining: "बची हुई पुस्तकें:",
        gender: "लिंग:",
        booklength: "पुस्तक की लंबाई:",
        agegroup: "आयु समूह:",
        lessonlearned: "शिक्षाएँ सीखीं",
        morallesson: "नैतिक शिक्षा",
        themes: "थीम्स",
        visualstyle: "दृश्य शैली:",
        Whatillustrationstyle: "आपको कौन सी चित्रण शैली पसंद है?",
        characterarechetype: "किरदार के प्रकार:",
        genre: "शैली:",
        plotelements: "कहानी के तत्व:",
        magicalelements: "जादुई तत्व:",
        cluturalcontext: "सांस्कृतिक संदर्भ:",
        emotionaltone: "भावनात्मक टोन:",
        environment: "पर्यावरण:",
        specificpreferences: "विशेष पसंद? अपनी कहानी को और अधिक अनुकूलित करें:",
        storycreatedsuccesfully: "कहानी सफलतापूर्वक बनाई और सहेजी गई!",
        failedtocreatestory: "कहानी बनाने और सहेजने में विफल!",
        toviewyourlibrary: "अपनी पुस्तकालय देखने के लिए, क्लिक करें",
        unfortunatellyyouhaveconsumed: "दुर्भाग्य से, आपने महीने की सभी पुस्तकें उपभोगित कर ली हैं। अगर आप अपने पैकेज को अपग्रेड करना चाहें, तो कृपया यहां जाएं",
        language: "भाषा:",
        english: "अंग्रेज़ी",
        arabic: "अरबी",
        bulgarian: "बल्गेरियाई",
        chinese: "चीनी",
        french: "फ्रेंच",
        german: "जर्मन",
        hindi: "हिंदी",
        korean: "कोरियाई",
        portuguese: "पुर्तगाली",
        russian: "रूसी",
        spanish: "स्पेनिश",
        dutch: "डच",

        safeContent: 'क्या सामग्री बच्चों के लिए सुरक्षित और उपयुक्त है?',
        safeContentAnswer: 'हाँ, GWOW AI की सामग्री बच्चों के लिए सुरक्षित और उपयुक्त है। हम इस बात का पूरा ध्यान रखते हैं कि हमारे AI द्वारा उत्पन्न सभी कहानियाँ और चित्रण छोटे दर्शकों के लिए उपयुक्त हों। हमारा प्लेटफ़ॉर्म माता-पिता को कहानियों को अनुकूलित करने की अनुमति देता है, जिससे यह सुनिश्चित होता है कि सामग्री उनकी विशिष्ट आवश्यकताओं और प्राथमिकताओं को पूरा करती है।',
        supportedDevices: 'AI इंटरएक्टिव स्टोरीटेलर द्वारा कौन से उपकरण समर्थित हैं?',
        supportedDevicesAnswer: 'AI इंटरएक्टिव स्टोरीटेलर सभी आधुनिक उपकरणों का समर्थन करता है, जिसमें स्मार्टफ़ोन, टैबलेट और कंप्यूटर शामिल हैं। आपको वेबसाइट का उपयोग करने के लिए इंटरनेट कनेक्शन की आवश्यकता होती है, लेकिन आप ऑफ़लाइन उपयोग के लिए कहानियों को स्थानीय रूप से भी डाउनलोड कर सकते हैं।',
        globalSupport: 'क्या यह वैश्विक रूप से समर्थित है?',
        globalSupportAnswer: 'हाँ, GWOW AI वैश्विक रूप से समर्थित है। उपयोगकर्ता 12 विभिन्न भाषाओं में से चुन सकते हैं ताकि वे अपनी कहानियाँ बना सकें और उनका आनंद ले सकें, जिससे यह पूरी दुनिया में एक व्यापक दर्शकों के लिए सुलभ हो सके।',
        freeTrial: 'क्या कोई मुफ्त परीक्षण उपलब्ध है?',
        freeTrialAnswer: 'हाँ, एक मुफ्त परीक्षण उपलब्ध है। आप सामान्य पुस्तकालय तक पहुंचने के लिए एक मुफ्त उपयोगकर्ता खाता बना सकते हैं। अनुकूलित कहानियों को बनाने के लिए, आपको हमारे 7.99 डॉलर से शुरू होने वाले पैकेज में से किसी एक की सदस्यता लेनी होगी।',
        dataCollection: 'क्या डेटा एकत्र किया जाता है और इसका उपयोग कैसे किया जाता है?',
        dataCollectionAnswer: 'हम अपनी सेवाओं को प्रदान करने और सुधारने के लिए बुनियादी लॉगिन जानकारी एकत्र करते हैं। यह जानकारी सुरक्षित रखी जाती है, किसी के साथ साझा नहीं की जाती है, और न ही बेची जाती है।',
        aiBooksCreativity: "AI-जनित पुस्तकें मेरे बच्चे की रचनात्मकता और सीखने में कैसे सुधार कर सकती हैं?",
        aiBooksCreativityAnswer: "AI-जनित पुस्तकें आपके बच्चे की रचनात्मकता और सीखने में व्यक्तिगत और आकर्षक कहानियाँ प्रदान करके काफी सुधार कर सकती हैं। ये कहानियाँ आपके बच्चे की रुचियों और सीखने की जरूरतों के अनुसार अनुकूलित की जा सकती हैं, जिससे पढ़ना एक अधिक इंटरैक्टिव और आनंददायक अनुभव बन जाता है। इसके अतिरिक्त, माता-पिता कहानियों को पीडीएफ़ या रंग पुस्तक के रूप में डाउनलोड कर सकते हैं ताकि उनके बच्चों को और अधिक आकर्षित कर सकें।",

        faqAppropriateContent: 'क्या सामग्री बच्चों के लिए सुरक्षित और उपयुक्त है?',
        faqSupportedDevices: 'एआई इंटरैक्टिव स्टोरीटेलर द्वारा कौन से उपकरण समर्थित हैं?',
        faqGlobalSupport: 'क्या यह वैश्विक स्तर पर समर्थित है?',
        faqFreeTrial: 'क्या मुफ्त परीक्षण उपलब्ध है?',
        faqDataCollection: 'कौन से डेटा एकत्र किए जाते हैं और उनका उपयोग कैसे किया जाता है?',
        faqAIBooks: 'एआई-जनित पुस्तकों से मेरे बच्चे की रचनात्मकता और सीखने में कैसे सुधार हो सकता है?',
        answerAppropriateContent: 'हां, GWOW AI पर सामग्री बच्चों के लिए सुरक्षित और उपयुक्त है। हम यह सुनिश्चित करने के लिए बहुत सावधानी बरतते हैं कि हमारी एआई द्वारा उत्पन्न सभी कहानियाँ और चित्रण युवा दर्शकों के लिए उपयुक्त हों। हमारा प्लेटफ़ॉर्म माता-पिता को कहानियाँ अनुकूलित करने की अनुमति देता है, यह सुनिश्चित करता है कि सामग्री उनके विशिष्ट आवश्यकताओं और प्राथमिकताओं को पूरा करती है।',
        answerSupportedDevices: 'एआई इंटरैक्टिव स्टोरीटेलर सभी आधुनिक उपकरणों का समर्थन करता है, जिसमें स्मार्टफोन, टैबलेट और कंप्यूटर शामिल हैं। वेबसाइट का उपयोग करने के लिए आपको इंटरनेट कनेक्शन की आवश्यकता होती है, लेकिन आप कहानियों को ऑफ़लाइन उपयोग के लिए भी डाउनलोड कर सकते हैं।',
        answerGlobalSupport: 'हां, GWOW AI वैश्विक स्तर पर समर्थित है। उपयोगकर्ता 12 विभिन्न भाषाओं में से चुन सकते हैं ताकि वे अपनी कहानियाँ बना और आनंद ले सकें, जिससे यह दुनिया भर में एक व्यापक दर्शकों के लिए सुलभ हो सके।',
        answerFreeTrial: 'हां, एक मुफ्त परीक्षण उपलब्ध है। आप सामान्य पुस्तकालय तक पहुंचने के लिए एक मुफ्त उपयोगकर्ता खाता बना सकते हैं। व्यक्तिगत कहानियाँ बनाने के लिए, आपको हमारे पैकेजों में से एक की सदस्यता लेनी होगी, जिसकी शुरुआत $4.99 से होती है।',
        answerDataCollection: 'हम अपनी सेवाएं प्रदान करने और सुधारने के लिए बुनियादी लॉगिन जानकारी एकत्र करते हैं। यह जानकारी सुरक्षित रखी जाती है, किसी के साथ साझा नहीं की जाती है और बेची नहीं जाती है।',
        answerAIBooks: 'एआई-जनित पुस्तकें आपके बच्चे की रचनात्मकता और सीखने में व्यक्तिगत और आकर्षक कहानियाँ प्रदान करके काफी सुधार कर सकती हैं। ये कहानियाँ आपके बच्चे के रुचियों और सीखने की आवश्यकताओं के अनुसार बनाई जा सकती हैं, जिससे पढ़ाई एक अधिक इंटरैक्टिव और आनंदमय अनुभव बन जाती है। इसके अतिरिक्त, माता-पिता अपने बच्चों को और अधिक शामिल करने के लिए कहानियों को पीडीएफ या रंग भरने वाली पुस्तकों के रूप में डाउनलोड कर सकते हैं।',
        learnmore: "अधिक जानें:",
        years: "साल", selectgender: "लिंग चुनें", male: "पुरुष", female: "महिला", unspecified: "अनिर्दिष्ट", selectbooklength: "पुस्तक की लंबाई चुनें", pages: "पृष्ठ", selectmorallesson: "नैतिक शिक्षा चुनें", friendship: "मित्रता", kindness: "दयालुता", courage: "साहस", honesty: "ईमानदारी", empathy: "सहानुभूति", teamwork: "टीमवर्क", acceptance: "स्वीकृति", responsibility: "जिम्मेदारी", creativity: "रचनात्मकता", selectvisualstyle: "दृश्य शैली चुनें", realistic: "यथार्थवादी", cartoon: "कार्टून", painterly: "चित्रकार शैली", anime: "एनीमे", fantasy: "काल्पनिक",
        watercolor: "वॉटरकलर",
        popart: "पॉप कला", selectgenre: "शैली चुनें", adventure: "साहसिक", mystery: "रहस्य", sciencefiction: "विज्ञान कथा", historicalfiction: "ऐतिहासिक कल्पित कथा", romance: "रोमांस", comedy: "कॉमेडी", addatwist: "एक मोड़ जोड़ें", selectmagicalelement: "जादुई तत्व चुनें", wizards: "जादूगर", mermaids: "जलपरी", dinosaurs: "डायनासोर", robots: "रोबोट", cars: "कारें", pets: "पालतू",
        dragons: "ड्रैगन", aliens: "एलियंस", fairies: "परियाँ", superheroes: "सुपरहीरो", pirates: "समुद्री डाकू", cowboys: "काउबॉय", secretworlds: "गुप्त संसार", magicalcreatures: "जादुई प्राणी", knights: "शूरवीर", princesses: "राजकुमारियाँ", otherspleasespecifybelow: "अन्य, कृपया नीचे के टेक्स्ट क्षेत्र में निर्दिष्ट करें", selectculturalcontext: "संस्कृति प्रसंग चुनें", ancientegypt: "प्राचीन मिस्र", medievaleurope: "मध्यकालीन यूरोप", feudaljapan: "सामंती जापान", victorianengland: "विक्टोरियन इंग्लैंड", renaissanceitaly: "पुनर्जागरण इटली", nativeamericanfolklore: "देशी अमेरिकी लोककथाएं", africanmythology: "अफ्रीकी पौराणिक कथाएं", asianlegends: "एशियाई दंतकथाएं", wildwest: "जंगली पश्चिम", selectenvironment: "पर्यावरण चुनें", forest: "जंगल", cityscape: "शहरी परिदृश्य", countryside: "ग्रामीण इलाका", mountains: "पहाड़", beach: "समुद्र तट", underwaterkingdom: "जलमग्न राज्य", outerspace: "अंतरिक्ष", enchantedforest: "जादुई जंगल", magicalcastle: "जादुई किला", desert: "रेगिस्तान", arctictundra: "आर्कटिक टुंड्रा", volcanicisland: "ज्वालामुखीय द्वीप", alienplanet: "एलियन ग्रह",
        Superheroes: "सुपरहीरो",
        VariousTales: "विभिन्न कहानियाँ", maincharacter: "मुख्य पात्र",

        animals: "जानवर",
        toys: "खिलौने",
        mythicalcreatures: "पौराणिक जीव",
        momentstogether: "साथ के पल",
        selectone: "एक चुनें",
        completeregistration: "पंजीकरण पूरा करें",

        subscriptionsuccessful: "याय! आपकी सदस्यता सफल रही, और आपके पास नई स्टोरी क्रेडिट्स हैं! 🎉 अपना अपडेटेड बैलेंस देखने के लिए कृपया लॉग आउट करें और फिर से लॉग इन करें। और भी मजेदार रोमांच के लिए तैयार हो जाइए!",
        paymentissue: "ओह! लगता है आपका भुगतान सफल नहीं हुआ। कृपया अपने कार्ड या PayPal की जानकारी जांचें और दोबारा कोशिश करें। यदि समस्या बनी रहती है, तो किसी अन्य भुगतान विधि का उपयोग करें ताकि आप कोई मजेदार चीज़ न चूकें!",
        underreview: "थोड़ा इंतजार करें! आपका ऑर्डर समीक्षा के अधीन है ताकि सब कुछ सुरक्षित और सही हो। जैसे ही यह तैयार होगा, हम आपको सूचित करेंगे। धन्यवाद—जल्द ही और कहानियाँ आ रही हैं!",
        pendingpayment: "आपका भुगतान हमारे पास आ रहा है! जैसे ही हमें यह प्राप्त होगा, आपकी स्टोरी क्रेडिट्स उपयोग के लिए तैयार हो जाएंगी। यदि आप PayPal या वायर ट्रांसफर का उपयोग कर रहे हैं, तो सुनिश्चित करें कि सब कुछ स्वीकृत हो गया है ताकि आप फिर से रोमांच में कूद सकें।",


        selectagegroup: "आयु वर्ग चुनें",
        createstory: "कहानी बनाएं",
        flipdeviceforbetterview: "बेहतर दृश्य के लिए डिवाइस घुमाएं",
        gobacktolibrary: "पुस्तकालय में वापस जाएं",
        downloadbook: "पुस्तक डाउनलोड करें",
        downloadcoloringbook: "रंग भरने की पुस्तक डाउनलोड करें",
        forsupportemailusat: "समर्थन के लिए हमें ईमेल करें ",
        chooselanguage: "भाषा चुनें",
        currentsubscriptiondetails: "वर्तमान सदस्यता विवरण",
        packagename: "पैकेज नाम",
        enddate: "समाप्ति तिथि",
        remainingbalance: "शेष राशि",
        emailaddress: "ईमेल पता",
        renewsubscription: "सदस्यता नवीनीकृत करें",
        confirrenewsubscriptiondate: "क्या आप निश्चित रूप से अपनी सदस्यता अब नवीनीकरण करना चाहते हैं?",
        ifyoudecidetoupgrade: "यदि आप अपनी सदस्यता बदलने का निर्णय लेते हैं, तो आपकी नई सदस्यता आज से शुरू होगी",
        yes: "हाँ",
        no: "नहीं",
        cancelsubscription: "सदस्यता रद्द करें",
        confirmsubcancelation: "क्या आप सुनिश्चित हैं कि आप अपनी सदस्यता रद्द करना चाहते हैं? एक बार रद्द होने के बाद, आपको केवल मुफ्त सुविधाओं और सामान्य पुस्तकालयों तक ही पहुंच प्राप्त होगी",
        toupgradeyourpackage: "अपना पैकेज अपग्रेड या डाउनग्रेड करने के लिए, निम्नलिखित में से एक चुनें",
        somethingwrongcontactsupport: "कुछ गड़बड़ हो सकती है। कृपया सहायता के लिए सहायता टीम से संपर्क करें।",
        emailverification: "ईमेल सत्यापन",
        pleaselogin: "कृपया साइन इन करें",
        downloadbookfeature: "डाउनलोड स्टोरीबुक — मूल या रंग भरने वाली पुस्तक संस्करण में, प्रिंट करने के लिए तैयार",
        child: "बच्चा",
        superhero: "सुपरहीरो",
        animal: "जानवर",
        wizard: "जादूगर",
        fairy: "परी",
        robot: "रोबोट",
        fantasycharacters: "काल्पनिक पात्र",
        aboutus: "हमारे बारे में",
        frequentlyaskedquestion: "सामान्य प्रश्न",
        howitworks: "यह कैसे काम करता है",
  languagedisclaimer: "कृपया ध्यान दें कि संदर्भ उद्देश्यों के लिए नियम और शर्तें अंग्रेज़ी में प्रदर्शित की गई हैं। जबकि हम अपनी सामग्री को कई भाषाओं में उपलब्ध कराने का प्रयास करते हैं, अनुवाद से उत्पन्न किसी भी असंगति, गलतफहमी या कानूनी विवाद की स्थिति में, इन नियमों और शर्तों का अंग्रेज़ी संस्करण हमेशा प्रबल होगा। इन नियमों और शर्तों से सहमति जताने पर, आप इस बात को स्वीकार करते हैं कि अपनी पसंदीदा भाषा में सामग्री की सटीक व्याख्या सुनिश्चित करना आपकी ज़िम्मेदारी है। किसी भी कानूनी विवाद या अस्पष्टता की स्थिति में, नियमों और शर्तों का अंग्रेज़ी संस्करण प्राथमिकता प्राप्त करेगा। यदि आपके कोई प्रश्न हैं या स्पष्टीकरण की आवश्यकता है, तो हम आपको पेशेवर सलाह लेने या सीधे हमसे संपर्क करने के लिए प्रोत्साहित करते हैं।",
        availablecurrences: "100 से अधिक स्थानीय मुद्राओं का समर्थन, जिसमें USD, EUR, GBP, JPY, AUD और अन्य शामिल हैं!",
        ifyouhaveacouponyoucanredeemit: "यदि आपके पास कूपन है, तो आप इसे भुना सकते हैं",
        ifthestoryreloads: "नोट: यदि फॉर्म पुनः लोड होता है, तो इसका मतलब है कि छवियां हमारी सुरक्षा जांच पास नहीं कर पाई। कृपया अपनी इनपुट को समायोजित करें और फिर से प्रयास करें",
        youcanalsosendagift: "आप एक बार का उपहार भी खरीद सकते हैं और इसे किसी को भेज सकते हैं",

    },

    ar: {
        home: "الصفحة الرئيسية",
        pricing: "التسعير",
        loginpage: "تسجيل الدخول",
        signup: "التسجيل",
        dashboard: "لوحة التحكم",
        managesubscribtion: "إدارة الاشتراك",
        upgradetopremium: "الترقية إلى الاشتراك المميز",
        logout: "تسجيل الخروج",
        chooseacategory: "اختر فئة",
        logingpage: "صفحة تسجيل الدخول",
        youarenotloggedin: "أنت غير مسجل دخولك حاليًا، يرجى تسجيل الدخول هنا",
        resetpassword: "إعادة تعيين كلمة المرور",
        enteremailtoresetpassowrd: "أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور",
        email: "البريد الإلكتروني",
        submit: "إرسال",
        enteryournewpassword: "أدخل كلمة المرور الجديدة",
        newpassword: "كلمة المرور الجديدة",
        confirmnewpassword: "تأكيد كلمة المرور الجديدة",
        gotologin: "الانتقال إلى تسجيل الدخول",
        generallibrary: "المكتبة العامة",
        yourlibrary: "مكتبتك",
        createnewbook: "إنشاء كتاب جديد",
        youarecurrentlynotloggedin: "أنت غير مسجل دخولك حاليًا، يرجى تسجيل الدخول هنا",
        nowpleaseconfirmwhichpackage: "يرجى تأكيد الآن الحزمة التي ترغب في شرائها. حاليا، أنت تستخدم عنوان البريد الإلكتروني هذا:",
        onceyouclickonthebuybutton: "بمجرد النقر على زر 'شراء الآن' أدناه، ستتم نقلك إلى البائع الموثوق به لدينا FastSpring حيث يمكنك إكمال تفاصيل الدفع. يرجى التأكد من استخدام نفس عنوان البريد الإلكتروني",
        chooseapackage: "اختر حزمة:",
        selectyourpackage: "اختر حزمتك",
        monthly: "شهرياً",
        yearly: "سنوياً",
        buynow: "شراء الآن",
        thankyouforchoosingpackage: "شكرًا لاختيارك الباقة الخاصة بك. بعد إتمام تفاصيل الدفع الخاصة بك، يرجى التأكد من إعادة تسجيل الدخول لتحديث رصيدك. ستتمكن من كتابه كتبك الخاصة والوصول إلى مكتبتك .",
        youarecurrentlysubscribed: "أنت حاليا مشترك في",
        pleaseuseyourregisteredemail: "الحزمة. يرجى استخدام عنوان البريد الإلكتروني المسجل الخاص بك:",
        tomanageyouraccount: "لإدارة حسابك. يمكنك إدارة اشتراكك",
        here: "هنا",
        noteifyoudecidetoupgradeyourpackage: "ملاحظة: إذا قررت ترقية حزمتك، ستظهر الحزمة المحدثة في المرة التالية التي تقوم فيها بتسجيل الدخول.",
        yourorderiscompleted: "تم إتمام طلبك",
        turnyourchildreality: "حوّل خيال طفلك إلى حقيقة مع ",
     
      
        whychoose: "لماذا تختار",
        ourplatformisdedicatedto: "منصتنا مكرسة لتعزيز الروابط العائلية من خلال تجارب السرد الشخصية. من خلال توفير منصة للسرد المشترك، نسهل التواصل الأعمق بين الآباء والأطفال، مما يسمح للعائلات بخلق ذكريات دائمة معًا. تروي القصص الغامرة التي تتماشى مع اهتمامات الأطفال ومراحل تطورهم، وتعزز المشاركة التعليمية بينما تعزز المفاهيم والقيم الهامة",
        ignitecreativity: "اشعل الإبداع",
        empowerchildrenimagination: "شجّع خيال وإبداع الأطفال من خلال سرد مثير ورسوم توضيحية حيوية. يشجع خدمتنا التفكير الإبداعي ومهارات حل المشكلات.",
        connectondeeperlevel: "التواصل على مستوى أعمق",
        fosteradeeperbond: "عزز الروابط العميقة بين الآباء والأطفال من خلال تجارب السرد الشخصية. توفر منصتنا عناصر تفاعلية للعائلات لخلق ذكريات دائمة معًا.",
        funlearningadventures: "مغامرات تعلم ممتعة",
        leverageourservicestoprovide: "استفد من خدماتنا لتقديم محتوى تعليمي بطريقة ممتعة وجذابة. يتعلم الأطفال دروسًا ومهارات قيمة من خلال سرد قصص غامرة مصممة وفقًا لاهتماماتهم ومراحل تطورهم",
        discoverwhyfamilies: "اكتشف لماذا تثق العائلات بنا",
        convenienceandsimplified: "الراحة والوصول الميسر",
        saygoodbuytothehassle: "وداعًا للبحث عن كتب مناسبة للعمر أو محاولة العثور على أفكار إبداعية. توفر خدمتنا قصصًا ورسومًا توضيحية شخصية بين يديك.",
        customizedlearningexperience: "تجربة تعلم مخصصة",
        tailorstorytelling: "ضبط تجارب السرد وفقًا لاحتياجات واهتمامات طفلك الفردية. سواءً كان تعزيز مفاهيم تعلم معينة أو معالجة مواضيع اجتماعية وعاطفية، تكييف خدمتنا مع مرحلة تطور طفلك وأسلوب تعلمه",
        parentalempowement: "تمكين الوالدين",
        takeanactiverole: "تولَّوا دوراً فعَّالاً في تعليم طفلكم ونموه باستخدام أدواتنا ومواردنا. قوموا بتمكين رحلة تعلم طفلكم وتعزيز علاقتكم من خلال تجارب السرد التعاوني.",
        timesavingsolution: "حلاً لتوفير الوقت",
        savetimebyeleminating: "وفِّروا الوقت عن طريق القضاء على الحاجة إلى البحث وتنسيق المواد التعليمية. اعتمدوا على منصتنا لتوفير محتوى شخصي ذو جودة عالية مصمم خصيصاً لاحتياجات واهتمامات طفلكم.",
        experiencethemagic: "استمتعوا بسحر السرد معنا اليوم. تفقدوا ",
        pricingpage: "صفحة التسعير",
        formoreinformationandstart: "للمزيد من المعلومات وبدء خلق الذكريات ذات المغزى مع عائلتكم.",
        welcomebackpleaselogin: "مرحباً بعودتكم! يرجى تسجيل الدخول للمتابعة.",
        password: "كلمة المرور",
        donthaveanouaccount: "ليس لديك حساب؟ يمكنك التسجيل ",
        forgotyourpassword: "نسيت كلمة المرور؟ يمكنك إعادة تعيينها ",
        welcome: "مرحباً",
        letscreateyouraccount: "لنقم بإنشاء حسابك!",
        firstname: "الاسم الأول",
        lastname: "اسم العائلة",
        termsandconditions: "الشروط والأحكام",
        free: "مجاني",
        freeuser: "مستخدم مجاني",
        accesstogenerallibrary: "الوصول إلى المكتبة العامة والكتب المميزة الشهرية",
        earlyaccesstonewfeature: "الوصول المبكر إلى العروض الترويجية — كن أول من يكتشف الميزات والعروض الجديدة",
        nocreditcardrequired: "لا حاجة لبطاقة ائتمان — ابدأ رحلتك بدون أي التزامات",
        signupnow: "سجل الآن",
        generalandprivatelibraries: "الوصول إلى المكتبات العامة والخاصة - استكشف قصصًا جديدة مميزة كل شهر",
        accesstonewaddedmonthlly: "الوصول الشهري إلى الكتب المميزة الجديدة — اكتشف قصص جديدة كل شهر",
        questbook: "5 كتب مخصصة — حتى 8 صفحات، مصممة بالكامل وفقًا لاهتمامات طفلك",
        odysseybook: "12 كتابًا مخصصًا — حتى 8 صفحات، مصممة بالكامل وفقًا لاهتمامات طفلك",
        enchantedbook: "29 كتابًا مخصصًا — حتى 8 صفحات، مصممة بالكامل وفقًا لاهتمامات طفلك",
        questbookannually: "60 كتب مخصصة — حتى 8 صفحات، مصممة بالكامل وفقًا لاهتمامات طفلك",
        odysseybookannually: "145 كتابًا مخصصًا — حتى 8 صفحات، مصممة بالكامل وفقًا لاهتمامات طفلك",
        enchantedbookannually: "350 كتابًا مخصصًا — حتى 8 صفحات، مصممة بالكامل وفقًا لاهتمامات طفلك",
        forallpaidpackages: "لجميع الحزم المدفوعة، يمكنكم اختيار الفوترة السنوية والحصول على ",
        onemonthfree: "شهر واحد مجاناً!",
        title: "العنوان",
        creationdate: "تاريخ الإنشاء",
        action: "الإجراء",
        view: "عرض",
        pleasebarewithus: "يرجى التحلي بالصبر، الأمور الجيدة تأخذ وقتاً!",
        loading: "جار التحميل",
        booksremaining: "الكتب المتبقية:",
        gender: "الجنس:",
        booklength: "طول الكتاب:",
        agegroup: "الفئة العمرية:",
        morallesson: "الدروس المستفادة",
        themes: "المواضيع",
        visualstyle: "الأسلوب البصري:",
        Whatillustrationstyle: "ما نوع أسلوب الرسم الذي تجده جذابًا؟",
        characterarechetype: "أنماط الشخصيات:",
        genre: "النوع:",
        plotelements: "عناصر الحبكة:",
        magicalelements: "العناصر السحرية:",
        cluturalcontext: "السياق الثقافي:",
        emotionaltone: "اللهجة العاطفية:",
        environment: "البيئة:",
        specificpreferences: "هل لديك تفضيلات محددة؟ قم بتخصيص قصتك أكثر:",
        storycreatedsuccesfully: "تم إنشاء القصة وحفظها بنجاح!",
        failedtocreatestory: "فشل في إنشاء وحفظ القصة!",
        toviewyourlibrary: "لعرض مكتبتك، انقر",
        unfortunatellyyouhaveconsumed: "للأسف، لقد استهلكت كل كتبك لهذا الشهر. إذا كنت ترغب في ترقية حزمتك، يرجى زيارة ",
        language: "اللغة:",
        english: "الإنجليزية",
        arabic: "العربية",
        bulgarian: "البلغارية",
        chinese: "الصينية",
        french: "الفرنسية",
        german: "الألمانية",
        hindi: "الهندية",
        korean: "الكورية",
        portuguese: "البرتغالية",
        russian: "الروسية",
        spanish: "الإسبانية",
        dutch: "الهولندية",
        safeContent: 'هل المحتوى آمن ومناسب للأطفال؟',
        safeContentAnswer: 'نعم، المحتوى الموجود على GWOW AI آمن ومناسب للأطفال. نحن نحرص بشدة على ضمان أن تكون جميع القصص والرسوم التوضيحية التي ينتجها الذكاء الاصطناعي لدينا مناسبة لجمهور الأطفال. يوفر منصتنا للأهالي إمكانية تخصيص القصص، لضمان أن المحتوى يتماشى مع متطلباتهم وتفضيلاتهم الخاصة.',
        supportedDevices: 'ما هي الأجهزة المدعومة من قبل الراوي التفاعلي الذكي؟',
        supportedDevicesAnswer: 'الراوي التفاعلي الذكي يدعم جميع الأجهزة الحديثة، بما في ذلك الهواتف الذكية والأجهزة اللوحية وأجهزة الكمبيوتر. تحتاج إلى اتصال بالإنترنت للوصول إلى الموقع، ولكن يمكنك أيضًا تنزيل القصص للوصول إليها في وضع عدم الاتصال.',
        globalSupport: 'هل هو مدعوم عالميًا؟',
        globalSupportAnswer: 'نعم، GWOW AI مدعوم عالميًا. يمكن للمستخدمين اختيار 12 لغة مختلفة لإنشاء والاستمتاع بقصصهم، مما يجعله متاحًا لجمهور واسع حول العالم.',
        freeTrial: 'هل يوجد تجربة مجانية متاحة؟',
        freeTrialAnswer: 'نعم، تتوفر تجربة مجانية. يمكنك إنشاء حساب مستخدم مجاني للوصول إلى المكتبة العامة. لإنشاء قصص مخصصة، ستحتاج إلى الاشتراك في واحدة من باقتنا الشهريه .',
        dataCollection: 'ما هي البيانات التي يتم جمعها وكيف يتم استخدامها؟',
        dataCollectionAnswer: 'نحن نجمع معلومات تسجيل الدخول الأساسية لتقديم وتحسين خدماتنا. يتم الاحتفاظ بهذه المعلومات بشكل آمن، ولا يتم مشاركتها مع أي شخص، ولن يتم بيعها.',
        aiBooksCreativity: "كيف يمكن للكتب التي يتم إنشاؤها بواسطة الذكاء الاصطناعي أن تحسن من إبداع طفلي وتعلمه؟",
        aiBooksCreativityAnswer: "الكتب التي يتم إنشاؤها بواسطة الذكاء الاصطناعي يمكن أن تعزز بشكل كبير من إبداع طفلك وتعلمه من خلال تقديم قصص مخصصة وجذابة. يمكن تخصيص هذه القصص وفقًا لاهتمامات واحتياجات طفلك التعليمية، مما يجعل القراءة تجربة تفاعلية وممتعة. بالإضافة إلى ذلك، يمكن للأهالي تنزيل القصص كملفات PDF أو كتب تلوين لإشراك أطفالهم بشكل أكبر.",

        faqAppropriateContent: 'هل المحتوى آمن ومناسب للأطفال؟',
        faqSupportedDevices: 'ما الأجهزة التي يدعمها القاص التفاعلي بالذكاء الاصطناعي؟',
        faqGlobalSupport: 'هل يدعم على مستوى العالم؟',
        faqFreeTrial: 'هل هناك نسخة تجريبية مجانية؟',
        faqDataCollection: 'ما البيانات التي يتم جمعها وكيف يتم استخدامها؟',
        faqAIBooks: 'كيف يمكن للكتب التي ينتجها الذكاء الاصطناعي تحسين إبداع طفلي وتعلمه؟',
        answerAppropriateContent: 'نعم، المحتوى على GWOW AI آمن ومناسب للأطفال. نحن نهتم جدًا لضمان أن تكون جميع القصص والرسوم التوضيحية التي ينتجها الذكاء الاصطناعي لدينا مناسبة للجمهور الصغير. تتيح منصتنا للآباء تخصيص القصص، مما يضمن أن المحتوى يلبي متطلباتهم وتفضيلاتهم الخاصة.',
        answerSupportedDevices: 'يدعم القاص التفاعلي بالذكاء الاصطناعي جميع الأجهزة الحديثة، بما في ذلك الهواتف الذكية والأجهزة اللوحية وأجهزة الكمبيوتر. تحتاج إلى اتصال بالإنترنت للوصول إلى الموقع، ولكن يمكنك أيضًا تنزيل القصص للاستخدام دون اتصال.',
        answerGlobalSupport: 'نعم، يدعم GWOW AI على مستوى العالم. يمكن للمستخدمين اختيار من بين 12 لغة مختلفة لإنشاء والاستمتاع بقصصهم، مما يجعلها متاحة لجمهور واسع في جميع أنحاء العالم.',
        answerFreeTrial: 'نعم، هناك نسخة تجريبية مجانية متاحة. يمكنك إنشاء حساب مستخدم مجاني للوصول إلى المكتبة العامة. لإنشاء قصص مخصصة، ستحتاج إلى الاشتراك في إحدى باقاتنا التي تبدأ من 4.99 دولار.',
        answerDataCollection: 'نقوم بجمع معلومات تسجيل الدخول الأساسية لتقديم خدماتنا وتحسينها. يتم الحفاظ على هذه المعلومات بشكل آمن، ولا تتم مشاركتها مع أي شخص ولا تباع.',
        answerAIBooks: 'يمكن للكتب التي ينتجها الذكاء الاصطناعي تحسين إبداع طفلك وتعلمه بشكل كبير من خلال تقديم قصص مخصصة وجذابة. يمكن تعديل هذه القصص لتناسب اهتمامات واحتياجات تعلم طفلك، مما يجعل القراءة تجربة تفاعلية وممتعة أكثر. بالإضافة إلى ذلك، يمكن للآباء تنزيل القصص بتنسيق PDF أو كتب تلوين لإشراك أطفالهم بشكل أكبر.',
        learnmore: "تعلم أكثر:",
        years: "سنوات", selectgender: "اختر الجنس", male: "ذكر", female: "أنثى", unspecified: "غير محدد", selectbooklength: "اختر طول الكتاب", pages: "صفحات", selectmorallesson: "اختر الدرس الأخلاقي", friendship: "صداقة", kindness: "لطف", courage: "شجاعة", honesty: "أمانة", empathy: "تعاطف", teamwork: "العمل الجماعي", acceptance: "قبول", responsibility: "مسؤولية", creativity: "إبداع", selectvisualstyle: "اختر النمط البصري", realistic: "واقعي", cartoon: "كارتون", painterly: "أسلوب الرسام", anime: "أنيمي",
        watercolor: "أكواريل",
        popart: "فن البوب", selectgenre: "اختر النوع", adventure: "مغامرة", mystery: "غموض", fantasy: "خيال", sciencefiction: "خيال علمي", historicalfiction: "خيال تاريخي", romance: "رومانسية", comedy: "كوميديا", addatwist: "أضف منعطف", selectmagicalelement: "اختر العنصر السحري", wizards: "السحرة", mermaids: "حوريات البحر", dinosaurs: "الديناصورات", robots: "الروبوتات", cars: "السيارات", pets: "الحيوانات الأليفة",
        dragons: "تنانين", aliens: "كائنات فضائية", fairies: "جنيات", superheroes: "أبطال خارقون", pirates: "قراصنة", cowboys: "رعاة البقر", secretworlds: "عوالم سرية", magicalcreatures: "مخلوقات سحرية", knights: "فرسان", princesses: "أميرات", otherspleasespecifybelow: "آخرون، يرجى التحديد في منطقة النص أدناه", selectculturalcontext: "اختر السياق الثقافي", ancientegypt: "مصر القديمة", medievaleurope: "أوروبا العصور الوسطى", feudaljapan: "اليابان الإقطاعية", victorianengland: "إنجلترا الفيكتورية", renaissanceitaly: "إيطاليا عصر النهضة", nativeamericanfolklore: "فلكلور الأمريكيين الأصليين", africanmythology: "الميثولوجيا الأفريقية", asianlegends: "الأساطير الآسيوية", wildwest: "الغرب المتوحش", selectenvironment: "اختر البيئة", forest: "غابة", cityscape: "منظر المدينة", countryside: "ريف", mountains: "جبال", beach: "شاطئ", underwaterkingdom: "مملكة تحت الماء", outerspace: "الفضاء الخارجي", enchantedforest: "غابة مسحورة", magicalcastle: "قلعة سحرية", desert: "صحراء", arctictundra: "التندرا القطبية", volcanicisland: "جزيرة بركانية", alienplanet: "كوكب فضائي",
        Superheroes: "أبطال خارقون",
        VariousTales: "قصص متنوعة",
        maincharacter: "الشخصية الرئيسية",

        animals: "الحيوانات",
        toys: "ألعاب",
        mythicalcreatures: "كائنات أسطورية",
        momentstogether: "لحظات معًا",
        selectone: "اختار واحدا",
        completeregistration: "أكمل التسجيل",
        subscriptionsuccessful: "رائع! تم تفعيل اشتراكك بنجاح، وحصلت على رصيد جديد من القصص! 🎉 لمعرفة رصيدك المحدث، يرجى تسجيل الخروج ثم تسجيل الدخول مرة أخرى. استعد للمزيد من المغامرات الممتعة!",
        paymentissue: "عذرًا! يبدو أن الدفع لم يكتمل. يرجى التحقق من تفاصيل بطاقتك وحاول مرة أخرى. إذا استمرت المشكلة، جرب وسيلة دفع أخرى حتى لا تفوتك المتعة!",
        underreview: "انتظر قليلًا! يتم مراجعة طلبك للتأكد من أن كل شيء على ما يرام. سنعلمك بمجرد أن يصبح جاهزًا. شكرًا على صبرك - المزيد من القصص قادمة قريبًا!",
        pendingpayment: "دفعتك في طريقها إلينا! بمجرد استلامها، سيكون رصيد القصص جاهزًا للاستخدام. إذا كنت تستخدم التحويل البنكي، تأكد من أن كل شيء تمت الموافقة عليه حتى تتمكن من العودة إلى المغامرة.",

        selectagegroup: "اختر الفئة العمرية",
        createstory: "إنشاء قصة",
        flipdeviceforbetterview: "اقلب الجهاز للحصول على رؤية أفضل",
        gobacktolibrary: "العودة إلى المكتبة",
        downloadbook: "تحميل الكتاب",
        downloadcoloringbook: "تحميل كتاب التلوين",
        forsupportemailusat: "للدعم، راسلنا عبر البريد الإلكتروني ",
        chooselanguage: "اختر اللغة",
        currentsubscriptiondetails: "تفاصيل الاشتراك الحالي",
        packagename: "اسم الحزمة",
        enddate: "تاريخ الانتهاء",
        remainingbalance: "الرصيد المتبقي",
        emailaddress: "عنوان البريد الإلكتروني",
        renewsubscription: "تجديد الاشتراك",
        confirrenewsubscriptiondate: "هل أنت متأكد أنك تريد تجديد اشتراكك الآن؟",
        ifyoudecidetoupgrade: "إذا قررت تغيير اشتراكك، فسيبدأ اشتراكك الجديد اليوم",
        yes: "نعم",
        no: "لا",
        cancelsubscription: "إلغاء الاشتراك",
        confirmsubcancelation: "هل أنت متأكد أنك تريد إلغاء اشتراكك؟ بمجرد الإلغاء، سيكون لديك وصول فقط إلى الميزات المجانية والمكتبات العامة",
        toupgradeyourpackage: "للترقية أو الترقية لأسفل لباقة أخرى، اختر واحدة من التالية",
        somethingwrongcontactsupport: "قد يكون هناك خطأ ما. يرجى الاتصال بفريق الدعم للحصول على المساعدة.",
        emailverification: "التحقق من البريد الإلكتروني",
        pleaselogin: "الرجاء تسجيل الدخول",
        downloadbookfeature: "تحميل كتب القصص — متاحة بصيغة أصلية أو صيغة تلوين، جاهزة للطباعة",
        child: "طفل",
        superhero: "بطل خارق",
        animal: "حيوان",
        wizard: "ساحر",
        fairy: "جنية",
        robot: "روبوت",
        fantasycharacters: "شخصيات خيالية",
        aboutus: "من نحن",
        frequentlyaskedquestion: "الأسئلة الشائعة",
        howitworks: "كيف يعمل",
  languagedisclaimer: "يرجى ملاحظة أن الشروط والأحكام معروضة باللغة الإنجليزية لأغراض مرجعية. بينما نسعى لجعل المحتوى الخاص بنا متاحًا بلغات متعددة، ستظل النسخة الإنجليزية من هذه الشروط والأحكام سارية في حالة وجود أي تناقضات أو سوء تفاهم أو نزاعات قانونية تنشأ عن الترجمات. من خلال الموافقة على هذه الشروط والأحكام، فإنك تقر بأنك تتحمل مسؤولية ضمان التفسير الدقيق للمحتوى باللغة التي تفضلها. في حالة وجود أي نزاعات قانونية أو غموض، ستكون النسخة الإنجليزية من الشروط والأحكام هي السائدة. إذا كانت لديك أي أسئلة أو تحتاج إلى توضيح، فنحن نحثك على طلب المشورة المهنية أو الاتصال بنا مباشرة.",
        availablecurrences: "دعم أكثر من 100 عملة محلية، بما في ذلك الدولار الأمريكي، اليورو، الجنيه الإسترليني، الين الياباني، الدولار الأسترالي والمزيد!",
        ifyouhaveacouponyoucanredeemit: "إذا كان لديك كوبون، يمكنك استردادها",
        ifthestoryreloads: "ملاحظة: إذا تمت إعادة تحميل النموذج، فهذا يعني أن الصور لم تجتز فحوصات الأمان لدينا. يرجى تعديل الاختيارات والمحاولة مرة أخرى",
        youcanalsosendagift: "يمكنك أيضًا شراء هدية لمرة واحدة وإرسالها لشخص ما",
    },
    pt: {
        home: "Página Inicial",
        pricing: "Preços",
        loginpage: "Entrar",
        signup: "Registrar",
        dashboard: "Painel",
        managesubscribtion: "Gerenciar Assinatura",
        upgradetopremium: "Atualizar para Premium",
        logout: "Sair",
        chooseacategory: "Escolha uma categoria",
        logingpage: "Página de Login",
        youarenotloggedin: "Você não está logado no momento, por favor faça login aqui",
        resetpassword: "Redefinir Senha",
        enteremailtoresetpassowrd: "Digite seu e-mail para redefinir sua senha",
        email: "E-mail",
        submit: "Enviar",
        enteryournewpassword: "Digite sua nova senha",
        newpassword: "Nova Senha",
        confirmnewpassword: "Confirmar Nova Senha",
        gotologin: "Ir para o Login",
        generallibrary: "Biblioteca Geral",
        yourlibrary: "Sua Biblioteca",
        createnewbook: "Criar Novo Livro",
        youarecurrentlynotloggedin: "Você não está logado no momento, por favor faça login aqui",
        nowpleaseconfirmwhichpackage: "Agora, por favor, confirme qual pacote você gostaria de comprar. Atualmente, você está usando este endereço de e-mail:",
        onceyouclickonthebuybutton: "Depois de clicar no botão 'Comprar Agora' abaixo, você será transferido para nosso fornecedor confiável FastSpring onde poderá finalizar os detalhes do pagamento. Certifique-se de usar o mesmo endereço de e-mail",
        chooseapackage: "Escolha um pacote:",
        selectyourpackage: "Selecione seu pacote",
        monthly: "Mensal",
        yearly: "Anual",
        buynow: "Compre Agora",
        thankyouforchoosingpackage: "Obrigado por selecionar seu pacote. Após finalizar os detalhes do seu pagamento, por favor, certifique-se de fazer login novamente para atualizar seu saldo. Você poderá criar seus próprios livros e acessar sua biblioteca privada.",
        youarecurrentlysubscribed: "Você está atualmente inscrito no",
        pleaseuseyourregisteredemail: "Pacote. Por favor, use seu endereço de e-mail registrado:",
        tomanageyouraccount: "para gerenciar sua conta. Você pode gerenciar sua assinatura",
        here: "aqui",
        noteifyoudecidetoupgradeyourpackage: "Nota: Se você decidir atualizar seu Pacote, o Pacote atualizado aparecerá na próxima vez que você fizer login.",
        yourorderiscompleted: "seu pedido foi concluído",
        turnyourchildreality: "Transforme a imaginação de seu filho em realidade com ",
      
      
        whychoose: "Por que escolher",
        ourplatformisdedicatedto: "Nossa plataforma é dedicada ao fortalecimento dos laços familiares através de experiências personalizadas de contação de histórias. Ao fornecer uma plataforma para contação de histórias compartilhada, facilitamos conexões mais profundas entre pais e filhos, permitindo que as famílias criem memórias duradouras juntas. Nossas histórias imersivas, adaptadas aos interesses e estágios de desenvolvimento das crianças, promovem o engajamento educacional ao mesmo tempo que reforçam conceitos e valores importantes",
        ignitecreativity: "Desperte a Criatividade",
        empowerchildrenimagination: "Empodere a imaginação e criatividade das crianças através de narrativas envolventes e ilustrações vibrantes. Nosso serviço incentiva o pensamento criativo e habilidades de resolução de problemas.",
        connectondeeperlevel: "Conecte-se em um Nível Mais Profundo",
        fosteradeeperbond: "Promova um vínculo mais profundo entre pais e filhos através de experiências personalizadas de contação de histórias. Nossa plataforma oferece elementos interativos para as famílias criarem memórias duradouras juntas.",
        funlearningadventures: "Aventuras de Aprendizagem Divertidas",
        leverageourservicestoprovide: "Aproveite nosso serviço para fornecer conteúdo educacional de forma divertida e envolvente. As crianças aprendem lições valiosas e habilidades através de contação de histórias imersivas adaptadas aos seus interesses e estágio de desenvolvimento.",
        discoverwhyfamilies: "Descubra por que as famílias confiam em nós",
        convenienceandsimplified: "Conveniência e Acesso Simplificado",
        saygoodbuytothehassle: "Diga adeus à dificuldade de procurar livros apropriados para a idade ou de ter ideias criativas. Nosso serviço oferece histórias personalizadas e ilustrações ao seu alcance.",
        customizedlearningexperience: "Experiências de Aprendizagem Personalizadas",
        tailorstorytelling: "Adapte experiências de contação de histórias às necessidades e interesses individuais de seu filho. Seja reforçando conceitos de aprendizagem específicos ou abordando temas socioemocionais, nosso serviço se adapta ao estágio de desenvolvimento e estilo de aprendizagem de seu filho.",
        parentalempowement: "Empoderamento Parental",
        takeanactiverole: "Assuma um papel ativo na educação e crescimento de seu filho com nossas ferramentas e recursos. Empodere a jornada de aprendizagem de seu filho e fortaleça seu relacionamento através de experiências colaborativas de contação de histórias.",
        timesavingsolution: "Solução para Economia de Tempo",
        savetimebyeleminating: "Economize tempo eliminando a necessidade de pesquisar e organizar materiais educacionais. Conte com nossa plataforma para fornecer conteúdo personalizado de alta qualidade adaptado às necessidades e interesses de seu filho.",
        experiencethemagic: "Experimente a magia da contação de histórias conosco hoje. Confira nosso",
        pricingpage: "Página de Preços",
        formoreinformationandstart: "para mais informações e começar a criar memórias significativas com sua família.",
        welcomebackpleaselogin: "Bem-vindo de volta! Por favor, faça login para continuar.",
        password: "Senha",
        donthaveanouaccount: "Não tem uma conta, você pode se inscrever ",
        forgotyourpassword: "Esqueceu sua senha, você pode redefini-la ",
        welcome: "Bem-vindo",
        letscreateyouraccount: "Vamos criar sua conta!",
        firstname: "Nome",
        lastname: "Sobrenome",
        termsandconditions: "Termos e Condições",
        free: "Grátis",
        freeuser: "Usuário Grátis",
        accesstogenerallibrary: "Acesso à Biblioteca Geral e livros em destaque mensais",
        earlyaccesstonewfeature: "Acesso antecipado a promoções — Seja o primeiro a descobrir novos recursos e ofertas",
        nocreditcardrequired: "Sem necessidade de cartão de crédito — Comece sem compromisso",
        signupnow: "Inscreva-se Agora",
        generalandprivatelibraries: "Acesso a Bibliotecas Gerais e Privadas — Explore novas histórias em destaque todos os meses",
        accesstonewaddedmonthlly: "Acesso mensal a novos livros em destaque — Descubra novas histórias todo mês",
        questbook: "5 Livros Personalizados — Com até 8 páginas, totalmente personalizados para seu filho",
        odysseybook: "12 Livros Personalizados — Com até 8 páginas, totalmente personalizados para seu filho",
        enchantedbook: "29 Livros Personalizados — Com até 8 páginas, totalmente personalizados para seu filho",
        questbookannually: "60 Livros Personalizados — Com até 8 páginas, totalmente personalizados para seu filho",
        odysseybookannually: "145 Livros Personalizados — Com até 8 páginas, totalmente personalizados para seu filho",
        enchantedbookannually: "350 Livros Personalizados — Com até 8 páginas, totalmente personalizados para seu filho",
         forallpaidpackages: "Para todos os pacotes pagos, na finalização da compra você pode optar por ser faturado anualmente e ganhar ",
        onemonthfree: "um mês grátis!",
        title: "Título",
        creationdate: "Data de Criação",
        action: "Ação",
        view: "Visualizar",
        pleasebarewithus: "Por favor, tenha paciência conosco, coisas boas levam tempo!",
        loading: "Carregando",
        booksremaining: "Livros restantes:",
        gender: "Gênero:",
        booklength: "Tamanho do Livro:",
        agegroup: "Grupo Etário:",
        lessonlearned: "Lições Aprendidas",
        morallesson: "Lição Moral",
        themes: "Temas",
        visualstyle: "Estilo Visual:",
        Whatillustrationstyle: "Qual estilo de ilustração você acha atraente?",
        characterarechetype: "Arquétipos de Personagens: ",
        genre: "Gênero:",
        plotelements: " Elementos da Trama:",
        magicalelements: "Elementos Mágicos:",
        cluturalcontext: "Contexto Cultural:",
        emotionaltone: "Tom Emocional:",
        environment: "Ambiente:",
        specificpreferences: "Preferências Específicas? Personalize ainda mais sua história:",
        storycreatedsuccesfully: "História criada e salva com sucesso!",
        failedtocreatestory: "Falha ao criar e salvar a história!",
        toviewyourlibrary: "Para visualizar sua biblioteca, clique",
        unfortunatellyyouhaveconsumed: "Infelizmente, você consumiu todos os seus livros do mês. Se desejar atualizar seu pacote, visite ",
        language: "Idioma:",
        english: "Inglês",
        arabic: "Árabe",
        bulgarian: "Búlgaro",
        chinese: "Chinês",
        french: "Francês",
        german: "Alemão",
        hindi: "Hindi",
        korean: "Coreano",
        portuguese: "Português",
        russian: "Russo",
        spanish: "Espanhol",
        dutch: "Holandês",
        safeContent: 'O conteúdo é seguro e apropriado para crianças?',
        safeContentAnswer: 'Sim, o conteúdo no GWOW AI é seguro e apropriado para crianças. Cuidamos para garantir que todas as histórias e ilustrações geradas pela nossa IA sejam adequadas para o público jovem. Nossa plataforma permite que os pais personalizem histórias, garantindo que o conteúdo atenda às suas necessidades e preferências específicas.',
        supportedDevices: 'Quais dispositivos são compatíveis com o AI Interactive Storyteller?',
        supportedDevicesAnswer: 'O AI Interactive Storyteller é compatível com todos os dispositivos modernos, incluindo smartphones, tablets e computadores. É necessário uma conexão com a internet para acessar o site, mas você também pode baixar histórias localmente para acesso offline.',
        globalSupport: 'É suportado globalmente?',
        globalSupportAnswer: 'Sim, o GWOW AI é suportado globalmente. Os usuários podem escolher entre 12 idiomas diferentes para criar e aproveitar suas histórias, tornando-o acessível a um público amplo ao redor do mundo.',
        freeTrial: 'Há uma avaliação gratuita disponível?',
        freeTrialAnswer: 'Sim, há uma avaliação gratuita disponível. Você pode criar uma conta de usuário gratuita para acessar a biblioteca geral. Para criar histórias personalizadas, você precisará assinar um de nossos pacotes a partir de $7.99.',
        dataCollection: 'Quais dados são coletados e como são usados?',
        dataCollectionAnswer: 'Coletamos informações básicas de login para fornecer e melhorar nossos serviços. Essas informações são mantidas em segurança, não são compartilhadas com ninguém e não serão vendidas.',
        aiBooksCreativity: 'Como os livros gerados por IA podem melhorar a criatividade e o aprendizado do meu filho?',
        aiBooksCreativityAnswer: 'Os livros gerados por IA podem melhorar significativamente a criatividade e o aprendizado do seu filho ao fornecer histórias personalizadas e envolventes. Essas histórias podem ser ajustadas para os interesses e necessidades de aprendizado do seu filho, tornando a leitura uma experiência mais interativa e agradável. Além disso, os pais podem baixar histórias em PDF ou livros de colorir para envolver ainda mais seus filhos.',

        faqAppropriateContent: 'O conteúdo é seguro e apropriado para crianças?',
        faqSupportedDevices: 'Quais dispositivos são suportados pelo Contador de Histórias Interativo com IA?',
        faqGlobalSupport: 'É suportado globalmente?',
        faqFreeTrial: 'Há um teste gratuito disponível?',
        faqDataCollection: 'Quais dados são coletados e como são usados?',
        faqAIBooks: 'Como os livros gerados por IA podem melhorar a criatividade e o aprendizado do meu filho?',
        answerAppropriateContent: 'Sim, o conteúdo no GWOW AI é seguro e apropriado para crianças. Tomamos muito cuidado para garantir que todas as histórias e ilustrações geradas por nossa IA sejam adequadas para o público jovem. Nossa plataforma permite que os pais personalizem as histórias, garantindo que o conteúdo atenda aos seus requisitos e preferências específicos.',
        answerSupportedDevices: 'O Contador de Histórias Interativo com IA suporta todos os dispositivos modernos, incluindo smartphones, tablets e computadores. Você precisa de uma conexão com a internet para acessar o site, mas também pode baixar histórias localmente para acesso offline.',
        answerGlobalSupport: 'Sim, o GWOW AI é suportado globalmente. Os usuários podem escolher entre 12 idiomas diferentes para criar e desfrutar de suas histórias, tornando-o acessível a um público amplo ao redor do mundo.',
        answerFreeTrial: 'Sim, há um teste gratuito disponível. Você pode criar uma conta de usuário gratuita para acessar a biblioteca geral. Para criar histórias personalizadas, você precisará assinar um de nossos pacotes a partir de $4.99.',
        answerDataCollection: 'Coletamos informações básicas de login para fornecer e melhorar nossos serviços. Essas informações são mantidas seguras, não são compartilhadas com ninguém e não serão vendidas.',
        answerAIBooks: 'Os livros gerados por IA podem melhorar significativamente a criatividade e o aprendizado do seu filho, fornecendo histórias personalizadas e envolventes. Essas histórias podem ser adaptadas aos interesses e necessidades de aprendizado do seu filho, tornando a leitura uma experiência mais interativa e agradável. Além disso, os pais podem baixar histórias em formato PDF ou livros para colorir para envolver ainda mais seus filhos.',
        learnmore: "Saiba mais:",
        years: "anos", selectgender: "Selecionar gênero", male: "Masculino", female: "Feminino", unspecified: "Não especificado", selectbooklength: "Selecionar comprimento do livro", pages: "páginas", selectmorallesson: "Selecionar lição moral", friendship: "Amizade", kindness: "Bondade", courage: "Coragem", honesty: "Honestidade", empathy: "Empatia", teamwork: "Trabalho em equipe", acceptance: "Aceitação", responsibility: "Responsabilidade", creativity: "Criatividade", selectvisualstyle: "Selecionar estilo visual", realistic: "Realista", cartoon: "Desenho animado", painterly: "Pintoresco", anime: "Anime", fantasy: "Fantasia",
        watercolor: "Aquarela",
        popart: "Arte Pop", selectgenre: "Selecionar gênero", adventure: "Aventura", mystery: "Mistério", sciencefiction: "Ficção científica", historicalfiction: "Ficção histórica", romance: "Romance", comedy: "Comédia", addatwist: "Adicionar uma reviravolta", selectmagicalelement: "Selecionar elemento mágico", wizards: "Magos", mermaids: "Sereias", dinosaurs: "Dinossauros", robots: "Robôs", cars: "Carros", pets: "Animais de estimação",
        dragons: "Dragões", aliens: "Alienígenas", fairies: "Fadas", superheroes: "Super-heróis", pirates: "Piratas", cowboys: "Cowboys", secretworlds: "Mundos Secretos", magicalcreatures: "Criaturas Mágicas", knights: "Cavaleiros", princesses: "Princesas", otherspleasespecifybelow: "Outros, por favor especifique na área de texto abaixo", selectculturalcontext: "Selecione o Contexto Cultural", ancientegypt: "Egito Antigo", medievaleurope: "Europa Medieval", feudaljapan: "Japão Feudal", victorianengland: "Inglaterra Vitoriana", renaissanceitaly: "Renascença Italiana", nativeamericanfolklore: "Folclore Nativo Americano", africanmythology: "Mitologia Africana", asianlegends: "Lendas Asiáticas", wildwest: "Velho Oeste", selectenvironment: "Selecione o Ambiente", forest: "Floresta", cityscape: "Paisagem Urbana", countryside: "Zona Rural", mountains: "Montanhas", beach: "Praia", underwaterkingdom: "Reino Submarino", outerspace: "Espaço Sideral", enchantedforest: "Floresta Encantada", magicalcastle: "Castelo Mágico", desert: "Deserto", arctictundra: "Tundra Ártica", volcanicisland: "Ilha Vulcânica", alienplanet: "Planeta Alienígena",
        Superheroes: "Super-heróis",
        VariousTales: "Contos Vários",
        maincharacter: "Personagem Principal",

        animals: "Animais",
        toys: "Brinquedos",
        mythicalcreatures: "Criaturas Míticas",
        momentstogether: "Momentos Juntos",
        selectone: "Escolha Um",
        completeregistration: "Complete o registro",
        subscriptionsuccessful: "Yay! Sua assinatura foi bem-sucedida e você ganhou créditos de histórias novos! 🎉 Para ver seu saldo atualizado, faça logout e faça login novamente. Prepare-se para mais aventuras divertidas!",
        paymentissue: "Ops! Parece que seu pagamento não foi concluído. Verifique os detalhes do seu cartão ou PayPal e tente novamente. Se continuar acontecendo, tente um método de pagamento diferente para não perder a diversão!",
        underreview: "Aguarde um pouco! Seu pedido está sendo revisado para garantir que tudo esteja seguro. Vamos avisá-lo assim que estiver pronto. Obrigado pela paciência—mais histórias estão a caminho!",
        pendingpayment: "Seu pagamento está a caminho! Assim que o recebermos, seus créditos de histórias estarão prontos para uso. Se estiver usando PayPal ou transferência bancária, apenas certifique-se de que tudo esteja aprovado para que você possa voltar à aventura.",

        selectagegroup: "Selecionar faixa etária",
        createstory: "Criar história",
        flipdeviceforbetterview: "Vire o dispositivo para melhor visualização",
        gobacktolibrary: "Voltar para a biblioteca",
        downloadbook: "Baixar livro",
        downloadcoloringbook: "Baixar livro de colorir",
        forsupportemailusat: "Para suporte, envie um e-mail para nós em ",
        chooselanguage: "Escolher idioma",
        currentsubscriptiondetails: "Detalhes da assinatura atual",
        packagename: "Nome do pacote",
        enddate: "Data de término",
        remainingbalance: "Saldo restante",
        emailaddress: "Endereço de e-mail",
        renewsubscription: "Renovar assinatura",
        confirrenewsubscriptiondate: "Tem certeza de que deseja renovar sua assinatura agora?",
        ifyoudecidetoupgrade: "Se você decidir mudar sua assinatura, sua nova assinatura começará hoje",
        yes: "Sim",
        no: "Não",
        cancelsubscription: "Cancelar assinatura",
        confirmsubcancelation: "Tem certeza de que deseja cancelar sua assinatura? Após o cancelamento, você terá acesso apenas aos recursos gratuitos e às bibliotecas gerais",
        toupgradeyourpackage: "Para atualizar ou rebaixar seu pacote, selecione uma das seguintes opções",
        somethingwrongcontactsupport: "Algo pode estar errado. Entre em contato com a equipe de suporte para assistência.",
        emailverification: "Verificação de e-mail",
        pleaselogin: "Por favor, faça login",
        downloadbookfeature: "Baixar Livros Infantis — Disponível na versão original ou de colorir, pronto para imprimir",
        child: "Criança",
        superhero: "Super-herói",
        animal: "Animal",
        wizard: "Mago",
        fairy: "Fada",
        robot: "Robô",
        fantasycharacters: "Personagens Fantásticos",
        aboutus: "Sobre Nós",
        frequentlyaskedquestion: "Perguntas Frequentes",
        howitworks: "Como Funciona",
 languagedisclaimer: "Observe que os Termos e Condições são exibidos em inglês para fins de referência. Embora nos esforcemos para disponibilizar nosso conteúdo em vários idiomas, a versão em inglês destes Termos e Condições sempre prevalecerá em caso de discrepâncias, mal-entendidos ou disputas legais decorrentes de traduções. Ao concordar com estes Termos e Condições, você reconhece que é sua responsabilidade garantir a interpretação precisa do conteúdo em seu idioma preferido. Em caso de quaisquer disputas legais ou ambiguidades, a versão em inglês dos Termos e Condições terá precedência. Se você tiver alguma dúvida ou precisar de esclarecimentos, recomendamos que busque aconselhamento profissional ou entre em contato conosco diretamente.",
        availablecurrences: "Apoio a mais de 100 moedas locais, incluindo USD, EUR, GBP, JPY, AUD e mais!",
        ifyouhaveacouponyoucanredeemit: "Se você tem um cupom, pode resgatá-lo",
        ifthestoryreloads: "Nota: Se o formulário recarregar, significa que as imagens não passaram nos nossos verificações de segurança. Por favor, ajuste sua entrada e tente novamente",
        youcanalsosendagift: "Você também pode comprar um presente único e enviá-lo para alguém",
    },
    fr: {
        home: "Accueil",
        pricing: "Tarification",
        loginpage: "Connexion",
        signup: "Inscription",
        dashboard: "Tableau de bord",
        managesubscribtion: "Gérer l'abonnement",
        upgradetopremium: "Passer à Premium",
        logout: "Déconnexion",
        chooseacategory: "Choisissez une catégorie",
        logingpage: "Page de connexion",
        youarenotloggedin: "Vous n'êtes pas actuellement connecté, veuillez vous connecter ici",
        resetpassword: "Réinitialiser le mot de passe",
        enteremailtoresetpassowrd: "Entrez votre email pour réinitialiser votre mot de passe",
        email: "Email",
        submit: "Soumettre",
        enteryournewpassword: "Entrez votre nouveau mot de passe",
        newpassword: "Nouveau mot de passe",
        confirmnewpassword: "Confirmer le nouveau mot de passe",
        gotologin: "Aller à la connexion",
        generallibrary: "Bibliothèque générale",
        yourlibrary: "Votre bibliothèque",
        createnewbook: "Créer un nouveau livre",
        youarecurrentlynotloggedin: "Vous n'êtes actuellement pas connecté, veuillez vous connecter ici",
        nowpleaseconfirmwhichpackage: "Veuillez maintenant confirmer quel package vous souhaitez acheter. Actuellement, vous utilisez cette adresse e-mail :",
        onceyouclickonthebuybutton: "Une fois que vous cliquez sur le bouton 'Acheter maintenant' ci-dessous, vous serez transféré à notre fournisseur de confiance FastSpring où vous pourrez finaliser les détails de paiement. Veuillez vous assurer d'utiliser la même adresse e-mail",
        chooseapackage: "Choisissez un package :",
        selectyourpackage: "Sélectionnez votre package",
        monthly: "Mensuel",
        yearly: "Annuel",
        buynow: "Acheter maintenant",
        thankyouforchoosingpackage: "Merci d'avoir sélectionné votre forfait. Après avoir finalisé les détails de votre paiement, veuillez vous reconnecter pour mettre à jour votre solde. Vous pourrez créer vos propres livres et accéder à votre bibliothèque privée.",
        youarecurrentlysubscribed: "Vous êtes actuellement abonné à",
        pleaseuseyourregisteredemail: "Package. Veuillez utiliser votre adresse e-mail enregistrée :",
        tomanageyouraccount: "pour gérer votre compte. Vous pouvez gérer votre abonnement",
        here: "ici",
        noteifyoudecidetoupgradeyourpackage: "Remarque : Si vous décidez de mettre à niveau votre package, le package mis à niveau apparaîtra la prochaine fois que vous vous connecterez.",
        yourorderiscompleted: "votre commande est terminée",
        turnyourchildreality: "Transformez l'imagination de votre enfant en réalité avec",
      
      
        whychoose: "Pourquoi choisir",
        ourplatformisdedicatedto: "Notre plateforme est dédiée au renforcement des liens familiaux grâce à des expériences de narration personnalisées. En fournissant une plateforme pour la narration partagée, nous facilitons des connexions plus profondes entre parents et enfants, permettant aux familles de créer des souvenirs durables ensemble. Nos histoires immersives, adaptées aux intérêts et aux étapes de développement des enfants, favorisent l'engagement éducatif tout en renforçant des concepts et des valeurs importants",
        ignitecreativity: "Stimuler la créativité",
        empowerchildrenimagination: "Donner vie à l'imagination et à la créativité des enfants à travers des récits captivants et des illustrations vibrantes. Notre service encourage la pensée créative et les compétences de résolution de problèmes.",
        connectondeeperlevel: "Établir une connexion plus profonde",
        fosteradeeperbond: "Favoriser un lien plus profond entre parents et enfants grâce à des expériences de narration personnalisées. Notre plateforme offre des éléments interactifs permettant aux familles de créer des souvenirs durables ensemble.",
        funlearningadventures: "Aventures d'apprentissage amusantes",
        leverageourservicestoprovide: "Utiliser notre service pour fournir du contenu éducatif de manière amusante et engageante. Les enfants apprennent des leçons et des compétences précieuses grâce à des récits immersifs adaptés à leurs intérêts et à leur étape de développement.",
        discoverwhyfamilies: "Découvrez pourquoi les familles nous font confiance",
        convenienceandsimplified: "Confort et accès simplifié",
        saygoodbuytothehassle: "Dites adieu à la recherche de livres appropriés pour l'âge ou à la lutte pour trouver des idées créatives. Notre service propose des histoires et des illustrations personnalisées à portée de main.",
        customizedlearningexperience: "Expériences d'apprentissage personnalisées",
        tailorstorytelling: "Adapter les expériences de narration aux besoins et aux intérêts individuels de votre enfant. Que ce soit pour renforcer des concepts d'apprentissage spécifiques ou aborder des thèmes socio-émotionnels, notre service s'adapte à l'étape de développement et au style d'apprentissage de votre enfant.",
        parentalempowement: "Autonomisation des parents",
        takeanactiverole: "Jouer un rôle actif dans l'éducation et la croissance de votre enfant avec nos outils et ressources. Dynamisez le parcours d'apprentissage de votre enfant et renforcez votre relation grâce à des expériences collaboratives de narration.",
        timesavingsolution: "Solution de gain de temps",
        savetimebyeleminating: "Économisez du temps en éliminant la recherche et la sélection de matériel éducatif. Comptez sur notre plateforme pour fournir du contenu personnalisé de haute qualité adapté aux besoins et aux intérêts de votre enfant.",
        experiencethemagic: "Découvrez la magie de la narration avec nous aujourd'hui. Consultez notre",
        pricingpage: "Page de tarification",
        formoreinformationandstart: "pour plus d'informations et commencer à créer des souvenirs significatifs avec votre famille.",
        welcomebackpleaselogin: "Bienvenue de retour ! Veuillez vous connecter pour continuer.",
        password: "Mot de passe",
        donthaveanouaccount: "Vous n'avez pas de compte, vous pouvez vous inscrire ",
        forgotyourpassword: "Mot de passe oublié, vous pouvez le réinitialiser ",
        welcome: "Bienvenue",
        letscreateyouraccount: "Créons votre compte !",
        firstname: "Prénom",
        lastname: "Nom de famille",
        termsandconditions: "Conditions générales",
        free: "Gratuit",
        freeuser: "Utilisateur Gratuit",
        accesstogenerallibrary: "Accès à la Bibliothèque Générale et aux livres en vedette du mois",
        earlyaccesstonewfeature: "Accès anticipé aux promotions — Soyez le premier à découvrir les nouvelles fonctionnalités et offres",
        nocreditcardrequired: "Pas de carte de crédit requise — Commencez sans engagement",
        signupnow: "Inscrivez-vous Maintenant",
        generalandprivatelibraries: "Accès aux Bibliothèques Générales et Privées — Découvrez de nouvelles histoires chaque mois",
        accesstonewaddedmonthlly: "Accès mensuel aux nouveaux livres en vedette — Découvrez des histoires fraîches chaque mois",
        questbook: "5 Livres Personnalisés — Jusqu’à 8 pages, entièrement personnalisés pour votre enfant",
        odysseybook: "12 Livres Personnalisés — Jusqu’à 8 pages, entièrement personnalisés pour votre enfant",
        enchantedbook: "29 Livres Personnalisés — Jusqu’à 8 pages, entièrement personnalisés pour votre enfant",
        questbookannually: "60 Livres Personnalisés — Jusqu’à 8 pages, entièrement personnalisés pour votre enfant",
        odysseybookannually: "145 Livres Personnalisés — Jusqu’à 8 pages, entièrement personnalisés pour votre enfant",
        enchantedbookannually: "350 Livres Personnalisés — Jusqu’à 8 pages, entièrement personnalisés pour votre enfant",
        forallpaidpackages: "Pour tous les packages payants, lors du paiement vous pouvez choisir d'être facturé annuellement et obtenir ",
        onemonthfree: "un mois gratuit !",
        title: "Titre",
        creationdate: "Date de création",
        action: "Action",
        view: "Voir",
        pleasebarewithus: "Veuillez nous excuser, les bonnes choses prennent du temps !",
        loading: "Chargement",
        booksremaining: "Livres restants :",
        gender: "Genre :",
        booklength: "Longueur du livre :",
        agegroup: "Groupe d'âge :",
        lessonlearned: "Leçons apprises",
        morallesson: "Leçon Morale",
        themes: "Thèmes",
        visualstyle: "Style visuel :",
        Whatillustrationstyle: "Quel style d'illustration trouvez-vous attrayant ?",
        characterarechetype: "Archétypes de personnage :",
        genre: "Genre :",
        plotelements: "Éléments de l'intrigue :",
        magicalelements: "Éléments magiques :",
        cluturalcontext: "Contexte culturel :",
        emotionaltone: "Ton émotionnel :",
        environment: "Environnement :",
        specificpreferences: "Préférences spécifiques ? Personnalisez encore plus votre histoire :",
        storycreatedsuccesfully: "Histoire créée et sauvegardée avec succès !",
        failedtocreatestory: "Échec de la création et de la sauvegarde de l'histoire !",
        toviewyourlibrary: "Pour voir votre bibliothèque, cliquez",
        unfortunatellyyouhaveconsumed: "Malheureusement, vous avez utilisé tous vos livres pour le mois. Si vous souhaitez mettre à niveau votre package, veuillez visiter le ",
        language: "Langue :",
        english: "Anglais",
        arabic: "Arabe",
        bulgarian: "Bulgare",
        chinese: "Chinois",
        french: "Français",
        german: "Allemand",
        hindi: "Hindi",
        korean: "Coréen",
        portuguese: "Portugais",
        russian: "Russe",
        spanish: "Espagnol",
        dutch: "Néerlandais",
        safeContent: 'Le contenu est-il sûr et approprié pour les enfants?',
        safeContentAnswer: 'Oui, le contenu sur GWOW AI est sûr et approprié pour les enfants. Nous prenons grand soin de veiller à ce que toutes les histoires et illustrations générées par notre IA soient adaptées aux jeunes publics. Notre plateforme permet aux parents de personnaliser les histoires, garantissant que le contenu répond à leurs exigences et préférences spécifiques.',
        supportedDevices: 'Quels dispositifs sont compatibles avec l’AI Interactive Storyteller?',
        supportedDevicesAnswer: 'L’AI Interactive Storyteller est compatible avec tous les appareils modernes, y compris les smartphones, les tablettes et les ordinateurs. Vous avez besoin d’une connexion Internet pour accéder au site Web, mais vous pouvez également télécharger des histoires localement pour un accès hors ligne.',
        globalSupport: 'Est-il pris en charge à l’échelle mondiale?',
        globalSupportAnswer: 'Oui, GWOW AI est pris en charge à l’échelle mondiale. Les utilisateurs peuvent choisir parmi 12 langues différentes pour créer et profiter de leurs histoires, ce qui le rend accessible à un large public à travers le monde.',
        freeTrial: 'Y a-t-il un essai gratuit disponible?',
        freeTrialAnswer: 'Oui, un essai gratuit est disponible. Vous pouvez créer un compte utilisateur gratuit pour accéder à la bibliothèque générale. Pour créer des histoires personnalisées, vous devrez vous abonner à l’un de nos forfaits à partir de 7,99 $.',
        dataCollection: 'Quelles données sont collectées et comment sont-elles utilisées?',
        dataCollectionAnswer: 'Nous collectons des informations de connexion de base pour fournir et améliorer nos services. Ces informations sont conservées en sécurité, ne sont partagées avec personne et ne seront pas vendues.',
        aiBooksCreativity: 'Comment les livres générés par IA peuvent-ils améliorer la créativité et l’apprentissage de mon enfant?',
        aiBooksCreativityAnswer: 'Les livres générés par IA peuvent améliorer considérablement la créativité et l’apprentissage de votre enfant en fournissant des histoires personnalisées et engageantes. Ces histoires peuvent être adaptées aux intérêts et aux besoins d’apprentissage de votre enfant, rendant la lecture plus interactive et agréable. De plus, les parents peuvent télécharger des histoires au format PDF ou des livres à colorier pour encore mieux engager leurs enfants.',

        faqAppropriateContent: 'Le contenu est-il sûr et adapté à l\'âge des enfants?',
        faqSupportedDevices: 'Quels appareils sont pris en charge par le conteur interactif avec IA?',
        faqGlobalSupport: 'Est-ce pris en charge à l\'échelle mondiale?',
        faqFreeTrial: 'Y a-t-il une version d\'essai gratuite disponible?',
        faqDataCollection: 'Quelles données sont collectées et comment sont-elles utilisées?',
        faqAIBooks: 'Comment les livres générés par l\'IA peuvent-ils améliorer la créativité et l\'apprentissage de mon enfant?',
        answerAppropriateContent: 'Oui, le contenu de GWOW AI est sûr et adapté aux enfants. Nous prenons grand soin de nous assurer que toutes les histoires et illustrations générées par notre IA sont adaptées aux jeunes publics. Notre plateforme permet aux parents de personnaliser les histoires, garantissant que le contenu répond à leurs exigences et préférences spécifiques.',
        answerSupportedDevices: 'Le conteur interactif avec IA prend en charge tous les appareils modernes, y compris les smartphones, les tablettes et les ordinateurs. Vous avez besoin d\'une connexion internet pour accéder au site, mais vous pouvez également télécharger les histoires localement pour un accès hors ligne.',
        answerGlobalSupport: 'Oui, GWOW AI est pris en charge à l\'échelle mondiale. Les utilisateurs peuvent choisir parmi 12 langues différentes pour créer et apprécier leurs histoires, ce qui le rend accessible à un large public dans le monde entier.',
        answerFreeTrial: 'Oui, une version d\'essai gratuite est disponible. Vous pouvez créer un compte utilisateur gratuit pour accéder à la bibliothèque générale. Pour créer des histoires personnalisées, vous devrez vous abonner à l\'un de nos forfaits à partir de 4,99 $.',
        answerDataCollection: 'Nous collectons des informations de connexion de base pour fournir et améliorer nos services. Ces informations sont sécurisées, ne sont partagées avec personne et ne seront pas vendues.',
        answerAIBooks: 'Les livres générés par l\'IA peuvent améliorer considérablement la créativité et l\'apprentissage de votre enfant en fournissant des histoires personnalisées et engageantes. Ces histoires peuvent être adaptées aux intérêts et aux besoins d\'apprentissage de votre enfant, rendant la lecture plus interactive et agréable. De plus, les parents peuvent télécharger des histoires sous forme de PDF ou de livres à colorier pour impliquer davantage leurs enfants.',
        learnmore: "En savoir plus :",
        years: "années", selectgender: "Sélectionner le genre", male: "Homme", female: "Femme", unspecified: "Non spécifié", selectbooklength: "Sélectionner la longueur du livre", pages: "pages", selectmorallesson: "Sélectionner la leçon morale", friendship: "Amitié", kindness: "Gentillesse", courage: "Courage", honesty: "Honnêteté", empathy: "Empathie", teamwork: "Travail d'équipe", acceptance: "Acceptation", responsibility: "Responsabilité", creativity: "Créativité", selectvisualstyle: "Sélectionner le style visuel", realistic: "Réaliste", cartoon: "Dessin animé", painterly: "Pictural", anime: "Anime", fantasy: "Fantasy",
        watercolor: "Aquarelle",
        popart: "Art Pop", selectgenre: "Sélectionner le genre", adventure: "Aventure", mystery: "Mystère",  sciencefiction: "Science-fiction", historicalfiction: "Fiction historique", romance: "Romance", comedy: "Comédie", addatwist: "Ajouter un rebondissement", selectmagicalelement: "Sélectionner l'élément magique", wizards: "Sorciers", mermaids: "Sirènes", dinosaurs: "Dinosaures", robots: "Robots", cars: "Voitures", pets: "Animaux de compagnie",
        dragons: "Dragons", aliens: "Extraterrestres", fairies: "Fées", superheroes: "Super-héros", pirates: "Pirates", cowboys: "Cow-boys", secretworlds: "Mondes Secrets", magicalcreatures: "Créatures Magiques", knights: "Chevaliers", princesses: "Princesses", otherspleasespecifybelow: "Autres, veuillez spécifier dans la zone de texte ci-dessous", selectculturalcontext: "Sélectionnez le Contexte Culturel", ancientegypt: "Égypte Ancienne", medievaleurope: "Europe Médiévale", feudaljapan: "Japon Féodal", victorianengland: "Angleterre Victorienne", renaissanceitaly: "Italie de la Renaissance", nativeamericanfolklore: "Folklore Amérindien", africanmythology: "Mythologie Africaine", asianlegends: "Légendes Asiatiques", wildwest: "Far West", selectenvironment: "Sélectionnez l'Environnement", forest: "Forêt", cityscape: "Paysage Urbain", countryside: "Campagne", mountains: "Montagnes", beach: "Plage", underwaterkingdom: "Royaume Sous-Marin", outerspace: "Espace", enchantedforest: "Forêt Enchantée", magicalcastle: "Château Magique", desert: "Désert", arctictundra: "Toundra Arctique", volcanicisland: "Île Volcanique", alienplanet: "Planète Alien",
        Superheroes: "Super-héros",
        VariousTales: "Contes Variés",
        maincharacter: "Personnage Principal",

        animals: "Animaux",
        toys: "Jouets",
        mythicalcreatures: "Créatures Mythiques",
        momentstogether: "Moments Ensemble",
        selectone: "Choisissez-en Un",
        completeregistration: "Compléter l'inscription",

        subscriptionsuccessful: "Yay ! Votre abonnement a réussi et vous avez un nouveau réservoir de crédits d'histoires ! 🎉 Pour voir votre solde mis à jour, veuillez vous déconnecter et vous reconnecter. Préparez-vous pour de nouvelles aventures amusantes !",
        paymentissue: "Oups ! Il semble que votre paiement n'ait pas abouti. Veuillez vérifier vos informations de carte ou PayPal et réessayez. Si cela continue, essayez un autre mode de paiement pour ne pas manquer de plaisir !",
        underreview: "Patientez un instant ! Votre commande est en cours de révision pour nous assurer que tout est en sécurité. Nous vous informerons dès que ce sera prêt. Merci pour votre patience—plus d'histoires arrivent bientôt !",
        pendingpayment: "Votre paiement est en route ! Dès que nous le recevrons, vos crédits d'histoires seront prêts à l'emploi. Si vous utilisez PayPal ou un virement bancaire, assurez-vous que tout est approuvé afin que vous puissiez retourner à l'aventure.",

        selectagegroup: "Sélectionner le groupe d'âge",
        createstory: "Créer une histoire",
        flipdeviceforbetterview: "Retournez l'appareil pour une meilleure vue",
        gobacktolibrary: "Retour à la bibliothèque",
        downloadbook: "Télécharger le livre",
        downloadcoloringbook: "Télécharger le livre de coloriage",
        forsupportemailusat: "Pour obtenir de l'aide, envoyez-nous un e-mail à ",
        chooselanguage: "Choisir la langue",
        currentsubscriptiondetails: "Détails de l'abonnement actuel",
        packagename: "Nom du forfait",
        enddate: "Date de fin",
        remainingbalance: "Solde restant",
        emailaddress: "Adresse e-mail",
        renewsubscription: "Renouveler l'abonnement",
        confirrenewsubscriptiondate: "Êtes-vous sûr de vouloir renouveler votre abonnement maintenant ?",
        ifyoudecidetoupgrade: "Si vous décidez de changer votre abonnement, votre nouvel abonnement commencera aujourd'hui",
        yes: "Oui",
        no: "Non",
        cancelsubscription: "Annuler l'abonnement",
        confirmsubcancelation: "Êtes-vous sûr de vouloir annuler votre abonnement? Une fois annulé, vous n'aurez accès qu'aux fonctionnalités gratuites et aux bibliothèques générales",
        toupgradeyourpackage: "Pour mettre à niveau ou rétrograder votre forfait, sélectionnez l'une des options suivantes",
        somethingwrongcontactsupport: "Il pourrait y avoir un problème. Veuillez contacter l'équipe de support pour obtenir de l'aide.",
        emailverification: "Vérification de l'e-mail",
        pleaselogin: "Veuillez vous connecter",
        downloadbookfeature: "Télécharger des Livres d'Histoire — Disponible en version originale ou de coloriage, prêt à imprimer",
        child: "Enfant",
        superhero: "Super-héros",
        animal: "Animal",
        wizard: "Sorcier",
        fairy: "Fée",
        robot: "Robot",
        fantasycharacters: "Personnages Fantastiques",
        aboutus: "À Propos de Nous",
        frequentlyaskedquestion: "Questions Fréquemment Posées",
        howitworks: "Comment ça fonctionne",
 languagedisclaimer: "Veuillez noter que les Conditions Générales sont affichées en anglais à des fins de référence. Bien que nous nous efforcions de rendre notre contenu disponible dans plusieurs langues, la version anglaise de ces Conditions Générales prévaudra toujours en cas de divergences, de malentendus ou de litiges juridiques découlant des traductions. En acceptant ces Conditions Générales, vous reconnaissez qu'il vous incombe de veiller à une interprétation précise du contenu dans la langue de votre choix. En cas de litige juridique ou d'ambiguïté, la version anglaise des Conditions Générales prévaudra. Si vous avez des questions ou avez besoin de précisions, nous vous encourageons à demander conseil à un professionnel ou à nous contacter directement.",
        availablecurrences: "Soutien de plus de 100 devises locales, y compris USD, EUR, GBP, JPY, AUD et plus encore!",
        ifyouhaveacouponyoucanredeemit: "Si vous avez un coupon, vous pouvez le réclamer",
        ifthestoryreloads: "Remarque : Si le formulaire se recharge, cela signifie que les images n'ont pas passé nos contrôles de sécurité. Veuillez ajuster votre saisie et réessayer",
        youcanalsosendagift: "Vous pouvez également acheter un cadeau unique et l'envoyer à quelqu'un",
    },
    ru: {
        home: "Главная",
        pricing: "Цены",
        loginpage: "Войти",
        signup: "Регистрация",
        dashboard: "Панель управления",
        managesubscribtion: "Управление подпиской",
        upgradetopremium: "Повысить до премиум",
        logout: "Выйти",
        chooseacategory: "Выберите категорию",
        logingpage: "Страница входа",
        youarenotloggedin: "Вы в настоящее время не вошли в систему, пожалуйста, войдите здесь",
        resetpassword: "Сбросить пароль",
        enteremailtoresetpassowrd: "Введите свой адрес электронной почты для сброса пароля",
        email: "Email",
        submit: "Отправить",
        enteryournewpassword: "Введите новый пароль",
        newpassword: "Новый пароль",
        confirmnewpassword: "Подтвердите новый пароль",
        gotologin: "Перейти к входу",
        generallibrary: "Общая библиотека",
        yourlibrary: "Ваша библиотека",
        createnewbook: "Создать новую книгу",
        youarecurrentlynotloggedin: "Вы в настоящее время не вошли в систему, пожалуйста, войдите здесь",
        nowpleaseconfirmwhichpackage: "Теперь, пожалуйста, подтвердите, какой пакет вы хотите купить. В настоящее время вы используете этот адрес электронной почты:",
        onceyouclickonthebuybutton: "После нажатия кнопки 'Купить сейчас' ниже, вы будете перенаправлены к нашему надежному поставщику FastSpring, где вы сможете завершить детали оплаты. Пожалуйста, используйте тот же адрес электронной почты",
        chooseapackage: "Выберите пакет:",
        selectyourpackage: "Выберите ваш пакет",
        monthly: "Ежемесячно",
        yearly: "Ежегодно",
        buynow: "Купить сейчас",
        thankyouforchoosingpackage: "Спасибо за выбор вашего пакета. После завершения оплаты, пожалуйста, перезайдите, чтобы обновить ваш баланс. Вы сможете создавать свои собственные книги и получать доступ к вашей личной библиотеке.",
        youarecurrentlysubscribed: "Вы в настоящее время подписаны на",
        pleaseuseyourregisteredemail: "пакет. Пожалуйста, используйте свой зарегистрированный адрес электронной почты:",
        tomanageyouraccount: "для управления вашей учетной записью. Вы можете управлять своей подпиской",
        here: "здесь",
        noteifyoudecidetoupgradeyourpackage: "Примечание: Если вы решите обновить свой пакет, обновленный пакет будет отображаться при следующем входе в систему.",
        yourorderiscompleted: "ваш заказ выполнен",
        turnyourchildreality: "Превратите в реальность воображение вашего ребенка с ",
      
     
        whychoose: "Почему выбрать",
        ourplatformisdedicatedto: "Наша платформа посвящена укреплению семейных уз через персонализированные впечатления от историй. Предоставляя платформу для совместного рассказывания историй, мы способствуем глубоким связям между родителями и детьми, позволяя семьям создавать долговременные воспоминания вместе. Наши захватывающие истории, адаптированные под интересы и возрастные стадии детей, способствуют образовательному взаимодействию, укрепляя важные концепции и ценности",
        ignitecreativity: "Возгорите творчество",
        empowerchildrenimagination: "Повысьте воображение и творческие способности детей через увлекательные повествования и яркие иллюстрации. Наш сервис способствует развитию креативного мышления и умений решать проблемы.",
        connectondeeperlevel: "Связывайтесь на глубоком уровне",
        fosteradeeperbond: "Укрепите глубокие связи между родителями и детьми через персонализированные впечатления от историй. Наша платформа предоставляет интерактивные элементы для создания долговечных воспоминаний вместе.",
        funlearningadventures: "Веселые учебные приключения",
        leverageourservicestoprovide: "Используйте наш сервис для предоставления образовательного контента в увлекательной и интересной форме. Дети учатся ценным урокам и навыкам через погружение в истории, адаптированные под их интересы и возрастные стадии.",
        discoverwhyfamilies: "Узнайте, почему семьи доверяют нам",
        convenienceandsimplified: "Удобство и упрощенный доступ",
        saygoodbuytothehassle: "Попрощайтесь с необходимостью искать подходящие по возрасту книги или бороться с творческими идеями. Наш сервис предоставляет персонализированные истории и иллюстрации на вашем пороге.",
        customizedlearningexperience: "Персонализированный образовательный опыт",
        tailorstorytelling: "Настройте опыт рассказа истории под индивидуальные потребности и интересы вашего ребенка. Будь то укрепление определенных учебных концепций или обращение к социально-эмоциональным темам, наш сервис адаптируется под возрастные стадии и стиль обучения вашего ребенка.",
        parentalempowement: "Родительское уполномочивание",
        takeanactiverole: "Примите активное участие в образовании и росте вашего ребенка с помощью наших инструментов и ресурсов. Укрепите образовательный путь вашего ребенка и укрепите свои отношения через совместные впечатления от историй.",
        timesavingsolution: "Решение для экономии времени",
        savetimebyeleminating: "Экономьте время, избегая необходимости исследовать и подбирать образовательные материалы. Полагайтесь на нашу платформу для предоставления высококачественного персонализированного контента, отвечающего потребностям и интересам вашего ребенка.",
        experiencethemagic: "Ощутите магию рассказов с нами сегодня. Ознакомьтесь с нашими",
        pricingpage: "Страница с ценами",
        formoreinformationandstart: "для получения дополнительной информации и начала создания значимых воспоминаний с вашей семьей.",
        welcomebackpleaselogin: "Добро пожаловать обратно! Пожалуйста, войдите, чтобы продолжить.",
        password: "Пароль",
        donthaveanouaccount: "У вас нет аккаунта? Вы можете зарегистрироваться ",
        forgotyourpassword: "Забыли пароль? Вы можете его сбросить",
        welcome: "Добро пожаловать",
        letscreateyouraccount: "Давайте создадим ваш аккаунт!",
        firstname: "Имя",
        lastname: "Фамилия",
        termsandconditions: "Условия и положения",
        free: "Бесплатно",
        freeuser: "Бесплатный пользователь",
        accesstogenerallibrary: "Доступ к Общей Библиотеке и ежемесячно рекомендуемым книгам",
        earlyaccesstonewfeature: "Ранний доступ к акциям — Будьте первыми, кто узнает о новых функциях и предложениях",
        nocreditcardrequired: "Без необходимости кредитной карты — Начните без обязательств",
        signupnow: "Зарегистрируйтесь Сейчас",
        generalandprivatelibraries: "Доступ к Общим и Частным Библиотекам — Исследуйте новые рекомендованные истории каждый месяц",
        accesstonewaddedmonthlly: "Ежемесячный доступ к новым рекомендуемым книгам — Открывайте свежие истории каждый месяц",
        questbook: "5 Индивидуальных Книг — До 8 страниц, полностью персонализированных для вашего ребенка",
        odysseybook: "12 Индивидуальных Книг — До 8 страниц, полностью персонализированных для вашего ребенка",
        enchantedbook: "29 Индивидуальных Книг — До 8 страниц, полностью персонализированных для вашего ребенка",
        questbookannually: "60 Индивидуальных Книг — До 8 страниц, полностью персонализированных для вашего ребенка",
        odysseybookannually: "145 Индивидуальных Книг — До 8 страниц, полностью персонализированных для вашего ребенка",
        enchantedbookannually: "350 Индивидуальных Книг — До 8 страниц, полностью персонализированных для вашего ребенка",
         forallpaidpackages: "Для всех платных пакетов при оформлении заказа вы можете выбрать ежегодную оплату и получить ",
        onemonthfree: "один месяц бесплатно!",
        title: "Заголовок",
        creationdate: "Дата создания",
        action: "Действие",
        view: "Просмотр",
        pleasebarewithus: "Пожалуйста, будьте с нами терпеливы, хорошие вещи требуют времени!",
        loading: "Загрузка",
        booksremaining: "Осталось книг:",
        gender: "Пол:",
        booklength: "Длина книги:",
        agegroup: "Возрастная группа:",
        lessonlearned: "Извлеченные уроки",
        morallesson: "Моральный Урок",
        themes: "Темы",
        visualstyle: "Визуальный стиль:",
       Whatillustrationstyle: "Какой стиль иллюстрации вам кажется привлекательным?",
        characterarechetype: "Архетипы персонажей:",
        genre: "Жанр:",
        plotelements: " Сюжетные элементы:",
        magicalelements: "Магические элементы:",
        cluturalcontext: "Культурный контекст:",
        emotionaltone: "Эмоциональный тон:",
        environment: "Окружающая среда:",
        specificpreferences: "Особые предпочтения? Настройте свою историю еще больше:",
        storycreatedsuccesfully: "История создана и успешно сохранена!",
        failedtocreatestory: "Не удалось создать и сохранить историю!",
        toviewyourlibrary: "Чтобы просмотреть вашу библиотеку, нажмите",
        unfortunatellyyouhaveconsumed: "К сожалению, вы исчерпали все книги на этот месяц. Если вы хотите обновить ваш пакет, пожалуйста, посетите ",
        language: "Язык:",
        english: "Английский",
        arabic: "Арабский",
        bulgarian: "Болгарский",
        chinese: "Китайский",
        french: "Французский",
        german: "Немецкий",
        hindi: "Хинди",
        korean: "Корейский",
        portuguese: "Португальский",
        russian: "Русский",
        spanish: "Испанский",
        dutch: "Голландский",
        safeContent: 'Безопасен ли контент и подходит ли он для детей?',
safeContentAnswer: 'Да, контент на GWOW AI безопасен и подходит для детей. Мы внимательно следим за тем, чтобы все истории и иллюстрации, созданные нашим ИИ, были подходящими для молодой аудитории. Наша платформа позволяет родителям настраивать истории, чтобы убедиться, что контент соответствует их конкретным требованиям и предпочтениям.',
supportedDevices: 'Какие устройства поддерживаются AI Interactive Storyteller?',
supportedDevicesAnswer: 'AI Interactive Storyteller поддерживает все современные устройства, включая смартфоны, планшеты и компьютеры. Для доступа к сайту требуется подключение к Интернету, но вы также можете загрузить истории локально для офлайн-доступа.',
globalSupport: 'Поддерживается ли это глобально?',
globalSupportAnswer: 'Да, GWOW AI поддерживается глобально. Пользователи могут выбирать из 12 различных языков для создания и наслаждения своими историями, что делает его доступным для широкой аудитории по всему миру.',
freeTrial: 'Доступна ли бесплатная пробная версия?',
freeTrialAnswer: 'Да, доступна бесплатная пробная версия. Вы можете создать бесплатную учетную запись, чтобы получить доступ к общей библиотеке. Для создания индивидуальных историй вам нужно будет подписаться на один из наших пакетов, начиная с $7.99.',
dataCollection: 'Какие данные собираются и как они используются?',
dataCollectionAnswer: 'Мы собираем основные данные для входа, чтобы предоставлять и улучшать наши услуги. Эта информация хранится в безопасности, не передается третьим лицам и не будет продаваться.',
aiBooksCreativity: 'Как книги, созданные ИИ, могут улучшить творчество и обучение моего ребенка?',
aiBooksCreativityAnswer: 'Книги, созданные ИИ, могут значительно улучшить творчество и обучение вашего ребенка, предоставляя персонализированные и увлекательные истории. Эти истории могут быть адаптированы к интересам и потребностям обучения вашего ребенка, делая чтение более интерактивным и приятным. Кроме того, родители могут загружать истории в формате PDF или раскраски, чтобы еще больше вовлечь своих детей.',

        faqAppropriateContent: 'Является ли контент безопасным и соответствующим возрасту для детей?',
        faqSupportedDevices: 'Какие устройства поддерживает интерактивный рассказчик с ИИ?',
        faqGlobalSupport: 'Поддерживается ли это по всему миру?',
        faqFreeTrial: 'Есть ли доступна бесплатная пробная версия?',
        faqDataCollection: 'Какие данные собираются и как они используются?',
        faqAIBooks: 'Как книги, созданные ИИ, могут улучшить креативность и обучение моего ребенка?',
        answerAppropriateContent: 'Да, контент на GWOW AI безопасен и соответствует возрасту для детей. Мы очень заботимся о том, чтобы все истории и иллюстрации, созданные нашим ИИ, были подходящими для юной аудитории. Наша платформа позволяет родителям настраивать истории, обеспечивая соответствие контента их конкретным требованиям и предпочтениям.',
        answerSupportedDevices: 'Интерактивный рассказчик с ИИ поддерживает все современные устройства, включая смартфоны, планшеты и компьютеры. Для доступа к сайту вам потребуется подключение к интернету, но вы также можете скачивать истории для оффлайн-доступа.',
        answerGlobalSupport: 'Да, GWOW AI поддерживается по всему миру. Пользователи могут выбирать из 12 различных языков для создания и наслаждения своими историями, что делает его доступным для широкой аудитории по всему миру.',
        answerFreeTrial: 'Да, доступна бесплатная пробная версия. Вы можете создать бесплатную учетную запись пользователя для доступа к общей библиотеке. Для создания персонализированных историй вам потребуется подписаться на один из наших пакетов, начиная с 4,99 $.',
        answerDataCollection: 'Мы собираем основную информацию для входа в систему для предоставления и улучшения наших услуг. Эта информация сохраняется в безопасности, не передается никому и не продается.',
        answerAIBooks: 'Книги, созданные ИИ, могут значительно улучшить креативность и обучение вашего ребенка, предоставляя персонализированные и увлекательные истории. Эти истории могут быть адаптированы к интересам и учебным потребностям вашего ребенка, делая чтение более интерактивным и приятным. Кроме того, родители могут скачивать истории в формате PDF или раскраски для дальнейшего вовлечения своих детей.',
        learnmore: "Узнать больше:",
        years: "годы", selectgender: "Выберите пол", male: "Мужчина", female: "Женщина", unspecified: "Не указано", selectbooklength: "Выберите длину книги", pages: "страницы", selectmorallesson: "Выберите моральный урок", friendship: "Дружба", kindness: "Доброта", courage: "Смелость", honesty: "Честность", empathy: "Сочувствие", teamwork: "Работа в команде", acceptance: "Принятие", responsibility: "Ответственность", creativity: "Креативность", selectvisualstyle: "Выберите визуальный стиль", realistic: "Реалистичный", cartoon: "Мультфильм", painterly: "Живописный", anime: "Аниме", fantasy: "Фэнтези",
        watercolor: "Акварель",
        popart: "Поп-арт",  selectgenre: "Выберите жанр", adventure: "Приключение", mystery: "Тайна",  sciencefiction: "Научная фантастика", historicalfiction: "Историческая фантастика", romance: "Романс", comedy: "Комедия", addatwist: "Добавить поворот", selectmagicalelement: "Выберите магический элемент", wizards: "Волшебники", mermaids: "Русалки", dinosaurs: "Динозавры", robots: "Роботы", cars: "Машины", pets: "Домашние животные",
        dragons: "Драконы", aliens: "Инопланетяне", fairies: "Феи", superheroes: "Супергерои", pirates: "Пираты", cowboys: "Ковбои", secretworlds: "Секретные миры", magicalcreatures: "Магические существа", knights: "Рыцари", princesses: "Принцессы", otherspleasespecifybelow: "Другие, укажите в текстовом поле ниже", selectculturalcontext: "Выберите культурный контекст", ancientegypt: "Древний Египет", medievaleurope: "Средневековая Европа", feudaljapan: "Феодальная Япония", victorianengland: "Викторианская Англия", renaissanceitaly: "Италия Ренессанса", nativeamericanfolklore: "Фольклор коренных американцев", africanmythology: "Африканская мифология", asianlegends: "Азиатские легенды", wildwest: "Дикий Запад", selectenvironment: "Выберите окружение", forest: "Лес", cityscape: "Городской пейзаж", countryside: "Сельская местность", mountains: "Горы", beach: "Пляж", underwaterkingdom: "Подводное королевство", outerspace: "Космос", enchantedforest: "Зачарованный лес", magicalcastle: "Волшебный замок", desert: "Пустыня", arctictundra: "Арктическая тундра", volcanicisland: "Вулканический остров", alienplanet: "Инопланетная планета",
        Superheroes: "Супергерои",
        VariousTales: "Разные Сказки",
        maincharacter: "Главный Персонаж",

        animals: "Животные",
        toys: "Игрушки",
        mythicalcreatures: "Мифические существа",
        momentstogether: "Время вместе",
        selectone: "Выберите Один",
        completeregistration: "Завершите регистрацию",

        subscriptionsuccessful: "Ура! Ваша подписка прошла успешно, и у вас есть новые кредиты для историй! 🎉 Чтобы увидеть обновленный баланс, пожалуйста, выйдите и снова войдите в систему. Приготовьтесь к новым увлекательным приключениям!",
        paymentissue: "Ой! Похоже, ваш платеж не прошел. Проверьте данные карты или PayPal и попробуйте еще раз. Если это продолжается, попробуйте другой способ оплаты, чтобы не упустить веселье!",
        underreview: "Подождите немного! Ваш заказ находится на проверке, чтобы убедиться, что все в порядке. Мы сообщим вам, как только он будет готов. Спасибо за терпение—скоро будут новые истории!",
        pendingpayment: "Ваш платеж уже в пути! Как только мы его получим, ваши кредиты на истории будут готовы к использованию. Если вы используете PayPal или банковский перевод, убедитесь, что все одобрено, чтобы вернуться к приключениям.",


        selectagegroup: "Выберите возрастную группу",
        createstory: "Создать историю",
        flipdeviceforbetterview: "Переверните устройство для лучшего обзора",
        gobacktolibrary: "Вернуться в библиотеку",
        downloadbook: "Скачать книгу",
        downloadcoloringbook: "Скачать раскраску",
        forsupportemailusat: "Для поддержки свяжитесь с нами по электронной почте ",
        chooselanguage: "Выберите язык",
        currentsubscriptiondetails: "Текущие детали подписки",
        packagename: "Название пакета",
        enddate: "Дата окончания",
        remainingbalance: "Оставшийся баланс",
        emailaddress: "Электронная почта",
        renewsubscription: "Продлить подписку",
        confirrenewsubscriptiondate: "Вы уверены, что хотите продлить свою подписку сейчас?",
        ifyoudecidetoupgrade: "Если вы решите изменить подписку, ваша новая подписка начнётся сегодня",
        yes: "Да",
        no: "Нет",
        cancelsubscription: "Отменить подписку",
        confirmsubcancelation: "Вы уверены, что хотите отменить подписку? После отмены у вас будет доступ только к бесплатным функциям и общей библиотеке",
        toupgradeyourpackage: "Чтобы обновить или понизить пакет, выберите один из следующих вариантов",
        somethingwrongcontactsupport: "Что-то пошло не так. Пожалуйста, свяжитесь с командой поддержки для получения помощи.",
        emailverification: "Подтверждение электронной почты",
        pleaselogin: "Пожалуйста, войдите",
        downloadbookfeature: "Скачать книги — Доступны в оригинальной или раскрасках версии, готовые к печати",
        child: "Ребенок",
        superhero: "Супергерой",
        animal: "Животное",
        wizard: "Волшебник",
        fairy: "Фея",
        robot: "Робот",
        fantasycharacters: "Фантастические Персонажи",
        aboutus: "О Нас",
        frequentlyaskedquestion: "Часто Задаваемые Вопросы",
        howitworks: "Как это работает",
 languagedisclaimer: "Обратите внимание, что Условия и Положения отображаются на английском языке в качестве справки. Несмотря на наши усилия сделать наш контент доступным на нескольких языках, английская версия этих Условий и Положений всегда будет иметь приоритет в случае любых расхождений, недоразумений или юридических споров, возникающих из переводов. Согласившись с этими Условиями и Положениями, вы признаете, что несете ответственность за точную интерпретацию контента на вашем предпочтительном языке. В случае любых юридических споров или неясностей, английская версия Условий и Положений будет иметь приоритет. Если у вас есть вопросы или требуется разъяснение, мы рекомендуем вам обратиться за профессиональной консультацией или напрямую связаться с нами.",
        availablecurrences: "Поддержка более 100 местных валют, включая USD, EUR, GBP, JPY, AUD и другие!",
        ifyouhaveacouponyoucanredeemit: "Если у вас есть купон, вы можете его обменять",
        ifthestoryreloads: "Примечание: Если форма перезагружается, это означает, что изображения не прошли нашу проверку безопасности. Пожалуйста, скорректируйте свой ввод и попробуйте снова",
        youcanalsosendagift: "Вы также можете купить одноразовый подарок и отправить его кому-то",
    },
    de: {
        home: "Startseite",
        pricing: "Preise",
        loginpage: "Anmelden",
        signup: "Registrieren",
        dashboard: "Dashboard",
        managesubscribtion: "Abonnement verwalten",
        upgradetopremium: "Auf Premium upgraden",
        logout: "Abmelden",
        chooseacategory: "Kategorie wählen",
        logingpage: "Anmeldeseite",
        youarenotloggedin: "Sie sind derzeit nicht angemeldet. Bitte melden Sie sich hier an",
        resetpassword: "Passwort zurücksetzen",
        enteremailtoresetpassowrd: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        email: "E-Mail",
        submit: "Absenden",
        enteryournewpassword: "Geben Sie Ihr neues Passwort ein",
        newpassword: "Neues Passwort",
        confirmnewpassword: "Neues Passwort bestätigen",
        gotologin: "Zur Anmeldung gehen",
        generallibrary: "Allgemeine Bibliothek",
        yourlibrary: "Ihre Bibliothek",
        createnewbook: "Neues Buch erstellen",
        youarecurrentlynotloggedin: "Sie sind derzeit nicht angemeldet. Bitte melden Sie sich hier an",
        nowpleaseconfirmwhichpackage: "Bestätigen Sie bitte jetzt, welches Paket Sie kaufen möchten. Derzeit verwenden Sie diese E-Mail-Adresse:",
        onceyouclickonthebuybutton: "Sobald Sie auf die Schaltfläche 'Jetzt kaufen' unten klicken, werden Sie zu unserem vertrauenswürdigen Anbieter FastSpring weitergeleitet, wo Sie die Zahlungsdetails finalisieren können. Stellen Sie sicher, dass Sie dieselbe E-Mail-Adresse verwenden",
        chooseapackage: "Wählen Sie ein Paket aus:",
        selectyourpackage: "Wählen Sie Ihr Paket aus",
        monthly: "Monatlich",
        yearly: "Jährlich",
        buynow: "Jetzt kaufen",
        thankyouforchoosingpackage: "Vielen Dank für die Auswahl Ihres Pakets. Bitte melden Sie sich nach Abschluss der Zahlungsdetails erneut an, um Ihr Guthaben zu aktualisieren. Sie werden in der Lage sein, Ihre eigenen Bücher zu erstellen und auf Ihre private Bibliothek zuzugreifen.",
        youarecurrentlysubscribed: " Sie sind derzeit abonniert auf das",
        pleaseuseyourregisteredemail: "Paket. Bitte verwenden Sie Ihre registrierte E-Mail-Adresse:",
        tomanageyouraccount: "zur Verwaltung Ihres Kontos. Sie können Ihr Abonnement verwalten",
        here: "hier",
        noteifyoudecidetoupgradeyourpackage: "Hinweis: Wenn Sie sich entscheiden, Ihr Paket zu aktualisieren, wird das aktualisierte Paket das nächste Mal angezeigt, wenn Sie sich anmelden.",
        yourorderiscompleted: "Ihre Bestellung ist abgeschlossen",
        turnyourchildreality: "Verwandeln Sie die Vorstellungskraft Ihres Kindes in Realität mit ",
       
      
        whychoose: "Warum wählen",
        ourplatformisdedicatedto: "Unsere Plattform widmet sich der Stärkung familiärer Bindungen durch personalisierte Geschichtenerlebnisse. Indem wir eine Plattform für gemeinsames Erzählen bereitstellen, fördern wir tiefere Verbindungen zwischen Eltern und Kindern und ermöglichen es Familien, bleibende Erinnerungen zusammen zu schaffen. Unsere immersiven Geschichten, maßgeschneidert auf die Interessen und Entwicklungsstadien der Kinder, fördern bildende Beschäftigung, während sie wichtige Konzepte und Werte festigen",
        ignitecreativity: "Kreativität entfachen",
        empowerchildrenimagination: "Stärken Sie die Vorstellungskraft und Kreativität von Kindern durch ansprechende Erzählungen und lebendige Illustrationen. Unser Service fördert kreatives Denken und Problemlösungsfähigkeiten.",
        connectondeeperlevel: "Verbinden Sie sich auf einer tieferen Ebene",
        fosteradeeperbond: "Fördern Sie eine tiefere Bindung zwischen Eltern und Kindern durch personalisierte Geschichtenerlebnisse. Unsere Plattform bietet interaktive Elemente, damit Familien bleibende Erinnerungen zusammen schaffen können.",
        funlearningadventures: "Spaßige Lernabenteuer",
        leverageourservicestoprovide: "Nutzen Sie unseren Service, um Bildungsinhalte auf unterhaltsame und ansprechende Weise bereitzustellen. Kinder lernen wertvolle Lektionen und Fähigkeiten durch immersive Geschichten, die auf ihre Interessen und Entwicklungsstadien zugeschnitten sind.",
        discoverwhyfamilies: "Erfahren Sie, warum Familien uns vertrauen",
        convenienceandsimplified: "Bequemlichkeit und vereinfachter Zugang",
        saygoodbuytothehassle: "Sagen Sie Auf Wiedersehen zu der Mühe, nach altersgerechten Büchern zu suchen oder kreative Ideen zu entwickeln. Unser Service bietet personalisierte Geschichten und Illustrationen auf Knopfdruck.",
        customizedlearningexperience: "Maßgeschneiderte Lernerfahrungen",
        tailorstorytelling: "Passen Sie Geschichtenerlebnisse an die individuellen Bedürfnisse und Interessen Ihres Kindes an. Ob Verstärkung spezifischer Lernkonzepte oder Ansprache sozial-emotionaler Themen, unser Service passt sich dem Entwicklungsstand und Lernstil Ihres Kindes an.",
        parentalempowement: "Stärkung der Elternkompetenz",
        takeanactiverole: "Nehmen Sie eine aktive Rolle in der Bildung und Entwicklung Ihres Kindes mit unseren Tools und Ressourcen ein. Stärken Sie die Lernreise Ihres Kindes und stärken Sie Ihre Beziehung durch gemeinsame Geschichtenerlebnisse.",
        timesavingsolution: "Zeitsparende Lösung",
        savetimebyeleminating: "Sparen Sie Zeit, indem Sie die Notwendigkeit zur Recherche und Zusammenstellung von Bildungsmaterialien eliminieren. Verlassen Sie sich auf unsere Plattform, um hochwertige, personalisierte Inhalte bereitzustellen, die auf die Bedürfnisse und Interessen Ihres Kindes zugeschnitten sind.",
        experiencethemagic: "Erleben Sie die Magie des Geschichtenerzählens mit uns heute. Schauen Sie sich unsere",
        pricingpage: "Preisübersichtsseite",
        formoreinformationandstart: "Für weitere Informationen und um bedeutsame Erinnerungen mit Ihrer Familie zu schaffen.",
        welcomebackpleaselogin: "Willkommen zurück! Bitte melden Sie sich an, um fortzufahren.",
        password: "Passwort",
        donthaveanouaccount: "Sie haben noch kein Konto? Sie können sich registrieren ",
        forgotyourpassword: "Passwort vergessen? Sie können es zurücksetzen ",
        welcome: "Willkommen",
        letscreateyouraccount: "Lassen Sie uns Ihr Konto erstellen!",
        firstname: "Vorname",
        lastname: "Nachname",
        termsandconditions: "Nutzungsbedingungen",
        free: "Kostenlos",
        freeuser: "Kostenloser Benutzer",
        accesstogenerallibrary: "Zugang zur Allgemeinen Bibliothek und monatlich empfohlenen Büchern",
        earlyaccesstonewfeature: "Früher Zugang zu Aktionen — Seien Sie der Erste, der neue Funktionen und Angebote entdeckt",
        nocreditcardrequired: "Keine Kreditkarte erforderlich — Beginnen Sie ohne Verpflichtungen",
        signupnow: "Jetzt Anmelden",
        generalandprivatelibraries: "Zugang zu allgemeinen und privaten Bibliotheken — Entdecken Sie jeden Monat neue empfohlene Geschichten",
        accesstonewaddedmonthlly: "Monatlicher Zugang zu neuen empfohlenen Büchern — Entdecken Sie jeden Monat neue Geschichten",
        questbook: "5 Individuelle Bücher — Bis zu 8 Seiten, vollständig personalisiert für Ihr Kind",
        odysseybook: "12 Individuelle Bücher — Bis zu 8 Seiten, vollständig personalisiert für Ihr Kind",
        enchantedbook: "29 Individuelle Bücher — Bis zu 8 Seiten, vollständig personalisiert für Ihr Kind",
        questbookannually: "60 Individuelle Bücher — Bis zu 8 Seiten, vollständig personalisiert für Ihr Kind",
        odysseybookannually: "145 Individuelle Bücher — Bis zu 8 Seiten, vollständig personalisiert für Ihr Kind",
        enchantedbookannually: "350 Individuelle Bücher — Bis zu 8 Seiten, vollständig personalisiert für Ihr Kind",
 forallpaidpackages: "Für alle kostenpflichtigen Pakete können Sie an der Kasse wählen, jährlich abgerechnet zu werden und ",
        onemonthfree: "einen Monat kostenlos!",
        title: "Titel",
        creationdate: "Erstellungsdatum",
        action: "Aktion",
        view: "Anzeigen",
        pleasebarewithus: "Bitte haben Sie etwas Geduld, gute Dinge brauchen Zeit!",
        loading: "Wird geladen",
        booksremaining: "Verbleibende Bücher:",
        gender: "Geschlecht:",
        booklength: "Buchlänge:",
        agegroup: "Altersgruppe:",
        lessonlearned: "Gelernte Lektionen",
        morallesson: "Moralische Lektion",
        themes: "Themen",
        visualstyle: "Visueller Stil:",
        Whatillustrationstyle: "Welcher Illustrationsstil gefällt Ihnen am meisten?",
        characterarechetype: "Charakterarchetypen: ",
        genre: "Genre:",
        plotelements: " Handlungselemente:",
        magicalelements: "Magische Elemente:",
        cluturalcontext: "Kultureller Kontext:",
        emotionaltone: "Emotionale Tonlage:",
        environment: "Umgebung:",
        specificpreferences: "Spezifische Vorlieben? Gestalten Sie Ihre Geschichte noch individueller:",
        storycreatedsuccesfully: "Geschichte erfolgreich erstellt und gespeichert!",
        failedtocreatestory: "Fehler beim Erstellen und Speichern der Geschichte!",
        toviewyourlibrary: "Um Ihre Bibliothek anzusehen, klicken Sie ",
        unfortunatellyyouhaveconsumed: "Leider haben Sie alle Ihre Bücher für den Monat verbraucht. Wenn Sie Ihr Paket aktualisieren möchten, besuchen Sie bitte die ",


        safeContent: 'Ist der Inhalt sicher und altersgerecht für Kinder?',
        safeContentAnswer: 'Ja, der Inhalt auf GWOW AI ist sicher und altersgerecht für Kinder. Wir legen großen Wert darauf, dass alle von unserer KI generierten Geschichten und Illustrationen für junge Zielgruppen geeignet sind. Unsere Plattform ermöglicht es Eltern, Geschichten anzupassen, um sicherzustellen, dass der Inhalt ihren spezifischen Anforderungen und Vorlieben entspricht.',
        supportedDevices: 'Welche Geräte werden vom AI Interactive Storyteller unterstützt?',
        supportedDevicesAnswer: 'Der AI Interactive Storyteller unterstützt alle modernen Geräte, einschließlich Smartphones, Tablets und Computer. Sie benötigen eine Internetverbindung, um auf die Website zuzugreifen, aber Sie können Geschichten auch lokal herunterladen, um sie offline anzusehen.',
        globalSupport: 'Wird es weltweit unterstützt?',
        globalSupportAnswer: 'Ja, GWOW AI wird weltweit unterstützt. Benutzer können aus 12 verschiedenen Sprachen wählen, um ihre Geschichten zu erstellen und zu genießen, was es einem breiten Publikum auf der ganzen Welt zugänglich macht.',
        freeTrial: 'Gibt es eine kostenlose Testversion?',
        freeTrialAnswer: 'Ja, es gibt eine kostenlose Testversion. Sie können ein kostenloses Benutzerkonto erstellen, um auf die allgemeine Bibliothek zuzugreifen. Um benutzerdefinierte Geschichten zu erstellen, müssen Sie ein Abonnement für eines unserer Pakete ab 7,99 $ abschließen.',
        dataCollection: 'Welche Daten werden gesammelt und wie werden sie verwendet?',
        dataCollectionAnswer: 'Wir sammeln grundlegende Anmeldeinformationen, um unsere Dienste bereitzustellen und zu verbessern. Diese Informationen werden sicher aufbewahrt, nicht mit Dritten geteilt und nicht verkauft.',
        aiBooksCreativity: 'Wie können von KI generierte Bücher die Kreativität und das Lernen meines Kindes verbessern?',
        aiBooksCreativityAnswer: 'Von KI generierte Bücher können die Kreativität und das Lernen Ihres Kindes erheblich verbessern, indem sie personalisierte und ansprechende Geschichten bieten. Diese Geschichten können auf die Interessen und Lernbedürfnisse Ihres Kindes zugeschnitten werden, wodurch das Lesen interaktiver und angenehmer wird. Darüber hinaus können Eltern Geschichten als PDFs oder Malbücher herunterladen, um ihre Kinder noch mehr zu beschäftigen.',

        language: "Sprache:",
        english: "Englisch",
        arabic: "Arabisch",
        bulgarian: "Bulgarisch",
        chinese: "Chinesisch",
        french: "Französisch",
        german: "Deutsch",
        hindi: "Hindi",
        korean: "Koreanisch",
        portuguese: "Portugiesisch",
        russian: "Russisch",
        spanish: "Spanisch",
        dutch: "Niederländisch",
        faqAppropriateContent: "Ist der Inhalt sicher und altersgerecht für Kinder?",
        faqSupportedDevices: "Welche Geräte werden vom interaktiven Geschichtenerzähler mit KI unterstützt?",
        faqGlobalSupport: "Wird es weltweit unterstützt?",
        faqFreeTrial: "Gibt es eine kostenlose Testversion?",
        faqDataCollection: "Welche Daten werden gesammelt und wie werden sie verwendet?",
        faqAIBooks: "Wie können KI-generierte Bücher die Kreativität und das Lernen meines Kindes verbessern?",
        answerAppropriateContent: "Ja, der Inhalt auf GWOW AI ist sicher und altersgerecht für Kinder. Wir legen großen Wert darauf, dass alle von unserer KI generierten Geschichten und Illustrationen für junge Zielgruppen geeignet sind. Unsere Plattform ermöglicht es Eltern, die Geschichten anzupassen, um sicherzustellen, dass der Inhalt ihren spezifischen Anforderungen und Vorlieben entspricht.",
        answerSupportedDevices: "Der interaktive Geschichtenerzähler mit KI unterstützt alle modernen Geräte, einschließlich Smartphones, Tablets und Computer. Sie benötigen eine Internetverbindung, um auf die Website zuzugreifen, können aber auch Geschichten lokal herunterladen, um offline darauf zuzugreifen.",
        answerGlobalSupport: "Ja, GWOW AI wird weltweit unterstützt. Benutzer können aus 12 verschiedenen Sprachen wählen, um ihre Geschichten zu erstellen und zu genießen, was es einem breiten Publikum auf der ganzen Welt zugänglich macht.",
        answerFreeTrial: "Ja, eine kostenlose Testversion ist verfügbar. Sie können ein kostenloses Benutzerkonto erstellen, um auf die öffentliche Bibliothek zuzugreifen. Für die Erstellung benutzerdefinierter Geschichten müssen Sie eines unserer Pakete abonnieren, beginnend bei 4,99 $.",
        answerDataCollection: "Wir sammeln grundlegende Anmeldeinformationen, um unsere Dienste bereitzustellen und zu verbessern. Diese Informationen werden sicher aufbewahrt, nicht weitergegeben und nicht verkauft.",
        answerAIBooks: "KI-generierte Bücher können die Kreativität und das Lernen Ihres Kindes erheblich verbessern, indem sie personalisierte und ansprechende Geschichten bieten. Diese Geschichten können an die Interessen und Lernbedürfnisse Ihres Kindes angepasst werden, was das Lesen zu einer interaktiveren und angenehmeren Erfahrung macht. Darüber hinaus können Eltern Geschichten im PDF-Format oder Malbücher herunterladen, um ihre Kinder weiter zu engagieren.",
        learnmore: "Erfahren Sie mehr:",
        years: "Jahre", selectgender: "Geschlecht wählen", male: "Männlich", female: "Weiblich", unspecified: "Nicht spezifiziert", selectbooklength: "Buchlänge wählen", pages: "Seiten", selectmorallesson: "Moralische Lektion wählen", friendship: "Freundschaft", kindness: "Freundlichkeit", courage: "Mut", honesty: "Ehrlichkeit", empathy: "Empathie", teamwork: "Teamarbeit", acceptance: "Akzeptanz", responsibility: "Verantwortung", creativity: "Kreativität", selectvisualstyle: "Visuellen Stil wählen", realistic: "Realistisch", cartoon: "Cartoon", painterly: "Malerisch", anime: "Anime", fantasy: "Fantasy",
        watercolor: "Aquarell",
        popart: "Pop-Art",  selectgenre: "Genre wählen", adventure: "Abenteuer", mystery: "Geheimnis",  sciencefiction: "Science-Fiction", historicalfiction: "Historische Fiktion", romance: "Romantik", comedy: "Komödie", addatwist: "Eine Wendung hinzufügen", selectmagicalelement: "Magisches Element wählen", wizards: "Zauberer", mermaids: "Meerjungfrauen", dinosaurs: "Dinosaurier", robots: "Roboter", cars: "Autos", pets: "Haustiere",
        dragons: "Drachen", aliens: "Außerirdische", fairies: "Feen", superheroes: "Superhelden", pirates: "Piraten", cowboys: "Cowboys", secretworlds: "Geheime Welten", magicalcreatures: "Magische Kreaturen", knights: "Ritter", princesses: "Prinzessinnen", otherspleasespecifybelow: "Andere, bitte unten im Textfeld angeben", selectculturalcontext: "Wählen Sie den kulturellen Kontext", ancientegypt: "Altes Ägypten", medievaleurope: "Mittelalterliches Europa", feudaljapan: "Feudales Japan", victorianengland: "Viktorianisches England", renaissanceitaly: "Renaissance Italien", nativeamericanfolklore: "Indianische Folklore", africanmythology: "Afrikanische Mythologie", asianlegends: "Asiatische Legenden", wildwest: "Wilder Westen", selectenvironment: "Wählen Sie die Umgebung", forest: "Wald", cityscape: "Stadtbild", countryside: "Landschaft", mountains: "Berge", beach: "Strand", underwaterkingdom: "Unterwasser-Königreich", outerspace: "Weltraum", enchantedforest: "Verzauberter Wald", magicalcastle: "Magisches Schloss", desert: "Wüste", arctictundra: "Arktische Tundra", volcanicisland: "Vulkaninsel", alienplanet: "Alien-Planet",
        Superheroes: "Superhelden",
        VariousTales: "Verschiedene Geschichten",
        maincharacter: "Hauptcharakter",

        animals: "Tiere",
        toys: "Spielzeuge",
        mythicalcreatures: "Mythische Kreaturen",
        momentstogether: "Momente Zusammen",
        selectone: "Wählen Sie Eine",
        completeregistration: "Registrierung abschließen",

        subscriptionsuccessful: "Yay! Dein Abonnement war erfolgreich und du hast neue Story-Credits erhalten! 🎉 Um dein aktualisiertes Guthaben zu sehen, logge dich bitte aus und wieder ein. Mach dich bereit für mehr spannende Abenteuer!",
        paymentissue: "Oh nein! Es scheint, als wäre deine Zahlung fehlgeschlagen. Überprüfe deine Karten- oder PayPal-Daten und versuche es erneut. Wenn es weiterhin nicht klappt, probiere eine andere Zahlungsmethode, damit du nichts verpasst!",
        underreview: "Halte durch! Deine Bestellung wird gerade überprüft, um sicherzustellen, dass alles in Ordnung ist. Wir melden uns, sobald sie fertig ist. Danke für deine Geduld—mehr Geschichten kommen bald auf dich zu!",
        pendingpayment: "Deine Zahlung ist auf dem Weg zu uns! Sobald wir sie erhalten haben, sind deine Story-Credits bereit zur Nutzung. Wenn du PayPal oder eine Überweisung benutzt, stelle sicher, dass alles genehmigt wurde, damit du wieder in das Abenteuer eintauchen kannst.",

        selectagegroup: "Altersgruppe auswählen",
        createstory: "Geschichte erstellen",
        flipdeviceforbetterview: "Drehen Sie das Gerät für eine bessere Ansicht",
        gobacktolibrary: "Zurück zur Bibliothek",
        downloadbook: "Buch herunterladen",
        downloadcoloringbook: "Malbuch herunterladen",
        forsupportemailusat: "Für Unterstützung senden Sie uns eine E-Mail an ",
        chooselanguage: "Sprache wählen",
        currentsubscriptiondetails: "Aktuelle Abonnementdetails",
        packagename: "Paketname",
        enddate: "Enddatum",
        remainingbalance: "Verbleibendes Guthaben",
        emailaddress: "E-Mail-Adresse",
        renewsubscription: "Abonnement erneuern",
        confirrenewsubscriptiondate: "Sind Sie sicher, dass Sie Ihr Abonnement jetzt verlängern möchten?",
        ifyoudecidetoupgrade: "Wenn Sie Ihr Abonnement ändern, beginnt Ihr neues Abonnement heute",
        yes: "Ja",
        no: "Nein",
        cancelsubscription: "Abonnement kündigen",
        confirmsubcancelation: "Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten? Nach der Kündigung haben Sie nur noch Zugriff auf kostenlose Funktionen und allgemeine Bibliotheken",
        toupgradeyourpackage: "Um Ihr Paket zu upgraden oder herunterzustufen, wählen Sie eines der folgenden",
        somethingwrongcontactsupport: "Etwas ist möglicherweise falsch. Bitte kontaktieren Sie das Support-Team für Unterstützung.",
        emailverification: "E-Mail-Verifizierung",
        pleaselogin: "Bitte einloggen",
        downloadbookfeature: "Bilderbücher herunterladen — Erhältlich in originaler oder Ausmalbuchversion, druckbereit",
        child: "Kind",
        superhero: "Superheld",
        animal: "Tier",
        wizard: "Zauberer",
        fairy: "Fee",
        robot: "Roboter",
        fantasycharacters: "Fantasiefiguren",

        aboutus: "Über Uns",
        frequentlyaskedquestion: "Häufig Gestellte Fragen",
        howitworks: "Wie es funktioniert",
 languagedisclaimer: "Bitte beachten Sie, dass die Allgemeinen Geschäftsbedingungen zur Orientierung in englischer Sprache angezeigt werden. Obwohl wir uns bemühen, unsere Inhalte in mehreren Sprachen verfügbar zu machen, hat die englische Version dieser Allgemeinen Geschäftsbedingungen im Falle von Unstimmigkeiten, Missverständnissen oder rechtlichen Streitigkeiten, die aus Übersetzungen entstehen, stets Vorrang. Durch die Zustimmung zu diesen Allgemeinen Geschäftsbedingungen erkennen Sie an, dass es Ihre Verantwortung ist, eine genaue Interpretation des Inhalts in Ihrer bevorzugten Sprache sicherzustellen. Im Falle von rechtlichen Streitigkeiten oder Unklarheiten hat die englische Version der Allgemeinen Geschäftsbedingungen Vorrang. Wenn Sie Fragen haben oder eine Klarstellung benötigen, empfehlen wir Ihnen, professionellen Rat einzuholen oder uns direkt zu kontaktieren.",
        availablecurrences: "Unterstützung von über 100 lokalen Währungen, einschließlich USD, EUR, GBP, JPY, AUD und mehr!",
        ifyouhaveacouponyoucanredeemit: "Wenn Sie einen Gutschein haben, können Sie ihn einlösen",
        ifthestoryreloads: "Hinweis: Wenn das Formular neu geladen wird, bedeutet dies, dass die Bilder unsere Sicherheitsprüfungen nicht bestanden haben. Bitte passen Sie Ihre Eingaben an und versuchen Sie es erneut",
        youcanalsosendagift: "Sie können auch ein einmaliges Geschenk kaufen und es an jemanden senden",
    },
    ja: {
        home: "ホーム",
        pricing: "価格設定",
        loginpage: "ログイン",
        signup: "サインアップ",
        dashboard: "ダッシュボード",
        managesubscribtion: "定期購読の管理",
        upgradetopremium: "プレミアムにアップグレード",
        logout: "ログアウト",
        chooseacategory: "カテゴリを選択",
        logingpage: "ログインページ",
        youarenotloggedin: "現在ログインしていません。こちらからログインしてください。",
        resetpassword: "パスワードをリセット",
        enteremailtoresetpassowrd: "パスワードをリセットするにはメールアドレスを入力してください",
        email: "メール",
        submit: "送信",
        enteryournewpassword: "新しいパスワードを入力してください",
        newpassword: "新しいパスワード",
        confirmnewpassword: "新しいパスワードを確認",
        gotologin: "ログインする",
        generallibrary: "一般図書館",
        yourlibrary: "あなたの図書館",
        createnewbook: "新しい本を作成する",
        youarecurrentlynotloggedin: "現在ログインしていません。こちらからログインしてください。",
        nowpleaseconfirmwhichpackage: "今、どのパッケージを購入したいか確認してください。現在、このメールアドレスを使用しています：",
        onceyouclickonthebuybutton: "以下の「今すぐ購入」ボタンをクリックすると、お支払いの詳細を確定できるFastSpringの信頼できるベンダーに移動します。同じメールアドレスを使用してください。",
        chooseapackage: "パッケージを選択",
        selectyourpackage: "パッケージを選択",
        monthly: "月額",
        yearly: "年間",
        buynow: "今すぐ購入",
        thankyouforchoosingpackage: "パッケージを選択していただきありがとうございます。支払いの詳細を完了した後、バランスを更新するために再度ログインしてください。独自の本を作成し、プライベートライブラリにアクセスできるようになります。",
        youarecurrentlysubscribed: " 現在、以下に登録されています：",
        pleaseuseyourregisteredemail: "パッケージ。登録されたメールアドレスを使用してください：",
        tomanageyouraccount: "アカウントを管理するために。",
        here: "こちら",
        noteifyoudecidetoupgradeyourpackage: "注意：パッケージをアップグレードする場合、次回ログイン時にアップグレードされたパッケージが表示されます。",
        yourorderiscompleted: "ご注文が完了しました",
        turnyourchildreality: "子どもの想像を現実にする ",
      
     
        whychoose: "なぜ選ぶのか",
        ourplatformisdedicatedto: "私たちのプラットフォームは、個別のストーリーテリング体験を通じて家族の絆を強化することに専念しています。共有ストーリーテリングのプラットフォームを提供することで、親子の深い結びつきを促進し、家族が一緒に持続可能な思い出を作るお手伝いをします。子どもの興味や発達段階に合わせた没入型のストーリーは、教育的な関与を促進し、重要な概念や価値観を強化します。",
        ignitecreativity: "創造性を刺激する",
        empowerchildrenimagination: "魅力的な物語と鮮やかなイラストを通じて、子供の想像力と創造力を高めます。私たちのサービスは、創造的思考と問題解決能力を促進します。",
        connectondeeperlevel: "より深いレベルでつながる",
        fosteradeeperbond: "個別のストーリーテリング体験を通じて、親子の間により深い絆を育みます。私たちのプラットフォームは、家族が一緒に持続可能な思い出を作成するためのインタラクティブな要素を提供します。",
        funlearningadventures: "楽しい学びの冒険",
        leverageourservicestoprovide: "楽しく魅力的な方法で教育コンテンツを提供するために、私たちのサービスを活用してください。子どもたちは興味や発達段階に合わせた没入型のストーリーテリングを通じて貴重な教訓とスキルを学びます。",
        discoverwhyfamilies: "なぜ家族が私たちを信頼するのかを発見する",
        convenienceandsimplified: "便利で簡単なアクセス",
        saygoodbuytothehassle: "年齢に適した本を探したり、創造的なアイデアを考える手間を省きます。私たちのサービスは、指先で個別に適合した物語やイラストを提供します。",
        customizedlearningexperience: "カスタマイズされた学習体験",
        tailorstorytelling: "子どもの個々のニーズや興味に合わせてストーリーテリング体験を調整します。特定の学習コンセプトを強化したり、社会的感情的なテーマを扱ったりすることで、私たちのサービスは子どもの発達段階や学習スタイルに適応します。",
        parentalempowement: "親の力を高める",
        takeanactiverole: "私たちのツールとリソースを活用して、子どもの教育と成長に積極的に関与してください。共同のストーリーテリング体験を通じて、子どもの学習の旅を助け、関係を強化します。",
        timesavingsolution: "時間節約のソリューション",
        savetimebyeleminating: "教育資料の調査やキュレーションの必要性を排除することで、時間を節約します。私たちのプラットフォームを利用して、子どものニーズや興味に合わせた高品質で個別化されたコンテンツを提供してください。",
        experiencethemagic: "今日私たちと一緒に物語の魔法を体験してください。私たちの",
        pricingpage: "料金ページ",
        formoreinformationandstart: "詳細情報と家族との意義深い思い出を作成するために。",
        welcomebackpleaselogin: "お帰りなさい！続行するにはログインしてください。",
        password: "パスワード",
        donthaveanouaccount: "アカウントをお持ちでない場合、サインアップできます",
        forgotyourpassword: "パスワードをお忘れですか？リセットできます",
        welcome: "ようこそ",
        letscreateyouraccount: "アカウントを作成しましょう！",
        firstname: "名",
        lastname: "姓",
        termsandconditions: "利用規約",
        free: "無料",
        freeuser: "無料ユーザー",
        accesstogenerallibrary: "一般図書館と月間特集本へのアクセス",
        earlyaccesstonewfeature: "プロモーションへの早期アクセス — 新しい機能やお得な情報をいち早く発見",
        nocreditcardrequired: "クレジットカード不要 — ノーコミットメントで始める",
        signupnow: "今すぐサインアップ",
        generalandprivatelibraries: "一般およびプライベートライブラリへのアクセス — 毎月新しい特集ストーリーを探求します。",
        accesstonewaddedmonthlly: "毎月の新しい特集本へのアクセス — 毎月新しい物語を発見",
        questbook: "5冊のカスタムストーリーブック — 最大8ページ、完全にお子様の興味に合わせてカスタマイズ",
        odysseybook: "12冊のカスタムストーリーブック — 最大8ページ、完全にお子様の興味に合わせてカスタマイズ",
        enchantedbook: "29冊のカスタムストーリーブック — 最大8ページ、完全にお子様の興味に合わせてカスタマイズ",
        questbookannually: "60冊のカスタムストーリーブック — 最大8ページ、完全にお子様の興味に合わせてカスタマイズ",
        odysseybookannually: "145冊のカスタムストーリーブック — 最大8ページ、完全にお子様の興味に合わせてカスタマイズ",
        enchantedbookannually: "350冊のカスタムストーリーブック — 最大8ページ、完全にお子様の興味に合わせてカスタマイズ",
        forallpaidpackages: "すべての有料パッケージについて、チェックアウト時に年間請求を選択して",
        onemonthfree: "1か月無料！",
        title: "タイトル",
        creationdate: "作成日",
        action: "アクション",
        view: "表示",
        pleasebarewithus: "お待ちください。良いものには時間がかかります！",
        loading: "読み込み中",
        booksremaining: "残りの本：",
        gender: "性別：",
        booklength: "本の長さ：",
        agegroup: "年齢グループ：",
        lessonlearned: "学んだ教訓",
        morallesson: "道徳の授業",
        themes: "テーマ",
        visualstyle: "視覚スタイル：",
        Whatillustrationstyle: "どのイラストスタイルが魅力的だと思いますか？",
        characterarechetype: "キャラクターのアーキタイプ：",
        genre: "ジャンル：",
        plotelements: "プロット要素：",
        magicalelements: "魔法の要素：",
        cluturalcontext: "文化的な背景：",
        emotionaltone: "感情的なトーン：",
        environment: "環境：",
        specificpreferences: "特定の希望はありますか？さらにストーリーをカスタマイズします：",
        storycreatedsuccesfully: "ストーリーが作成され保存されました！",
        failedtocreatestory: "ストーリーの作成と保存に失敗しました！",
        toviewyourlibrary: "図書館を表示するには、クリックしてください",
        unfortunatellyyouhaveconsumed: "残念ながら、この月のすべての本を消費しました。パッケージをアップグレードしたい場合は、次をご覧ください",
        language: "言語:",
        english: "英語",
        arabic: "アラビア語",
        bulgarian: "ブルガリア語",
        chinese: "中国語",
        french: "フランス語",
        german: "ドイツ語",
        hindi: "ヒンディー語",
        korean: "韓国語",
        portuguese: "ポルトガル語",
        russian: "ロシア語",
        spanish: "スペイン語",
        dutch: "オランダ語",
        safeContent: 'コンテンツは安全で、子供に適していますか?',
        safeContentAnswer: 'はい、GWOW AIのコンテンツは安全で、子供に適しています。私たちは、AIによって生成されたすべてのストーリーとイラストが若いオーディエンスに適していることを確実にするために、細心の注意を払っています。私たちのプラットフォームでは、親がストーリーをカスタマイズできるため、コンテンツが特定の要求や好みに合致することを保証します。',
        supportedDevices: 'AI Interactive Storytellerはどのデバイスに対応していますか?',
        supportedDevicesAnswer: 'AI Interactive Storytellerは、スマートフォン、タブレット、コンピュータなど、すべての最新のデバイスに対応しています。ウェブサイトにアクセスするにはインターネット接続が必要ですが、オフラインでのアクセスのためにストーリーをローカルにダウンロードすることもできます。',
        globalSupport: '世界中でサポートされていますか?',
        globalSupportAnswer: 'はい、GWOW AIは世界中でサポートされています。ユーザーは12の異なる言語から選択してストーリーを作成し楽しむことができ、世界中の広範なオーディエンスにアクセス可能です。',
        freeTrial: '無料トライアルは利用できますか?',
        freeTrialAnswer: 'はい、無料トライアルがあります。一般ライブラリにアクセスするために無料ユーザーアカウントを作成できます。カスタマイズされたストーリーを作成するには、$7.99からのパッケージのいずれかにサブスクライブする必要があります。',
        dataCollection: 'どのデータが収集され、どのように使用されますか?',
        dataCollectionAnswer: '基本的なログイン情報を収集し、サービスを提供および改善します。この情報は安全に保持され、誰とも共有されず、販売されることはありません。',
        aiBooksCreativity: 'AI生成の本は、子供の創造性や学習にどのように役立ちますか?',
        aiBooksCreativityAnswer: 'AI生成の本は、パーソナライズされた魅力的なストーリーを提供することで、子供の創造性や学習を大いに向上させることができます。これらのストーリーは、子供の興味や学習ニーズに合わせて調整できるため、読書がよりインタラクティブで楽しい体験になります。さらに、親はPDFや塗り絵ブックとしてストーリーをダウンロードして、子供たちをさらに引き込むことができます。',

        faqAppropriateContent: "コンテンツは子供にとって安全で適切ですか？",
        faqSupportedDevices: "AI インタラクティブ ストーリーテラーはどのデバイスをサポートしていますか？",
        faqGlobalSupport: "世界中でサポートされていますか？",
        faqFreeTrial: "無料トライアルは利用できますか？",
        faqDataCollection: "どのようなデータが収集され、どのように使用されますか？",
        faqAIBooks: "AI によって生成された本は、どのようにして子供の創造力や学習を向上させることができますか？",
        answerAppropriateContent: "はい、GWOW AI のコンテンツは安全で子供に適しています。私たちは、AI によって生成されたすべてのストーリーとイラストが若い視聴者に適していることを確認するために細心の注意を払っています。私たちのプラットフォームでは、親がストーリーをカスタマイズできるため、コンテンツが親の具体的なニーズと好みに合うように保証しています。",
        answerSupportedDevices: "AI インタラクティブ ストーリーテラーは、スマートフォン、タブレット、コンピューターを含むすべての最新デバイスをサポートしています。サイトにアクセスするにはインターネット接続が必要ですが、オフラインで使用するためにストーリーをダウンロードすることもできます。",
        answerGlobalSupport: "はい、GWOW AI は世界中でサポートされています。ユーザーは 12 の異なる言語から選択してストーリーを作成し、楽しむことができるため、世界中の広いオーディエンスに利用可能です。",
        answerFreeTrial: "はい、無料トライアルが利用できます。一般的なライブラリにアクセスするには無料のユーザーアカウントを作成できます。カスタマイズされたストーリーを作成するには、4.99 ドルから始まるプランのいずれかにサインアップする必要があります。",
        answerDataCollection: "サービスの提供と改善のために基本的なログイン情報を収集します。この情報は安全に保管され、誰とも共有されることはなく、販売されることもありません。",
        answerAIBooks: "AI によって生成された本は、パーソナライズされた魅力的なストーリーを提供することで、子供の創造力や学習を大幅に向上させることができます。これらのストーリーは、子供の興味や学習ニーズに合わせて調整できるため、読書をよりインタラクティブで楽しい体験にします。さらに、親は PDF 形式でストーリーや塗り絵の本をダウンロードして、子供たちをさらに引き込むことができます。",
        learnmore: "詳細はこちら:",
        years: "年", selectgender: "性別を選択", male: "男性", female: "女性", unspecified: "未指定", selectbooklength: "本の長さを選択", pages: "ページ", selectmorallesson: "道徳の教訓を選択", friendship: "友情", kindness: "親切", courage: "勇気", honesty: "誠実", empathy: "共感", teamwork: "チームワーク", acceptance: "受け入れ", responsibility: "責任", creativity: "創造性", selectvisualstyle: "ビジュアルスタイルを選択", realistic: "リアリスティック", cartoon: "漫画", painterly: "画家風", anime: "アニメ", fantasy: "ファンタジー",
        watercolor: "水彩画",
        popart: "ポップアート" , selectgenre: "ジャンルを選択", adventure: "冒険", mystery: "ミステリー", sciencefiction: "SF", historicalfiction: "歴史フィクション", romance: "ロマンス", comedy: "コメディ", addatwist: "ツイストを追加", selectmagicalelement: "魔法の要素を選択", wizards: "魔法使い", mermaids: "人魚", dinosaurs: "恐竜", robots: "ロボット", cars: "車", pets: "ペット",
        dragons: "ドラゴン", aliens: "エイリアン", fairies: "妖精", superheroes: "スーパーヒーロー", pirates: "海賊", cowboys: "カウボーイ", secretworlds: "秘密の世界", magicalcreatures: "魔法の生き物", knights: "騎士", princesses: "プリンセス", otherspleasespecifybelow: "その他、以下のテキストエリアに指定してください", selectculturalcontext: "文化的なコンテキストを選択", ancientegypt: "古代エジプト", medievaleurope: "中世ヨーロッパ", feudaljapan: "封建時代の日本", victorianengland: "ビクトリア朝のイギリス", renaissanceitaly: "ルネサンス期のイタリア", nativeamericanfolklore: "ネイティブアメリカンの民間伝承", africanmythology: "アフリカの神話", asianlegends: "アジアの伝説", wildwest: "ワイルドウェスト", selectenvironment: "環境を選択", forest: "森", cityscape: "都市景観", countryside: "田舎", mountains: "山", beach: "ビーチ", underwaterkingdom: "水中王国", outerspace: "宇宙", enchantedforest: "魔法の森", magicalcastle: "魔法の城", desert: "砂漠", arctictundra: "北極のツンドラ", volcanicisland: "火山島", alienplanet: "異星",

        Superheroes: "スーパーヒーロー",
        VariousTales: "様々な物語",
        maincharacter: "主人公",

        animals: "動物",
        toys: "おもちゃ",
        mythicalcreatures: "神話の生き物",
        momentstogether: "一緒の時間",
        selectone: "一つ選ぶ",
        completeregistration: "登録を完了する",

        subscriptionsuccessful: "やった！サブスクリプションが成功し、新しいストーリークレジットを獲得しました！ 🎉 更新された残高を確認するには、ログアウトしてから再度ログインしてください。 さらなる冒険に備えましょう！",
        paymentissue: "おっと！お支払いが完了していないようです。カードやPayPalの詳細を確認し、もう一度お試しください。 問題が続く場合は、他の支払い方法をお試しください。楽しいことを見逃さないようにしてください！",
        underreview: "少々お待ちください！ご注文は安全確認のため審査中です。準備が整い次第お知らせします。 ご協力ありがとうございます—まもなくさらに多くの物語が届きます！",
        pendingpayment: "お支払いがこちらに向かっています！ 受領次第、ストーリークレジットが使用可能になります。 PayPalや銀行振込をご利用の場合は、すべてが承認されていることを確認して、再び冒険に戻りましょう。",


        selectagegroup: "年齢グループを選択",
        createstory: "ストーリーを作成",
        flipdeviceforbetterview: "より良い表示のためにデバイスを回転させてください",
        gobacktolibrary: "図書館に戻る",
        downloadbook: "本をダウンロード",
        downloadcoloringbook: "ぬりえをダウンロード",
        forsupportemailusat: "サポートが必要な場合は、メールでお問い合わせください ",
        chooselanguage: "言語を選択",
        currentsubscriptiondetails: "現在のサブスクリプションの詳細",
        packagename: "パッケージ名",
        enddate: "終了日",
        remainingbalance: "残高",
        emailaddress: "メールアドレス",
        renewsubscription: "サブスクリプションを更新",
        confirrenewsubscriptiondate: "今すぐサブスクリプションを更新してもよろしいですか？",
        ifyoudecidetoupgrade: "サブスクリプションを変更する場合、新しいサブスクリプションは今日から開始されます",
        yes: "はい",
        no: "いいえ",
        cancelsubscription: "サブスクリプションをキャンセル",
        confirmsubcancelation: "本当にサブスクリプションをキャンセルしますか？キャンセル後は、無料機能と一般的なライブラリのみ利用可能です",
        toupgradeyourpackage: "パッケージをアップグレードまたはダウングレードするには、次のいずれかを選択してください",
        somethingwrongcontactsupport: "問題が発生した可能性があります。サポートチームに連絡してください。",
        emailverification: "メールの確認",
        pleaselogin: "ログインしてください",
        downloadbookfeature: "ストーリーブックをダウンロード — オリジナルまたは塗り絵バージョンで、印刷可能",
        child: "子供",
        superhero: "スーパーヒーロー",
        animal: "動物",
        wizard: "魔法使い",
        fairy: "妖精",
        robot: "ロボット",
        fantasycharacters: "ファンタジーキャラクター",
        aboutus: "私たちに関しては",
        frequentlyaskedquestion: "よくある質問",
        howitworks: "どのように機能するか",
         languagedisclaimer: "利用規約は参照用に英語で表示されていることにご注意ください。私たちはコンテンツを複数の言語で提供するよう努めていますが、翻訳による不一致、誤解、または法的紛争が生じた場合は、常に英語版の利用規約が優先されます。この利用規約に同意することで、お客様の希望する言語でコンテンツを正確に解釈する責任があることを認めるものとします。法的紛争や曖昧さが発生した場合、英語版の利用規約が優先されます。質問がある場合や、明確にしたいことがある場合は、専門家に相談するか、直接お問い合わせください。",
        availablecurrences: "USD、EUR、GBP、JPY、AUDなど、100以上の現地通貨に対応！",
        ifyouhaveacouponyoucanredeemit: "クーポンをお持ちの場合は、引き換えることができます",
        ifthestoryreloads: "注意：フォームが再読み込みされた場合、画像が安全性チェックに合格しなかったことを意味します。入力を調整して再試行してください",
        youcanalsosendagift: "一度限りのギフトを購入して誰かに送ることもできます",
    },
    bg: {
        home: "Начало",
        pricing: "Ценообразуване",
        loginpage: "Вход",
        signup: "Регистрация",
        dashboard: "Табло",
        managesubscribtion: "Управление на абонамента",
        upgradetopremium: "Надстройка до премиум",
        logout: "Изход",
        chooseacategory: "Изберете категория",
        logingpage: "Страница за вход",
        youarenotloggedin: "В момента не сте влезли в системата, моля влезте от тук",
        resetpassword: "Нулиране на паролата",
        enteremailtoresetpassowrd: "Въведете вашия имейл, за да нулирате паролата си",
        email: "Имейл",
        submit: "Изпрати",
        enteryournewpassword: "Въведете новата си парола",
        newpassword: "Нова парола",
        confirmnewpassword: "Потвърдете новата парола",
        gotologin: "Към входа",
        generallibrary: "Обща библиотека",
        yourlibrary: "Вашата библиотека",
        createnewbook: "Създайте нова книга",
        youarecurrentlynotloggedin: "В момента не сте влезли в системата, моля влезте от тук",
        nowpleaseconfirmwhichpackage: "Сега потвърдете кой пакет бихте желали да закупите. В момента използвате този имейл адрес:",
        onceyouclickonthebuybutton: "След като кликнете на бутона 'Купи сега' по-долу, ще бъдете пренасочени към нашия уповерен доставчик FastSpring, където ще можете да финализирате детайлите за плащане. Моля, уверете се, че използвате същия имейл адрес",
        chooseapackage: "Изберете пакет",
        selectyourpackage: "Изберете вашия пакет",
        monthly: "Месечен",
        yearly: "Годишен",
        buynow: "Купи сега",
        thankyouforchoosingpackage: "Благодарим ви, че избрахте своя пакет. След като финализирате данните за плащане, моля, уверете се, че сте влезли отново, за да актуализирате баланса си. Ще можете да създавате свои собствени книги и да имате достъп до личната си библиотека.",
        youarecurrentlysubscribed: "В момента сте абонирани за",
        pleaseuseyourregisteredemail: "Пакет. Моля, използвайте вашия регистриран имейл адрес:",
        tomanageyouraccount: "за управление на вашия профил. Можете да управлявате вашия абонамент",
        here: "тук",
        noteifyoudecidetoupgradeyourpackage: "Забележка: Ако решите да надградите вашия пакет, надграденият пакет ще се покаже при следващото ви влизане.",
        yourorderiscompleted: "вашият поръчка е завършена",
        turnyourchildreality: "Превърнете въображението на вашето дете в реалност с ",
       
     
        whychoose: "Защо да изберете",
        ourplatformisdedicatedto: "Нашата платформа е посветена на укрепване на семейните връзки чрез персонализирани изживявания с разказване на истории. Предоставяйки платформа за споделено разказване на истории, ние създаваме по-дълбоки връзки между родители и деца, позволявайки на семействата да създадат трайни спомени заедно. Нашите поглъщащи истории, насочени към интересите и развойните етапи на децата, насърчават образователното ангажиране, като подсилват важни понятия и ценности",
        ignitecreativity: "Запали креативността",
        empowerchildrenimagination: "Усилете въображението и креативността на децата чрез ангажиращи разкази и живописни илюстрации. Нашата услуга насърчава креативното мислене и уменията за решаване на проблеми.",
        connectondeeperlevel: "Свързване на по-дълбоко ниво",
        fosteradeeperbond: "Подкрепете по-дълбока връзка между родители и деца чрез персонализирани изживявания с разказване на истории. Нашата платформа предлага интерактивни елементи, които позволяват на семействата да създават трайни спомени заедно.",
        funlearningadventures: "Забавни обучителни приключения",
        leverageourservicestoprovide: "Използвайте нашата услуга, за да предоставяте образователно съдържание по забавен и ангажиращ начин. Децата учат ценни уроци и умения чрез поглъщащо разказване на истории, насочено към техните интереси и развойна стадия.",
        discoverwhyfamilies: "Открийте защо семействата ни вярват",
        convenienceandsimplified: "Удобство и опростен достъп",
        saygoodbuytothehassle: "Кажете сбогом на проблемите с търсенето на подходящи за възрастта книги или борбата да намерите креативни идеи. Нашата услуга предоставя персонализирани истории и илюстрации на вашето разположение.",
        customizedlearningexperience: "Персонализирани образователни изживявания:",
        tailorstorytelling: "Персонализирайте изживяванията с разказване на истории според индивидуалните нужди и интереси на вашето дете. Независимо дали сте в процес на засилване на конкретни учебни концепции или се справяте с социално-емоционални теми, нашата услуга се адаптира към развойната стадия и учебния стил на вашето дете.",
        parentalempowement: "Придобиване на родителска власт",
        takeanactiverole: "Вземете активно участие в образованието и растежа на вашето дете с нашите инструменти и ресурси. Засилете учебния път на вашето дете и укрепете връзката си чрез колаборативни изживявания с разказване на истории.",
        timesavingsolution: "Решение за спестяване на време",
        savetimebyeleminating: "Избегнете загубата на време за търсене и подбор на образователни материали. Ние предоставяме висококачествено, персонализирано съдържание, което отговаря на нуждите и интересите на вашето дете.",
        experiencethemagic: "Изживейте магията на разказването на истории с нас днес. Вижте нашите",
        pricingpage: "Страница с ценообразуване",
        formoreinformationandstart: "за повече информация и започване на създаване на значими спомени с вашето семейство.",
        welcomebackpleaselogin: "Добре дошли отново! Моля, влезте в профила си, за да продължите.",
        password: "Парола",
        donthaveanouaccount: "Нямате профил? Можете да се регистрирате ",
        forgotyourpassword: "Забравена парола? Можете да я нулирате",
        welcome: "Добре дошли",
        letscreateyouraccount: "Нека създадем вашия профил!",
        firstname: "Име",
        lastname: "Фамилия",
        termsandconditions: "Условия и условия",
        free: "Безплатно",
        freeuser: "Безплатен потребител",
        accesstogenerallibrary: "Достъп до Общата Библиотека и месечно препоръчани книги",
        earlyaccesstonewfeature: "Ранен достъп до промоции — Бъдете първият, който открива нови функции и оферти",
        nocreditcardrequired: "Не е необходима кредитна карта — Започнете без ангажименти",
        signupnow: "Регистрирайте се сега",
        generalandprivatelibraries: "Достъп до Общи и Частни Библиотеки — Открийте нови избрани истории всеки месец",
        accesstonewaddedmonthlly: "Месечен достъп до нови книги — Свежи истории всеки месец",
        questbook: "5 Персонализирани Книги — До 8 страници, напълно персонализирани за вашето дете",
        odysseybook: "12 Персонализирани Книги — До 8 страници, напълно персонализирани за вашето дете",
        enchantedbook: "29 Персонализирани Книги — До 8 страници, напълно персонализирани за вашето дете",
        questbookannually: "60 Персонализирани Книги — До 8 страници, напълно персонализирани за вашето дете",
        odysseybookannually: "145 Персонализирани Книги — До 8 страници, напълно персонализирани за вашето дете",
        enchantedbookannually: "350 Персонализирани Книги — До 8 страници, напълно персонализирани за вашето дете",
        forallpaidpackages: "За всички платени пакети, при плащане може да изберете да бъдете фактурирани годишно и да получите ",
        onemonthfree: "един месец безплатно!",
        title: "Заглавие",
        creationdate: "Дата на създаване",
        action: "Действие",
        view: "Преглед",
        pleasebarewithus: "Моля, бъдете търпеливи, добрите неща отнемат време!",
        loading: "Зареждане",
        booksremaining: "Оставащи книги:",
        gender: "Пол:",
        booklength: "Дължина на книгата:",
        agegroup: "Възрастова група:",
        lessonlearned: "Извлечени уроци",
        morallesson: "Морална Урок",
        themes: "Теми",
        visualstyle: "Визуален стил:",
        Whatillustrationstyle: "Кой стил на илюстрациите намирате за привлекателен?",
        characterarechetype: "Архетипове на героите: ",
        genre: "Жанр:",
        plotelements: "Елементи на сюжета:",
        magicalelements: "Магически елементи:",
        cluturalcontext: "Културен контекст:",
        emotionaltone: "Емоционален тон:",
        environment: "Околна среда:",
        specificpreferences: "Конкретни предпочитания? Персонализирайте историята си още повече:",
        storycreatedsuccesfully: "Историята е създадена и запазена успешно!",
        failedtocreatestory: "Неуспешно създаване и запазване на историята!",
        toviewyourlibrary: "За да видите вашата библиотека, кликнете",
        unfortunatellyyouhaveconsumed: "За съжаление, изчерпахте всичките си книги за месеца. Ако искате да ъпгрейднете пакета си, моля, посетете ",
        language: "Език:",
        english: "Английски",
        arabic: "Арабски",
        bulgarian: "Български",
        chinese: "Китайски",
        french: "Френски",
        german: "Немски",
        hindi: "Хинди",
        korean: "Корейски",
        portuguese: "Португалски",
        russian: "Руски",
        spanish: "Испански",
        dutch: "Холандски",
        safeContent: 'Съдържанието безопасно ли е и подходящо ли е за деца?',
        safeContentAnswer: 'Да, съдържанието в GWOW AI е безопасно и подходящо за деца. Ние полагаме големи усилия, за да гарантираме, че всички истории и илюстрации, генерирани от нашия ИИ, са подходящи за млади аудитории. Нашата платформа позволява на родителите да персонализират истории, като се уверяват, че съдържанието отговаря на техните конкретни изисквания и предпочитания.',
        supportedDevices: 'Какви устройства са съвместими с AI Interactive Storyteller?',
        supportedDevicesAnswer: 'AI Interactive Storyteller поддържа всички модерни устройства, включително смартфони, таблети и компютри. Нужна е интернет връзка, за да получите достъп до сайта, но можете също така да изтеглите истории локално за офлайн достъп.',
        globalSupport: 'Поддържа ли се глобално?',
        globalSupportAnswer: 'Да, GWOW AI се поддържа глобално. Потребителите могат да избират от 12 различни езика, за да създават и се наслаждават на своите истории, което го прави достъпно за широка аудитория по целия свят.',
        freeTrial: 'Има ли наличен безплатен пробен период?',
        freeTrialAnswer: 'Да, има наличен безплатен пробен период. Можете да създадете безплатен потребителски акаунт, за да получите достъп до основната библиотека. За да създадете персонализирани истории, ще трябва да се абонирате за един от нашите пакети, започващи от $7.99.',
        dataCollection: 'Какви данни се събират и как се използват?',
        dataCollectionAnswer: 'Събираме основна информация за вход, за да предоставим и подобрим нашите услуги. Тази информация се съхранява безопасно, не се споделя с никого и няма да бъде продавана.',
        aiBooksCreativity: 'Как могат книгите, генерирани от ИИ, да подобрят креативността и обучението на моето дете?',
        aiBooksCreativityAnswer: 'Книгите, генерирани от ИИ, могат значително да подобрят креативността и обучението на вашето дете, като предоставят персонализирани и ангажиращи истории. Тези истории могат да бъдат адаптирани към интересите и нуждите от обучение на вашето дете, което прави четенето по-интерактивно и приятно. Освен това, родителите могат да изтеглят истории като PDF файлове или книги за оцветяване, за да ангажират още повече децата си.',

        faqAppropriateContent: "Съдържанието безопасно и подходящо ли е за деца?",
        faqSupportedDevices: "Какви устройства се поддържат от интерактивния разказвач с ИИ?",
        faqGlobalSupport: "Поддържа ли се глобално?",
        faqFreeTrial: "Има ли налична безплатна пробна версия?",
        faqDataCollection: "Какви данни се събират и как се използват?",
        faqAIBooks: "Как AI генерираните книги могат да подобрят креативността и ученето на детето ми?",
        answerAppropriateContent: "Да, съдържанието на GWOW AI е безопасно и подходящо за деца. Ние се грижим да гарантираме, че всички истории и илюстрации, генерирани от нашия ИИ, са подходящи за млади зрители. Нашата платформа позволява на родителите да персонализират историите, за да осигури съдържание, което отговаря на техните специфични нужди и предпочитания.",
        answerSupportedDevices: "Интерактивният разказвач с ИИ поддържа всички съвременни устройства, включително смартфони, таблети и компютри. Необходимо е интернет свързване, за да получите достъп до сайта, но също така можете да изтегляте истории за офлайн достъп.",
        answerGlobalSupport: "Да, GWOW AI се поддържа глобално. Потребителите могат да избират от 12 различни езика за създаване и наслаждаване на своите истории, което го прави достъпно за широка аудитория по целия свят.",
        answerFreeTrial: "Да, налична е безплатна пробна версия. Можете да създадете безплатен потребителски акаунт, за да получите достъп до обществената библиотека. За създаване на персонализирани истории ще трябва да се абонирате за някой от нашите планове, започващи от $4.99.",
        answerDataCollection: "Събираме основна информация за вход, за да предоставим и подобрим нашите услуги. Тази информация се съхранява безопасно, не се споделя с никого и не се продава.",
        answerAIBooks: "Книгите, генерирани от ИИ, могат значително да подобрят креативността и ученето на вашето дете, предоставяйки персонализирани и ангажиращи истории. Тези истории могат да бъдат адаптирани към интересите и нуждите на вашето дете, като правят четенето по-интерактивно и приятно. Освен това родителите могат да изтеглят истории във формат PDF или книги за оцветяване, за да ангажират децата си още повече.",
        learnmore: "Научете повече:",
        years: "години", selectgender: "Изберете пол", male: "Мъжки", female: "Женски", unspecified: "Неопределен", selectbooklength: "Изберете дължина на книгата", pages: "страници", selectmorallesson: "Изберете морален урок", friendship: "Приятелство", kindness: "Доброта", courage: "Смелост", honesty: "Честност", empathy: "Емпатия", teamwork: "Работа в екип", acceptance: "Приемане", responsibility: "Отговорност", creativity: "Креативност", selectvisualstyle: "Изберете визуален стил", realistic: "Реалистичен", cartoon: "Карикатура", painterly: "Живописен", anime: "Аниме", fantasy: "Фентъзи",
        watercolor: "Акварел",
        popart: "Поп арт",  selectgenre: "Изберете жанр", adventure: "Приключение", mystery: "Мистерия",  sciencefiction: "Научна фантастика", historicalfiction: "Историческа фикция", romance: "Романтика", comedy: "Комедия", addatwist: "Добавете обрат", selectmagicalelement: "Изберете магически елемент", wizards: "Магьосници", mermaids: "Русалки", dinosaurs: "Динозаври", robots: "Роботи", cars: "Коли", pets: "Домашни любимци",
        dragons: "Дракони", aliens: "Извънземни", fairies: "Феи", superheroes: "Супергерои", pirates: "Пирати", cowboys: "Каубои", secretworlds: "Тайни светове", magicalcreatures: "Магически същества", knights: "Рицари", princesses: "Принцеси", otherspleasespecifybelow: "Други, моля посочете в текстовата област по-долу", selectculturalcontext: "Изберете културен контекст", ancientegypt: "Древен Египет", medievaleurope: "Средновековна Европа", feudaljapan: "Феодална Япония", victorianengland: "Викторианска Англия", renaissanceitaly: "Ренесансова Италия", nativeamericanfolklore: "Фолклор на коренните американци", africanmythology: "Африканска митология", asianlegends: "Азиатски легенди", wildwest: "Дивия Запад", selectenvironment: "Изберете среда", forest: "Гора", cityscape: "Градски пейзаж", countryside: "Селска област", mountains: "Планини", beach: "Плаж", underwaterkingdom: "Подводно кралство", outerspace: "Космос", enchantedforest: "Зачарована гора", magicalcastle: "Магически замък", desert: "Пустиня", arctictundra: "Арктическа тундра", volcanicisland: "Вулканичен остров", alienplanet: "Извънземна планета",
        Superheroes: "Супергерои",
        VariousTales: "Различни приказки",
        maincharacter: "Главен Персонаж",
        animals: "Животни",
        toys: "Играчки",
        mythicalcreatures: "Митични същества",
        momentstogether: "Моменти заедно",
        selectone: "Избери Едно",
        completeregistration: "Завършете регистрацията",

        subscriptionsuccessful: "Ура! Абонаментът ви беше успешен и имате нови кредити за истории! 🎉 За да видите актуализирания си баланс, моля, излезте и влезте отново. Пригответе се за още забавни приключения!",
        paymentissue: "Опа! Изглежда, че плащането ви не е минало. Моля, проверете данните на картата или PayPal и опитайте отново. Ако продължи да се случва, пробвайте друг метод на плащане, за да не пропуснете забавлението!",
        underreview: "Задръжте малко! Поръчката ви се преглежда, за да се уверим, че всичко е наред. Ще ви уведомим веднага щом бъде готово. Благодарим за търпението—още истории скоро идват по вашия път!",
        pendingpayment: "Вашето плащане е на път към нас! Щом го получим, кредитите ви за истории ще бъдат готови за ползване. Ако използвате PayPal или банков превод, уверете се, че всичко е одобрено, за да се върнете към приключението.",


        selectagegroup: "Изберете възрастова група",
        createstory: "Създай история",
        flipdeviceforbetterview: "Завъртете устройството за по-добра гледка",
        gobacktolibrary: "Върни се в библиотеката",
        downloadbook: "Изтегли книга",
        downloadcoloringbook: "Изтегли книжка за оцветяване",
        forsupportemailusat: "За поддръжка ни пишете на имейл ",
        chooselanguage: "Изберете език",
        currentsubscriptiondetails: "Текущи данни за абонамента",
        packagename: "Име на пакета",
        enddate: "Крайна дата",
        remainingbalance: "Оставащ баланс",
        emailaddress: "Имейл адрес",
        renewsubscription: "Поднови абонамента",
        confirrenewsubscriptiondate: "Сигурни ли сте, че искате да подновите абонамента си сега?",
        ifyoudecidetoupgrade: "Ако решите да смените абонамента си, новият ви абонамент ще започне днес",
        yes: "Да",
        no: "Не",
        cancelsubscription: "Отмени абонамента",
        confirmsubcancelation: "Сигурни ли сте, че искате да отмените абонамента си? След отмяната ще имате достъп само до безплатни функции и общи библиотеки",
        toupgradeyourpackage: "За да надградите или понижите пакета си, изберете едно от следните",
        somethingwrongcontactsupport: "Възможно е да има проблем. Свържете се с екипа за поддръжка за помощ.",
        emailverification: "Потвърждение на имейл",
        pleaselogin: "Моля, влезте",
        downloadbookfeature: "Свалете книги — Налични в оригинална или оцветяваща версия, готови за печат",
        child: "Дете",
        superhero: "Супергерой",
        animal: "Животно",
        wizard: "Магьосник",
        fairy: "Фея",
        robot: "Робот",
        fantasycharacters: "Фантастични Персонажи",
        aboutus: "За Нас",
        frequentlyaskedquestion: "Често Задавани Въпроси",
        howitworks: "Как работи",
 languagedisclaimer: "Моля, имайте предвид, че Общите условия са показани на английски език за справка. Въпреки че се стремим да направим нашето съдържание достъпно на няколко езика, английската версия на тези Общи условия винаги ще има предимство в случай на несъответствия, недоразумения или правни спорове, произтичащи от преводите. Съгласявайки се с тези Общи условия, вие потвърждавате, че носите отговорност за точното тълкуване на съдържанието на предпочитания от вас език. В случай на правни спорове или двусмислие, английската версия на Общите условия ще има предимство. Ако имате въпроси или имате нужда от разяснения, насърчаваме ви да потърсите професионален съвет или да се свържете директно с нас.",
        availablecurrences: "Поддържаме над 100 местни валути, включително USD, EUR, GBP, JPY, AUD и други!",
        ifyouhaveacouponyoucanredeemit: "Ако имате купон, можете да го използвате",
        ifthestoryreloads: "Забележка: Ако формулярът се презареди, това означава, че изображенията не са преминали нашите проверки за безопасност. Моля, коригирайте вашите данни и опитайте отново",
        youcanalsosendagift: "Можете също да закупите еднократен подарък и да го изпратите на някого",
    },

    ko: {
        home: "홈",
        pricing: "가격",
        loginpage: "로그인",
        signup: "가입",
        dashboard: "대시보드",
        managesubscribtion: "구독 관리",
        upgradetopremium: "프리미엄 업그레이드",
        logout: "로그아웃",
        chooseacategory: "카테고리 선택",
        logingpage: "로그인 페이지",
        youarenotloggedin: "현재 로그인되어 있지 않습니다. 여기에서 로그인하세요.",
        resetpassword: "비밀번호 재설정",
        enteremailtoresetpassowrd: "이메일을 입력하여 비밀번호를 재설정하세요.",
        email: "이메일",
        submit: "제출",
        enteryournewpassword: "새 비밀번호를 입력하세요.",
        newpassword: "새 비밀번호",
        confirmnewpassword: "새 비밀번호 확인",
        gotologin: "로그인하러 가기",
        generallibrary: "일반 도서관",
        yourlibrary: "내 서재",
        createnewbook: "새 책 생성",
        youarecurrentlynotloggedin: "현재 로그인되어 있지 않습니다. 여기에서 로그인하세요.",
        nowpleaseconfirmwhichpackage: "지금 구매하고자 하는 패키지를 확인하세요. 현재 이 이메일 주소를 사용하고 있습니다:",
        onceyouclickonthebuybutton: "'지금 구매하기' 버튼을 클릭하면 아래의 '지금 구매하기' 버튼을 클릭하면 FastSpring이라는 신뢰할 수 있는 공급 업체로 이동되어 결제 세부 정보를 최종 확인할 수 있습니다. 동일한 이메일 주소를 사용하십시오.",
        chooseapackage: "패키지 선택:",
        selectyourpackage: "패키지 선택",
        monthly: "월간",
        yearly: "연간",
        buynow: "지금 구매",
        thankyouforchoosingpackage: "패키지를 선택해 주셔서 감사합니다. 결제 세부 정보를 완료한 후 잔액을 업데이트하려면 다시 로그인하십시오. 개인 도서관에 접근하고 책을 만들 수 있습니다.",
        youarecurrentlysubscribed: "현재 다음에 구독 중입니다",
        pleaseuseyourregisteredemail: "패키지. 등록된 이메일 주소를 사용하십시오:",
        tomanageyouraccount: "계정 관리하기. 구독 관리할 수 있습니다",
        here: "여기",
        noteifyoudecidetoupgradeyourpackage: "참고: 패키지를 업그레이드하려고 결정하면 업그레이드된 패키지가 다음 로그인 시에 표시됩니다.",
        yourorderiscompleted: "주문이 완료되었습니다.",
        turnyourchildreality: "자녀의 상상을 현실로 변환하세요 ",
      
    
        whychoose: "선택 이유",
        ourplatformisdedicatedto: "우리 플랫폼은 가족의 유대를 강화하기 위해 맞춤형 스토리텔링 경험을 제공하는 데 전념하고 있습니다. 공유 스토리텔링을 위한 플랫폼을 제공함으로써 부모와 자녀 사이의 깊은 연결을 촉진하며, 가족들이 오래 남을 추억을 함께 만들 수 있도록 지원합니다. 우리의 몰입형 이야기는 아동의 관심과 발달 단계에 맞춰 맞춤화되어 교육적 참여를 촉진하면서 중요한 개념과 가치를 강화합니다.",
        ignitecreativity: "창의력을 불러일으키다",
        empowerchildrenimagination: "아동의 상상력과 창의력을 이야기를 통해 촉진하고 생생한 일러스트로 활기를 불어넣습니다. 우리의 서비스는 창의적 사고와 문제 해결 능력을 증진시킵니다.",
        connectondeeperlevel: "더 깊은 연결",
        fosteradeeperbond: "맞춤형 스토리텔링 경험을 통해 부모와 자녀 사이의 더 깊은 유대감을 육성합니다. 우리 플랫폼은 가족이 함께 오래 지속될 추억을 만들 수 있도록 상호 작용 요소를 제공합니다.",
        funlearningadventures: "재미있는 학습 모험",
        leverageourservicestoprovide: "우리의 서비스를 활용하여 재미있고 매료되는 방식으로 교육 콘텐츠를 제공합니다. 아동들은 그들의 관심과 발달 단계에 맞춘 몰입형 이야기를 통해 중요한 교훈과 기술을 배우게 됩니다.",
        discoverwhyfamilies: "가족들이 우리를 신뢰하는 이유를 발견하세요",
        convenienceandsimplified: "편리하고 간소화된 접근",
        saygoodbuytothehassle: "연령에 맞는 책을 찾거나 창의적 아이디어를 찾는 데 드는 번거로움을 고사하십시오. 우리의 서비스는 손쉽게 맞춤형 이야기와 일러스트를 제공합니다.",
        customizedlearningexperience: "맞춤형 학습 경험",
        tailorstorytelling: "아동의 개별적인 필요와 관심에 맞춰 이야기를 맞춤화합니다. 특정 학습 개념을 강화하거나 사회-정서적 주제를 다루는 경우, 우리의 서비스는 아동의 발달 단계와 학습 스타일에 적응합니다.",
        parentalempowement: "부모의 권한 부여",
        takeanactiverole: "우리의 도구와 자원을 통해 자녀의 교육과 성장에 적극적으로 참여하세요. 협력적인 스토리텔링 경험을 통해 자녀의 학습 여정을 촉진하고 귀하의 관계를 강화하세요.",
        timesavingsolution: "시간 절약 솔루션",
        savetimebyeleminating: "교육 자료를 조사하고 선별하는 번거로움을 줄이고 시간을 절약하세요. 귀하의 자녀의 필요와 관심에 맞춘 고품질 맞춤형 콘텐츠를 제공하는 우리의 플랫폼을 신뢰하세요.",
        experiencethemagic: "오늘 우리와 함께 이야기의 마법을 경험해보세요. 우리의",
        pricingpage: "가격 페이지",
        formoreinformationandstart: "더 많은 정보를 원하시거나 가족과 함께 의미 있는 추억을 만드는 방법을 시작하려면 여기를 클릭하세요.",
        welcomebackpleaselogin: "다시 오신 것을 환영합니다! 계속하려면 로그인하세요.",
        password: "비밀번호",
        donthaveanouaccount: "계정이 없으신가요? 회원 가입하실 수 있습니다 ",
        forgotyourpassword: "비밀번호를 잊으셨나요? 재설정할 수 있습니다",
        welcome: "환영합니다",
        letscreateyouraccount: "계정을 만들어 보세요!",
        firstname: "이름",
        lastname: "성",
        termsandconditions: "이용 약관",
        free: "무료",
        freeuser: "무료 사용자",
        accesstogenerallibrary: "일반 도서관 및 매월 추천 도서 접근",
        earlyaccesstonewfeature: "프로모션 조기 접근 — 새로운 기능과 할인 혜택을 가장 먼저 발견하세요",
        nocreditcardrequired: "신용카드 필요 없음 — 부담 없이 시작하세요",
        signupnow: "지금 등록하기",
        generalandprivatelibraries: "일반 및 개인 도서관 이용 — 매월 새로운 추천 이야기를 탐험하세요",
        accesstonewaddedmonthlly: "매월 새로운 추천 도서 접근 — 매달 새로운 이야기를 발견하세요",
        questbook: "5개의 맞춤형 스토리북 — 최대 8페이지, 자녀의 관심사에 맞게 완전히 개인화됨",
        odysseybook: "12개의 맞춤형 스토리북 — 최대 8페이지, 자녀의 관심사에 맞게 완전히 개인화됨",
        enchantedbook: "29개의 맞춤형 스토리북 — 최대 8페이지, 자녀의 관심사에 맞게 완전히 개인화됨",
        questbookannually: "60개의 맞춤형 스토리북 — 최대 8페이지, 자녀의 관심사에 맞게 완전히 개인화됨",
        odysseybookannually: "145개의 맞춤형 스토리북 — 최대 8페이지, 자녀의 관심사에 맞게 완전히 개인화됨",
        enchantedbookannually: "350개의 맞춤형 스토리북 — 최대 8페이지, 자녀의 관심사에 맞게 완전히 개인화됨",
        forallpaidpackages: "모든 유료 패키지에 대해 결제 시 연간 청구를 선택하고 ",
        onemonthfree: "한 달 무료!",
        title: "제목",
        creationdate: "작성 날짜",
        action: "동작",
        view: "보기",
        pleasebarewithus: "잠시 기다려 주세요. 좋은 것들에는 시간이 필요합니다!",
        loading: "로딩 중",
        booksremaining: "남은 책:",
        gender: "성별:",
        booklength: "책 길이:",
        agegroup: "연령 그룹:",
        lessonlearned: "배운 교훈",
        morallesson: "도덕 수업",
        themes: "테마",
        visualstyle: "시각 스타일:",
        Whatillustrationstyle: "어떤 일러스트 스타일이 매력적이라고 생각하십니까?",
        characterarechetype: "캐릭터 아처타입:",
        genre: "장르:",
        plotelements: "플롯 요소:",
        magicalelements: "마법적 요소:",
        cluturalcontext: "문화적 맥락:",
        emotionaltone: "감정적 톤:",
        environment: "환경:",
        specificpreferences: "특정한 선호 사항이 있으신가요? 이야기를 더 맞춤화하세요:",
        storycreatedsuccesfully: "이야기가 성공적으로 생성 및 저장되었습니다!",
        failedtocreatestory: "이야기 생성 및 저장에 실패했습니다!",
        toviewyourlibrary: "서재를 보려면 클릭하세요",
        unfortunatellyyouhaveconsumed: "죄송하지만 이번 달 모든 책을 다 사용하셨습니다. 패키지를 업그레이드하려면 방문하십시오 ",
        language: "언어:",
        english: "영어",
        arabic: "아랍어",
        bulgarian: "불가리아어",
        chinese: "중국어",
        french: "프랑스어",
        german: "독일어",
        hindi: "힌디어",
        korean: "한국어",
        portuguese: "포르투갈어",
        russian: "러시아어",
        spanish: "스페인어",
        dutch: "네덜란드어",
        safeContent: '컨텐츠가 안전하고 연령에 적합한가요?',
        safeContentAnswer: '네, GWOW AI의 컨텐츠는 안전하고 어린이에게 적합합니다. 우리의 AI가 생성하는 모든 이야기와 일러스트가 어린 연령대의 관객에게 적합하도록 세심하게 주의를 기울이고 있습니다. 우리의 플랫폼은 부모가 이야기를 맞춤 설정할 수 있도록 하여, 컨텐츠가 특정 요구 사항과 선호도에 맞도록 보장합니다.',
        supportedDevices: 'AI Interactive Storyteller가 지원하는 장치는 무엇인가요?',
        supportedDevicesAnswer: 'AI Interactive Storyteller는 스마트폰, 태블릿 및 컴퓨터를 포함한 모든 최신 장치를 지원합니다. 웹사이트에 접근하려면 인터넷 연결이 필요하지만, 오프라인 액세스를 위해 이야기를 로컬로 다운로드할 수도 있습니다.',
        globalSupport: '전 세계적으로 지원되나요?',
        globalSupportAnswer: '네, GWOW AI는 전 세계적으로 지원됩니다. 사용자는 12가지 언어 중에서 선택하여 자신의 이야기를 만들고 즐길 수 있어, 전 세계의 넓은 청중에게 접근할 수 있습니다.',
        freeTrial: '무료 체험이 가능한가요?',
        freeTrialAnswer: '네, 무료 체험이 가능합니다. 일반 라이브러리에 접근하기 위해 무료 사용자 계정을 생성할 수 있습니다. 맞춤형 이야기를 만들기 위해서는 $7.99부터 시작하는 패키지 중 하나에 구독해야 합니다.',
        dataCollection: '어떤 데이터가 수집되며 어떻게 사용되나요?',
        dataCollectionAnswer: '우리는 서비스를 제공하고 개선하기 위해 기본 로그인 정보를 수집합니다. 이 정보는 안전하게 보관되며, 누구와도 공유되지 않고 판매되지 않습니다.',
        aiBooksCreativity: 'AI 생성 책이 자녀의 창의성과 학습을 어떻게 향상시킬 수 있나요?',
        aiBooksCreativityAnswer: 'AI가 생성한 책은 개인화된 매력적인 이야기를 제공함으로써 자녀의 창의성과 학습을 크게 향상시킬 수 있습니다. 이러한 이야기는 자녀의 관심사와 학습 요구에 맞게 조정될 수 있어 독서를 더 인터랙티브하고 즐거운 경험으로 만듭니다. 또한 부모는 PDF나 색칠 책으로 이야기를 다운로드하여 자녀를 더 많이 참여시킬 수 있습니다.',

        faqAppropriateContent: "콘텐츠가 안전하고 어린이에게 적합한가요?",
        faqSupportedDevices: "AI 인터랙티브 스토리텔러는 어떤 기기를 지원하나요?",
        faqGlobalSupport: "전 세계적으로 지원되나요?",
        faqFreeTrial: "무료 체험이 가능한가요?",
        faqDataCollection: "어떤 데이터가 수집되며 어떻게 사용되나요?",
        faqAIBooks: "AI가 생성한 책이 자녀의 창의성과 학습에 어떻게 도움이 되나요?",
        answerAppropriateContent: "네, GWOW AI의 콘텐츠는 안전하고 어린이에게 적합합니다. 우리는 모든 AI 생성 이야기와 그림이 어린이들에게 적합하도록 신경을 쓰고 있습니다. 부모님이 이야기를 맞춤 설정할 수 있어 콘텐츠가 부모님의 특정 요구 사항과 선호도에 맞도록 보장됩니다.",
        answerSupportedDevices: "AI 인터랙티브 스토리텔러는 스마트폰, 태블릿, 컴퓨터를 포함한 모든 최신 기기를 지원합니다. 웹사이트에 접근하려면 인터넷 연결이 필요하지만, 오프라인 상태에서도 이야기를 다운로드하여 사용할 수 있습니다.",
        answerGlobalSupport: "네, GWOW AI는 전 세계적으로 지원됩니다. 사용자는 12개의 다양한 언어 중에서 선택하여 이야기를 만들고 즐길 수 있어 전 세계 많은 사용자에게 접근할 수 있습니다.",
        answerFreeTrial: "네, 무료 체험이 가능합니다. 무료 사용자 계정을 생성하여 공개 라이브러리에 접근할 수 있습니다. 맞춤형 이야기를 만들기 위해서는 4.99 달러부터 시작하는 요금제 중 하나를 구독해야 합니다.",
        answerDataCollection: "서비스를 제공하고 개선하기 위해 기본 로그인 정보를 수집합니다. 이 정보는 안전하게 보관되며, 누구와도 공유되지 않고 판매되지 않습니다.",
        answerAIBooks: "AI가 생성한 책은 개인화되고 매력적인 이야기를 제공함으로써 자녀의 창의성과 학습을 크게 향상시킬 수 있습니다. 이러한 이야기는 자녀의 관심사와 학습 요구에 맞게 조정할 수 있어 독서가 더 인터랙티브하고 즐거운 경험이 됩니다. 또한 부모님은 PDF 형식의 이야기나 색칠하기 책을 다운로드하여 자녀를 더욱 참여시킬 수 있습니다.",
        learnmore: "자세히 알아보기:",
        years: "년", selectgender: "성별 선택", male: "남성", female: "여성", unspecified: "지정되지 않음", selectbooklength: "책 길이 선택", pages: "페이지", selectmorallesson: "도덕적 교훈 선택", friendship: "우정", kindness: "친절", courage: "용기", honesty: "정직", empathy: "공감", teamwork: "팀워크", acceptance: "수용", responsibility: "책임", creativity: "창의성", selectvisualstyle: "시각적 스타일 선택", realistic: "사실적인", cartoon: "만화", painterly: "회화적", anime: "애니메이션", fantasy: "판타지",
        watercolor: "수채화",
        popart: "팝 아트",  selectgenre: "장르 선택", adventure: "모험", mystery: "미스터리",  sciencefiction: "공상 과학", historicalfiction: "역사 소설", romance: "로맨스", comedy: "코미디", addatwist: "반전 추가", selectmagicalelement: "마법 요소 선택", wizards: "마법사", mermaids: "인어", dinosaurs: "공룡", robots: "로봇", cars: "차", pets: "애완 동물",
        dragons: "드래곤", aliens: "외계인", fairies: "요정", superheroes: "슈퍼히어로", pirates: "해적", cowboys: "카우보이", secretworlds: "비밀 세계", magicalcreatures: "마법의 생물", knights: "기사", princesses: "공주", otherspleasespecifybelow: "기타, 아래 텍스트 영역에 명시하십시오", selectculturalcontext: "문화적 맥락 선택", ancientegypt: "고대 이집트", medievaleurope: "중세 유럽", feudaljapan: "봉건 시대의 일본", victorianengland: "빅토리아 시대의 영국", renaissanceitaly: "르네상스 시대의 이탈리아", nativeamericanfolklore: "아메리카 원주민 민속", africanmythology: "아프리카 신화", asianlegends: "아시아 전설", wildwest: "와일드 웨스트", selectenvironment: "환경 선택", forest: "숲", cityscape: "도시 풍경", countryside: "시골", mountains: "산", beach: "해변", underwaterkingdom: "수중 왕국", outerspace: "우주", enchantedforest: "마법의 숲", magicalcastle: "마법의 성", desert: "사막", arctictundra: "북극 툰드라", volcanicisland: "화산 섬", alienplanet: "외계 행성",
        Superheroes: "슈퍼히어로",
        VariousTales: "다양한 이야기",
        maincharacter: "주요 캐릭터",
        animals: "동물",
        toys: "장난감",
        mythicalcreatures: "신화 속 생물",
        momentstogether: "함께하는 순간",
        selectone: "하나 선택",
        completeregistration: "등록을 완료하세요",

        subscriptionsuccessful: "와! 구독이 성공적으로 완료되었고, 새 이야기 크레딧이 충전되었습니다! 🎉 업데이트된 잔액을 확인하려면 로그아웃 후 다시 로그인하세요. 더 재미있는 모험을 준비하세요!",
        paymentissue: "이런! 결제가 완료되지 않은 것 같아요. 카드나 PayPal 정보를 확인하고 다시 시도해 보세요. 계속 문제가 발생한다면 다른 결제 방법을 시도해 보세요. 재미를 놓치지 않도록 하세요!",
        underreview: "잠시만 기다려주세요! 주문이 안전한지 확인하기 위해 검토 중입니다. 준비되면 바로 알려드리겠습니다. 기다려 주셔서 감사합니다—곧 더 많은 이야기가 찾아옵니다!",
        pendingpayment: "결제가 저희에게 전달 중입니다! 결제가 완료되는 즉시 이야기 크레딧이 사용 가능합니다. PayPal 또는 은행 송금을 사용하는 경우, 모든 것이 승인되었는지 확인하고 모험으로 다시 돌아가세요.",



        selectagegroup: "연령대 선택",
        createstory: "이야기 만들기",
        flipdeviceforbetterview: "더 나은 시야를 위해 장치를 회전하세요",
        gobacktolibrary: "도서관으로 돌아가기",
        downloadbook: "책 다운로드",
        downloadcoloringbook: "색칠책 다운로드",
        forsupportemailusat: "지원 문의는 이메일로 연락하세요 ",
        chooselanguage: "언어 선택",
        currentsubscriptiondetails: "현재 구독 세부 사항",
        packagename: "패키지 이름",
        enddate: "종료일",
        remainingbalance: "잔액",
        emailaddress: "이메일 주소",
        renewsubscription: "구독 갱신",
        confirrenewsubscriptiondate: "지금 구독을 갱신하시겠습니까?",
        ifyoudecidetoupgrade: "구독을 변경하면 새 구독이 오늘부터 시작됩니다",
        yes: "예",
        no: "아니요",
        cancelsubscription: "구독 취소",
        confirmsubcancelation: "구독을 취소하시겠습니까? 취소 후에는 무료 기능 및 일반 도서관만 이용할 수 있습니다.",
        toupgradeyourpackage: "패키지를 업그레이드하거나 다운그레이드하려면 아래 옵션 중 하나를 선택하세요",
        somethingwrongcontactsupport: "문제가 발생할 수 있습니다. 지원 팀에 문의하세요.",
        emailverification: "이메일 확인",
        pleaselogin: "로그인하세요",
        downloadbookfeature: "동화책 다운로드 — 원본 또는 색칠책 버전으로 제공되며 인쇄 준비 완료",
        child: "아이",
        superhero: "슈퍼히어로",
        animal: "동물",
        wizard: "마법사",
        fairy: "요정",
        robot: "로봇",
        fantasycharacters: "판타지 캐릭터",
        aboutus: "회사 소개",
        frequentlyaskedquestion: "자주 묻는 질문",
        howitworks: "작동 방식",
 languagedisclaimer: "참고용으로 약관이 영어로 표시된다는 점을 유의해 주십시오. 저희는 콘텐츠를 여러 언어로 제공하기 위해 노력하고 있지만, 번역으로 인한 불일치, 오해 또는 법적 분쟁이 발생할 경우 이 약관의 영어 버전이 항상 우선합니다. 이 약관에 동의함으로써 선호하는 언어로 콘텐츠를 정확하게 해석할 책임이 귀하에게 있음을 인정합니다. 법적 분쟁이나 모호함이 발생할 경우, 약관의 영어 버전이 우선됩니다. 질문이 있거나 설명이 필요한 경우, 전문가의 조언을 구하거나 저희에게 직접 연락하시기 바랍니다.",
        availablecurrences: "USD, EUR, GBP, JPY, AUD 등 100개 이상의 현지 통화를 지원합니다!",
        ifyouhaveacouponyoucanredeemit: "쿠폰이 있으면 교환할 수 있습니다",
        ifthestoryreloads: "참고: 양식이 다시 로드되면 이미지가 안전 검사에 통과하지 못했음을 의미합니다. 입력을 조정하고 다시 시도하십시오",
        youcanalsosendagift: "한 번만 사용할 수 있는 선물을 구매하여 다른 사람에게 보낼 수도 있습니다",
    },

    nl: {
        home: "Home",
        pricing: "Prijzen",
        loginpage: "Inloggen",
        signup: "Aanmelden",
        dashboard: "Dashboard",
        managesubscribtion: "Beheer abonnement",
        upgradetopremium: "Upgrade naar premium",
        logout: "Uitloggen",
        chooseacategory: "Kies een categorie",
        logingpage: "Inlogpagina",
        youarenotloggedin: "U bent momenteel niet ingelogd, log hier in",
        resetpassword: "Wachtwoord opnieuw instellen",
        enteremailtoresetpassowrd: "Voer uw e-mailadres in om uw wachtwoord opnieuw in te stellen",
        email: "E-mail",
        submit: "Verzenden",
        enteryournewpassword: "Voer uw nieuwe wachtwoord in",
        newpassword: "Nieuw wachtwoord",
        confirmnewpassword: "Bevestig nieuw wachtwoord",
        gotologin: "Ga naar inloggen",
        generallibrary: "Algemene Bibliotheek",
        yourlibrary: "Uw Bibliotheek",
        createnewbook: "Nieuw boek maken",
        youarecurrentlynotloggedin: "U bent momenteel niet ingelogd, log hier in",
        nowpleaseconfirmwhichpackage: "Bevestig nu welk pakket u wilt kopen. Momenteel gebruikt u dit e-mailadres:",
        onceyouclickonthebuybutton: "Zodra u op de 'Nu kopen' knop hieronder klikt, wordt u doorgestuurd naar onze vertrouwde leverancier FastSpring waar u de betalingsgegevens kunt finaliseren. Zorg ervoor dat u hetzelfde e-mailadres gebruikt",
        chooseapackage: "Kies een pakket:",
        selectyourpackage: "Selecteer uw pakket",
        monthly: "Maandelijks",
        yearly: "Jaarlijks",
        buynow: "Nu kopen",
        thankyouforchoosingpackage: "Bedankt voor het selecteren van je pakket. Zorg ervoor dat je na het afronden van je betalingsgegevens opnieuw inlogt om je saldo bij te werken. Je kunt je eigen boeken maken en toegang krijgen tot je privébibliotheek.",
        youarecurrentlysubscribed: " U bent momenteel geabonneerd op de",
        pleaseuseyourregisteredemail: "Pakket. Gebruik alstublieft uw geregistreerde e-mailadres:",
        tomanageyouraccount: "om uw account te beheren. U kunt uw abonnement",
        here: "hier",
        noteifyoudecidetoupgradeyourpackage: "Opmerking: als u besluit uw pakket te upgraden, wordt het geüpgradede pakket de volgende keer weergegeven wanneer u inlogt.",
        yourorderiscompleted: "uw bestelling is voltooid",
        turnyourchildreality: "Maak van de verbeelding van uw kind werkelijkheid met ",

    
        whychoose: "Waarom kiezen",
        ourplatformisdedicatedto: "Ons platform is toegewijd aan het versterken van familiebanden door gepersonaliseerde verhalende ervaringen. Door een platform voor gedeelde verhalen te bieden, bevorderen we diepere verbindingen tussen ouders en kinderen, waardoor families blijvende herinneringen kunnen creëren. Onze meeslepende verhalen, op maat gemaakt naar de interesses en ontwikkelingsfasen van kinderen, bevorderen educatieve betrokkenheid en versterken belangrijke concepten en waarden",
        ignitecreativity: "Ontsteek creativiteit",
        empowerchildrenimagination: "Stimuleer de verbeelding en creativiteit van kinderen door boeiende verhalen en levendige illustraties. Onze service bevordert creatief denken en probleemoplossende vaardigheden.",
        connectondeeperlevel: "Verbind op een dieper niveau",
        fosteradeeperbond: "Bevorder een diepere band tussen ouders en kinderen door gepersonaliseerde verhalende ervaringen. Ons platform biedt interactieve elementen waarmee families blijvende herinneringen kunnen creëren.",
        funlearningadventures: "Leuke leeravonturen",
        leverageourservicestoprovide: "Maak gebruik van onze service om educatieve inhoud op een leuke en boeiende manier te bieden. Kinderen leren waardevolle lessen en vaardigheden door meeslepende verhalen op maat gemaakt naar hun interesses en ontwikkelingsfase.",
        discoverwhyfamilies: "Ontdek waarom families ons vertrouwen",
        convenienceandsimplified: "Gemak en vereenvoudigde toegang",
        saygoodbuytothehassle: "Zeg vaarwel tegen het gedoe van het zoeken naar leeftijdsgerichte boeken of het bedenken van creatieve ideeën. Onze service biedt gepersonaliseerde verhalen en illustraties binnen handbereik.",
        customizedlearningexperience: "Aangepaste leerervaringen",
        tailorstorytelling: "Pas verhalende ervaringen aan naar de individuele behoeften en interesses van uw kind. Of het nu gaat om het versterken van specifieke leerconcepten of het aanpakken van sociaal-emotionele thema's, onze service past zich aan naar de ontwikkelingsfase en leerstijl van uw kind.",
        parentalempowement: "Ouderlijke kracht",
        takeanactiverole: "Neem een actieve rol in de educatie en groei van uw kind met onze tools en middelen. Versterk de leerreis van uw kind en versterk uw relatie door samenwerking in verhalende ervaringen.",
        timesavingsolution: "Tijdbesparende oplossing",
        savetimebyeleminating: "Bespaar tijd door de noodzaak om educatief materiaal te onderzoeken en samen te stellen weg te nemen. Vertrouw op ons platform om hoogwaardige, gepersonaliseerde inhoud te bieden die is afgestemd op de behoeften en interesses van uw kind.",
        experiencethemagic: "Ervaar de magie van verhalen vertellen met ons vandaag. Bekijk onze",
        pricingpage: "Prijspagina",
        formoreinformationandstart: "voor meer informatie en het creëren van waardevolle herinneringen met uw familie.",
        welcomebackpleaselogin: "Welkom terug! Gelieve in te loggen om verder te gaan.",
        password: "Wachtwoord",
        donthaveanouaccount: "Heeft u nog geen account? U kunt zich aanmelden ",
        forgotyourpassword: "Wachtwoord vergeten? U kunt het opnieuw instellen",
        welcome: "Welkom",
        letscreateyouraccount: "Laten we uw account aanmaken!",
        firstname: "Voornaam",
        lastname: "Achternaam",
        termsandconditions: "Algemene Voorwaarden",
        free: "Gratis",
        freeuser: "Gratis Gebruiker",
        accesstogenerallibrary: "Toegang tot de Algemene Bibliotheek en maandelijkse aanbevolen boeken",
        earlyaccesstonewfeature: "Vroege toegang tot promoties — Wees de eerste die nieuwe functies en aanbiedingen ontdekt",
        nocreditcardrequired: "Geen creditcard nodig — Begin zonder verplichtingen",
        signupnow: "Meld je Nu Aan",
        generalandprivatelibraries: "Toegang tot Algemene en Private Bibliotheken — Ontdek elke maand nieuwe aanbevolen verhalen",
        accesstonewaddedmonthlly: "Maandelijkse toegang tot nieuwe aanbevolen boeken — Ontdek elke maand frisse verhalen",
        questbook: "5 Op Maat Gemaakte Verhalenboeken — Tot 8 pagina's, volledig gepersonaliseerd voor jouw kind",
        odysseybook: "12 Op Maat Gemaakte Verhalenboeken — Tot 8 pagina's, volledig gepersonaliseerd voor jouw kind",
        enchantedbook: "29 Op Maat Gemaakte Verhalenboeken — Tot 8 pagina's, volledig gepersonaliseerd voor jouw kind",
        questbookannually: "60 Op Maat Gemaakte Verhalenboeken — Tot 8 pagina's, volledig gepersonaliseerd voor jouw kind",
        odysseybookannually: "145 Op Maat Gemaakte Verhalenboeken — Tot 8 pagina's, volledig gepersonaliseerd voor jouw kind",
        enchantedbookannually: "350 Op Maat Gemaakte Verhalenboeken — Tot 8 pagina's, volledig gepersonaliseerd voor jouw kind",
        forallpaidpackages: "Voor alle betaalde pakketten kunt u bij het afrekenen kiezen voor jaarlijkse facturering en krijgt u ",
        onemonthfree: "één maand gratis!",
        title: "Titel",
        creationdate: "Creatiedatum",
        action: "Actie",
        view: "Bekijk",
        pleasebarewithus: "Gelieve geduld te hebben, goede dingen kosten tijd!",
        loading: "Laden",
        booksremaining: "Boeken over:",
        gender: "Geslacht:",
        booklength: "Boeklengte:",
        agegroup: "Leeftijdsgroep:",
        lessonlearned: "Geleerde lessen",
        morallesson: "Morele Les",
        themes: "Thema's",
        visualstyle: "Visuele Stijl:",
        Whatillustrationstyle: "Welke illustratiestijl vindt u aantrekkelijk?",
        characterarechetype: "Karakterarchetypen: ",
        genre: "Genre:",
        plotelements: " Verhaalelementen:",
        magicalelements: "Magische Elementen:",
        cluturalcontext: "Culturele Context:",
        emotionaltone: "Emotionele Toon:",
        environment: "Omgeving:",
        specificpreferences: "Specifieke voorkeuren? Pas uw verhaal nog meer aan:",
        storycreatedsuccesfully: "Verhaal succesvol aangemaakt en opgeslagen!",
        failedtocreatestory: "Mislukt om verhaal te maken en op te slaan!",
        toviewyourlibrary: "Om uw bibliotheek te bekijken, klik",
        unfortunatellyyouhaveconsumed: "Helaas hebt u alle boeken voor de maand opgebruikt. Als u uw pakket wilt upgraden, bezoek dan de ",
        language: "Taal:",
        english: "Engels",
        arabic: "Arabisch",
        bulgarian: "Bulgaars",
        chinese: "Chinees",
        french: "Frans",
        german: "Duits",
        hindi: "Hindi",
        korean: "Koreaans",
        portuguese: "Portugees",
        russian: "Russisch",
        spanish: "Spaans",
        dutch: "Nederlands",

        safeContent: 'Is de inhoud veilig en geschikt voor kinderen?',
        safeContentAnswer: 'Ja, de inhoud op GWOW AI is veilig en geschikt voor kinderen. We zorgen ervoor dat alle verhalen en illustraties die door onze AI worden gegenereerd geschikt zijn voor jonge doelgroepen. Ons platform stelt ouders in staat om verhalen aan te passen, zodat de inhoud voldoet aan hun specifieke vereisten en voorkeuren.',
        supportedDevices: 'Welke apparaten worden ondersteund door de AI Interactive Storyteller?',
        supportedDevicesAnswer: 'De AI Interactive Storyteller ondersteunt alle moderne apparaten, inclusief smartphones, tablets en computers. Je hebt een internetverbinding nodig om de website te bereiken, maar je kunt ook verhalen lokaal downloaden voor offline toegang.',
        globalSupport: 'Wordt het wereldwijd ondersteund?',
        globalSupportAnswer: 'Ja, GWOW AI wordt wereldwijd ondersteund. Gebruikers kunnen uit 12 verschillende talen kiezen om hun verhalen te creëren en te genieten, waardoor het toegankelijk is voor een breed publiek over de hele wereld.',
        freeTrial: 'Is er een gratis proefversie beschikbaar?',
        freeTrialAnswer: 'Ja, er is een gratis proefversie beschikbaar. Je kunt een gratis gebruikersaccount aanmaken om toegang te krijgen tot de algemene bibliotheek. Om gepersonaliseerde verhalen te maken, moet je je abonneren op een van onze pakketten vanaf $7.99.',
        dataCollection: 'Welke gegevens worden verzameld en hoe worden ze gebruikt?',
        dataCollectionAnswer: 'We verzamelen basis inloggegevens om onze diensten te leveren en te verbeteren. Deze informatie wordt veilig bewaard, niet met anderen gedeeld en niet verkocht.',
        aiBooksCreativity: 'Hoe kunnen door AI gegenereerde boeken de creativiteit en het leren van mijn kind verbeteren?',
        aiBooksCreativityAnswer: 'Boeken die door AI zijn gegenereerd, kunnen de creativiteit en het leren van je kind aanzienlijk verbeteren door gepersonaliseerde en boeiende verhalen te bieden. Deze verhalen kunnen worden afgestemd op de interesses en leerbehoeften van je kind, waardoor lezen een interactievere en plezieriger ervaring wordt. Bovendien kunnen ouders verhalen als PDF of kleurboeken downloaden om hun kinderen verder te betrekken.',



        faqAppropriateContent: "Is de inhoud veilig en geschikt voor kinderen?",
        faqSupportedDevices: "Welke apparaten worden ondersteund door de AI-interactieve verteller?",
        faqGlobalSupport: "Wordt het wereldwijd ondersteund?",
        faqFreeTrial: "Is er een gratis proefversie beschikbaar?",
        faqDataCollection: "Welke gegevens worden verzameld en hoe worden ze gebruikt?",
        faqAIBooks: "Hoe kunnen door AI gegenereerde boeken de creativiteit en het leren van mijn kind verbeteren?",
        answerAppropriateContent: "Ja, de inhoud op GWOW AI is veilig en geschikt voor kinderen. We zorgen ervoor dat alle door onze AI gegenereerde verhalen en illustraties geschikt zijn voor jonge doelgroepen. Ons platform stelt ouders in staat om verhalen aan te passen om ervoor te zorgen dat de inhoud voldoet aan hun specifieke behoeften en voorkeuren.",
        answerSupportedDevices: "De AI-interactieve verteller ondersteunt alle moderne apparaten, waaronder smartphones, tablets en computers. Je hebt een internetverbinding nodig om toegang te krijgen tot de website, maar je kunt verhalen ook lokaal downloaden voor offline toegang.",
        answerGlobalSupport: "Ja, GWOW AI wordt wereldwijd ondersteund. Gebruikers kunnen kiezen uit 12 verschillende talen om hun verhalen te maken en te genieten, waardoor het toegankelijk is voor een breed publiek over de hele wereld.",
        answerFreeTrial: "Ja, er is een gratis proefversie beschikbaar. Je kunt een gratis gebruikersaccount aanmaken om toegang te krijgen tot de openbare bibliotheek. Voor het maken van gepersonaliseerde verhalen moet je je abonneren op een van onze plannen, beginnend bij $4,99.",
        answerDataCollection: "We verzamelen basis inloggegevens om onze diensten te leveren en te verbeteren. Deze informatie wordt veilig opgeslagen, niet gedeeld en niet verkocht.",
        answerAIBooks: "AI-gegenereerde boeken kunnen de creativiteit en het leren van je kind aanzienlijk verbeteren door gepersonaliseerde en boeiende verhalen te bieden. Deze verhalen kunnen worden aangepast aan de interesses en leerbehoeften van je kind, waardoor lezen een interactievere en aangenamere ervaring wordt. Bovendien kunnen ouders verhalen in PDF-formaat of kleurboeken downloaden om hun kinderen verder te betrekken.",

        learnmore: "Lees meer:",
        years: "jaren", selectgender: "Geslacht kiezen", male: "Man", female: "Vrouw", unspecified: "Niet gespecificeerd", selectbooklength: "Kies de lengte van het boek", pages: "pagina's", selectmorallesson: "Kies een morele les", friendship: "Vriendschap", kindness: "Vriendelijkheid", courage: "Moed", honesty: "Eerlijkheid", empathy: "Empathie", teamwork: "Teamwerk", acceptance: "Acceptatie", responsibility: "Verantwoordelijkheid", creativity: "Creativiteit", selectvisualstyle: "Kies een visuele stijl", realistic: "Realistisch", cartoon: "Cartoon", painterly: "Schilderachtig", anime: "Anime", fantasy: "Fantasy",
        watercolor: "Aquarel",
        popart: "Pop Art",  selectgenre: "Kies een genre", adventure: "Avontuur", mystery: "Mysterie",  sciencefiction: "Sciencefiction", historicalfiction: "Historische fictie", romance: "Romantiek", comedy: "Komedie", addatwist: "Voeg een twist toe", selectmagicalelement: "Kies een magisch element", wizards: "Tovenaars", mermaids: "Zeemeerminnen", dinosaurs: "Dinosaurussen", robots: "Robots", cars: "Auto's", pets: "Huisdieren",
        dragons: "Draken", aliens: "Buitenaardse wezens", fairies: "Feeën", superheroes: "Superhelden", pirates: "Piraten", cowboys: "Cowboys", secretworlds: "Geheime Werelden", magicalcreatures: "Magische Wezens", knights: "Ridders", princesses: "Prinsessen", otherspleasespecifybelow: "Anderen, specificeer hieronder in het tekstvak", selectculturalcontext: "Selecteer Culturele Context", ancientegypt: "Oude Egypte", medievaleurope: "Middeleeuws Europa", feudaljapan: "Feodaal Japan", victorianengland: "Victoriaans Engeland", renaissanceitaly: "Renaissance Italië", nativeamericanfolklore: "Inheemse Amerikaanse Folklore", africanmythology: "Afrikaanse Mythologie", asianlegends: "Aziatische Legenden", wildwest: "Wilde Westen", selectenvironment: "Selecteer Omgeving", forest: "Bos", cityscape: "Stadsgezicht", countryside: "Platteland", mountains: "Bergen", beach: "Strand", underwaterkingdom: "Onderwater Koninkrijk", outerspace: "Buitenaardse Ruimte", enchantedforest: "Betoverd Bos", magicalcastle: "Magisch Kasteel", desert: "Woestijn", arctictundra: "Arctische Toendra", volcanicisland: "Vulkanisch Eiland", alienplanet: "Buitenaardse Planeet",

        Superheroes: "Superhelden",
        VariousTales: "Verschillende Verhalen",
        maincharacter: "Hoofdpersonage",
        animals: "Dieren",
        toys: "Speelgoed",
        mythicalcreatures: "Mythische Wezens",
        momentstogether: "Momenten Samen",
        selectone: "Kies Er Een",
        completeregistration: "Voltooi de registratie",

        subscriptionsuccessful: "Yay! Je abonnement is gelukt en je hebt een nieuwe tank vol verhaalcredits! 🎉 Om je bijgewerkte saldo te zien, log uit en log opnieuw in. Maak je klaar voor nog meer leuke avonturen!",
        paymentissue: "Oeps! Het lijkt erop dat je betaling niet is gelukt. Controleer je kaart- of PayPal-gegevens en probeer het opnieuw. Als het blijft gebeuren, probeer dan een andere betaalmethode zodat je niets van het plezier mist!",
        underreview: "Even geduld! Je bestelling wordt gecontroleerd om ervoor te zorgen dat alles veilig is. We laten je weten zodra het klaar is. Bedankt voor je geduld—er komen snel meer verhalen jouw kant op!",
        pendingpayment: "Je betaling is onderweg naar ons! Zodra we het ontvangen hebben, zijn je verhaalcredits klaar voor gebruik. Als je PayPal of een bankoverschrijving gebruikt, zorg er dan voor dat alles goedgekeurd is, zodat je terug kunt naar het avontuur.",


        selectagegroup: "Selecteer leeftijdsgroep",
        createstory: "Maak verhaal",
        flipdeviceforbetterview: "Draai apparaat voor beter zicht",
        gobacktolibrary: "Ga terug naar bibliotheek",
        downloadbook: "Boek downloaden",
        downloadcoloringbook: "Kleurboek downloaden",
        forsupportemailusat: "Voor ondersteuning kunt u ons een e-mail sturen op ",
        chooselanguage: "Kies taal",
        currentsubscriptiondetails: "Huidige abonnementsgegevens",
        packagename: "Pakketnaam",
        enddate: "Einddatum",
        remainingbalance: "Resterend saldo",
        emailaddress: "E-mailadres",
        renewsubscription: "Abonnement verlengen",
        confirrenewsubscriptiondate: "Weet u zeker dat u uw abonnement nu wilt verlengen?",
        ifyoudecidetoupgrade: "Als je besluit je abonnement te wijzigen, begint je nieuwe abonnement vandaag",
        
        yes: "Ja",
        no: "Nee",
        cancelsubscription: "Abonnement annuleren",
        confirmsubcancelation: "Weet u zeker dat u uw abonnement wilt annuleren? Na annulering heeft u alleen toegang tot gratis functies en algemene bibliotheken",
        toupgradeyourpackage: "Om uw pakket te upgraden of te downgraden, selecteer een van de volgende opties",
        somethingwrongcontactsupport: "Er lijkt iets mis te zijn. Neem contact op met het ondersteuningsteam voor hulp.",
        emailverification: "E-mailverificatie",
        pleaselogin: "Log in alstublieft",
        downloadbookfeature: "Verhaalboeken downloaden — Origineel of kleurboek, klaar om te printen",
        child: "Kind",
        superhero: "Superheld",
        animal: "Dier",
        wizard: "Tovenaar",
        fairy: "Fee",
        robot: "Robot",
        fantasycharacters: "Fantasiepersonages",

        aboutus: "Over Ons",
        frequentlyaskedquestion: "Veelgestelde Vragen",
        howitworks: "Hoe het werkt",
 languagedisclaimer: "Houd er rekening mee dat de Algemene Voorwaarden in het Engels worden weergegeven ter referentie. Hoewel we ons best doen om onze inhoud in meerdere talen beschikbaar te maken, zal de Engelse versie van deze Algemene Voorwaarden altijd voorrang hebben in geval van discrepanties, misverstanden of juridische geschillen die voortvloeien uit vertalingen. Door akkoord te gaan met deze Algemene Voorwaarden, erkent u dat het uw verantwoordelijkheid is om de inhoud in uw voorkeurstaal nauwkeurig te interpreteren. In geval van juridische geschillen of onduidelijkheden heeft de Engelse versie van de Algemene Voorwaarden voorrang. Als u vragen heeft of verduidelijking nodig heeft, raden we u aan professioneel advies in te winnen of rechtstreeks contact met ons op te nemen.",
        availablecurrences: "Ondersteuning van meer dan 100 lokale valuta's, waaronder USD, EUR, GBP, JPY, AUD en meer!",
        ifyouhaveacouponyoucanredeemit: "Als je een coupon hebt, kun je deze inwisselen",
        ifthestoryreloads: "Opmerking: Als het formulier opnieuw wordt geladen, betekent dit dat de afbeeldingen onze veiligheidscontroles niet hebben doorstaan. Pas uw invoer aan en probeer het opnieuw",
        youcanalsosendagift: "Je kunt ook een eenmalig cadeau kopen en het naar iemand sturen",
    },

};
export default translations;