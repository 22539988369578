import React from 'react';

import './App.css'; // Import custom CSS for styling

function Careers() {

    return (
        <section id="loginpagesection" className="section">
            <h1 id="careers-header">Join Our Team</h1>
            <p id="careers-intro">
                We’re expanding and looking for passionate individuals to help us grow GWOW AI worldwide!
            </p>
            <div id="careers-instructions">
                <p className="careers-step">
                    <strong>Email us:</strong> Send an email to <strong>Partnerships@GwowAI.com</strong> with the subject line <em>"Interested in working with GWOW AI Team"</em>.
                </p>
                <p className="careers-step">
                    <strong>Include:</strong> Your updated resume and a message explaining how you can help promote or sell GWOW AI worldwide.
                </p>
                <p className="careers-step">
                    <strong>Social Media:</strong> Provide links to all your social media accounts (Instagram, TikTok, LinkedIn, Facebook).
                </p>
                <p className="careers-step">
                    <strong>Note:</strong> Our current sales roles are commission-based. Share your enthusiasm and ideas to join us on this exciting journey!
                </p>
            </div>
        </section>
    );


}

export default Careers;
